// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from "@mui/system";
import { Backdrop } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
	zIndex: theme.zIndex.modal + 1,
}));

function CommonBackdrop({ children, ...otherProps }) {
	return (
		<StyledBackdrop {...otherProps} className="backdropcontainer">
			{children}
		</StyledBackdrop>
	);
}

CommonBackdrop.propTypes = {
	children: PropTypes.node,
};

export default CommonBackdrop;
