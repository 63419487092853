import { Box, IconButton, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import { format } from "date-fns";
import { useState, useRef } from "react";
import {
	BUTTON_TYPE,
	CALENDAR_TEST_IDS,
	DAILY_WEEKLY_ROTATION_CONSTANTS,
} from "../../../constants";

import MESSAGE_STRINGS from "../../../constants/en-us";

import ViewSwitcher from "../ViewSwitcher";
import PreviousMonth from "../../../assets/img/leftArrow.svg";
import NextMonth from "../../../assets/img/rightArrow.svg";
import GeneralButton from "../../GeneralButton";
import ToolbarLegend from "../ToolbarLegend";
import DatePicker from "../../DatePicker";

function CalendarToolbar({ onNavigate, onView, view, date, legends = [] }) {
	const [open, setOpen] = useState(false);
	const currentDateRef = useRef(null);
	const goToPreviousMonth = () => {
		onNavigate(DAILY_WEEKLY_ROTATION_CONSTANTS.MONTH_CHANGE.PREV);
	};

	const goToNextMonth = () => {
		onNavigate(DAILY_WEEKLY_ROTATION_CONSTANTS.MONTH_CHANGE.NEXT);
	};

	const goToCurrentDate = () => {
		onNavigate(DAILY_WEEKLY_ROTATION_CONSTANTS.MONTH_CHANGE.TODAY);
	};

	const currentDate = date ? new Date(date) : new Date();

	return (
		<Box
			display="flex"
			alignItems="center"
			paddingX={2}
			paddingY={1}
			bgcolor="background.layoutBackgroundColor"
			sx={{
				border: (theme) => `1px solid ${theme.palette.background.darkGrey}`,
				color: (theme) => theme.palette.text.primary,
			}}
		>
			<Box display="flex" alignItems="center" flexGrow={1}>
				<Box marginRight={1}>
					<GeneralButton
						text={
							MESSAGE_STRINGS[
								"WeeklyAndDailyRotations.RotationsCalendar.button.today"
							]
						}
						type={BUTTON_TYPE.SECONDARY}
						onClick={goToCurrentDate}
						data-testid={CALENDAR_TEST_IDS.TODAY_BUTTON}
					/>
				</Box>
				<Box marginX={1}>
					<IconButton
						onClick={goToPreviousMonth}
						sx={{ padding: 0 }}
						data-testid={CALENDAR_TEST_IDS.PREVIOUS_MONTH_BUTTON}
					>
						<PreviousMonth />
					</IconButton>
				</Box>
				<Box marginX={1}>
					<IconButton
						onClick={goToNextMonth}
						sx={{ padding: 0 }}
						data-testid={CALENDAR_TEST_IDS.NEXT_MONTH_BUTTON}
					>
						<NextMonth />
					</IconButton>
				</Box>
				<Box marginX={2} display="flex" flexDirection="column">
					<Typography
						data-testid={CALENDAR_TEST_IDS.MONTH_CAPTION}
						ref={currentDateRef}
						variant="h6"
						sx={{
							fontWeight: "bold",
							fontSize: "1rem",
							cursor: "pointer",
						}}
						onClick={() => setOpen(true)}
					>
						{`${format(currentDate, "MMMM").toUpperCase()} ${format(
							currentDate,
							"yyyy"
						)}`}
					</Typography>
					{open && (
						<DatePicker
							inputRef={currentDateRef}
							value={currentDate}
							onChange={(nDate) => onNavigate("DATE", nDate)}
							open
							onClose={() => setOpen(false)}
							renderInput={({ inputRef }) => <span ref={inputRef} />}
							views={["day"]}
						/>
					)}
				</Box>
			</Box>
			<Box display="flex" flexGrow={1} justifyContent="center">
				<ViewSwitcher
					currentViewName={view}
					onChange={(e) => onView(e.target.value)}
				/>
			</Box>
			<ToolbarLegend legends={legends} />
		</Box>
	);
}

CalendarToolbar.propTypes = {
	onNavigate: PropTypes.func,
	onView: PropTypes.func,
	view: PropTypes.string,
	date: PropTypes.instanceOf(Date),
	legends: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.string,
		})
	),
};
export default CalendarToolbar;
