import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function BasicTableError({ errorMsg = "There was an error" }) {
	return (
		<Box position="relative">
			<Box
				p={4}
				color="red"
				position="absolute"
				left="70%"
				bgcolor="black"
				top="10rem"
			>
				{errorMsg}
			</Box>
		</Box>
	);
}

BasicTableError.propTypes = {
	errorMsg: PropTypes.string,
};

export default BasicTableError;
