import React from "react";
import PropTypes from "prop-types";
import { styled, Typography } from "@mui/material";
import ModalBody from "../GeneralDialogBody";
import ErrorIcon from "../../../assets/img/dangerIcon.svg";
import WarningIcon from "../../../assets/img/warningIconV2.svg";
import SuccessIcon from "../../../assets/img/successIconV2.svg";
import ConfirmIcon from "../../../assets/img/confirmIcon.svg";
import GeneralButton from "../../GeneralButton";
import { BUTTON_TYPE } from "../../../constants";

const iconTypes = {
	error: <ErrorIcon height={32} width={32} />,
	warning: <WarningIcon height={32} width={32} />,
	success: <SuccessIcon height={32} width={32} />,
	confirm: <ConfirmIcon height={32} width={32} />,
};

const StyledContent = styled("div")(() => ({
	"& .notificationdialog-content-note": {
		fontWeight: "bold",
		marginTop: "1rem",
	},
	"& .notificationdialog-content-note_lighttext": {
		fontWeight: "lighter",
		color: "grey",
	},
}));

function Content({ customText, noteText, lightText }) {
	return (
		<StyledContent className="notificationdialog-content">
			<Typography
				data-testid="modal-primary-text"
				variant="subtitle2"
				className="notificationdialog-content-customText"
				sx={{ textTransform: "none" }}
			>
				{customText}
			</Typography>
			<Typography
				data-testid="modal-secondary-text"
				variant="subtitle2"
				className={`notificationdialog-content-note ${
					lightText ? "notificationdialog-content-note_lighttext" : null
				}`}
				sx={{ textTransform: "none" }}
			>
				{noteText}
			</Typography>
		</StyledContent>
	);
}

Content.propTypes = {
	customText: PropTypes.string,
	noteText: PropTypes.string,
	lightText: PropTypes.bool,
};

function Actions({
	type,
	confirmText,
	cancelText,
	handleClose,
	handleSave,
	buttonTypes = { left: BUTTON_TYPE.SECONDARY, right: BUTTON_TYPE.PRIMARY },
}) {
	if (type !== "confirm") {
		return (
			<GeneralButton
				type="secondary"
				text={confirmText}
				onClick={handleClose}
				data-testid="confirm-button"
			/>
		);
	}
	return (
		<>
			<GeneralButton
				type={buttonTypes.left}
				onClick={handleClose}
				text={cancelText}
				data-testid="secondary-modal-button"
			/>
			<GeneralButton
				type={buttonTypes.right}
				onClick={handleSave}
				text={confirmText}
				data-testid="primary-modal-button"
			/>
		</>
	);
}

Actions.propTypes = {
	type: PropTypes.string,
	confirmText: PropTypes.string,
	cancelText: PropTypes.string,
	handleClose: PropTypes.func,
	handleSave: PropTypes.func,
	buttonTypes: PropTypes.shape({
		left: PropTypes.string,
		right: PropTypes.string,
	}),
};

function NotificationDialog({
	type = "error",
	customIcon = null,
	handleSave,
	handleClose,
	cancelText = "No",
	confirmText = "Yes",
	customText = "",
	customTitle = "",
	noteText = "",
	customActions = null,
	minWidth = "36rem",
	maxWidth = "40rem",
	lightText = true,
	buttonTypes,
}) {
	const selectedIcon = customIcon || iconTypes[type];
	const dialogTitle = customTitle;

	return (
		<ModalBody
			customIcon={selectedIcon}
			dialogTitle={dialogTitle}
			dialogContent={
				<Content
					customText={customText}
					noteText={noteText}
					lightText={lightText}
				/>
			}
			minWidth={minWidth}
			maxWidth={maxWidth}
			actions={
				customActions || (
					<Actions
						type={type}
						confirmText={confirmText}
						cancelText={cancelText}
						handleSave={handleSave}
						handleClose={handleClose}
						buttonTypes={buttonTypes}
					/>
				)
			}
		/>
	);
}

NotificationDialog.propTypes = {
	type: PropTypes.string,
	customIcon: PropTypes.node,
	handleSave: PropTypes.func,
	handleClose: PropTypes.func,
	cancelText: PropTypes.string,
	confirmText: PropTypes.string,
	customText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	customTitle: PropTypes.string,
	noteText: PropTypes.string,
	customActions: PropTypes.node,
	minWidth: PropTypes.string,
	maxWidth: PropTypes.string,
	lightText: PropTypes.bool,
	buttonTypes: PropTypes.shape({
		left: PropTypes.string,
		right: PropTypes.string,
	}),
};

export default NotificationDialog;
