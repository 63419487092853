import { format, parse, set } from "date-fns";
import {
	DAILY_WEEKLY_ROTATION_CONSTANTS,
	ENDS_ON,
	TOAST_REDUCER_CONSTANTS,
} from "../constants";
import MESSAGE_STRINGS from "../constants/en-us";

export const simulateUrlClick = (url, fileAcceptType) => {
	const link = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute("type", fileAcceptType);
	link.setAttribute("download", true);
	link.setAttribute("referrerpolicy", "same-origin");
	link.click();
};

export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

/**
 * A function which returns accept object for file type
 * @param {string} type File Type for accepting
 * @returns AcceptType Object
 */
export function getAcceptType(type = "XLSX") {
	switch (type) {
		case "XLSX":
			return {
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
					".xlsx",
				],
			};
		case "CSV":
			return { "text/csv": [".csv"] };
		default:
			return {};
	}
}

export function parseToDecimal(number = "", place = 2) {
	if (number === null || number === "") return "";
	return number.toFixed(place);
}

/**
 * Function to format Weekly Rotations Event
 * @param {Date} startDate Start Date of date instance
 * @param {Date} endDate  End Date of date instance
 * @returns A formattted string of format 1st Jan - 17th Jan, 2022
 */
export const formatRotationDuration = (startDate, endDate) => {
	const startYear = startDate.getFullYear();
	const endYear = endDate.getFullYear();
	const year = startYear === endYear ? endYear : null;

	const formattedStartDate = year
		? format(startDate, "do MMM")
		: format(startDate, "do MMM, yyyy");
	const formattedEndDate = format(endDate, "do MMM, yyyy");

	return `${formattedStartDate} - ${formattedEndDate}`;
};

export function convertMinsTo24Hour(totalMins = 0) {
	if (totalMins && totalMins < 1440) {
		const [hours, mins] = [Math.floor(totalMins / 60), totalMins % 60];
		return format(
			set(new Date(), { hours, minutes: mins, seconds: 0 }),
			"HH:mm"
		);
	}
	return "00:00";
}

// Returns formatted string for shift duration containing start and end time for each shift
/**
 * Function to format string for shift duration containing start and end time for each shift
 * @param {Date} actualStartTime
 * @param {Date} actualEndTime
 * @param {Date} date
 * @param {string} endsOn
 * @param {string} timezone
 * @returns Returns formatted string for shift duration containing start and end time for each shift
 */
export const formatShiftDuration = (
	actualStartTime = {},
	actualEndTime = {},
	date = new Date(),
	endsOn = ENDS_ON.SAME_DAY,
	timezone = ""
) => {
	const actualStartTimeString = convertMinsTo24Hour(
		Number(actualStartTime.startHours) * 60 +
			Number(actualStartTime.startMinutes)
	);
	const actualEndTimeString = convertMinsTo24Hour(
		Number(actualEndTime.endHours) * 60 + Number(actualEndTime.endMinutes)
	);

	const currentDayObj = new Date(date);
	const nextDayObj = new Date(date);
	nextDayObj.setDate(currentDayObj.getDate() + 1);
	const currentDate = ` ${format(currentDayObj, "do MMM, yyyy")}`;
	const nextDate = ` ${format(nextDayObj, "do MMM, yyyy")}`;
	if (endsOn === ENDS_ON.SAME_DAY) {
		return `${currentDate} ${actualStartTimeString} - ${actualEndTimeString} ${timezone}`;
	}
	if (endsOn === ENDS_ON.NEXT_DAY) {
		return `${nextDate} ${actualStartTimeString} - ${actualEndTimeString} ${timezone}`;
	}
	return `${currentDate} ${actualStartTimeString} - ${nextDate} ${actualEndTimeString} ${timezone}`;
};

export function getWeeklyRotationViewData([...weeklyRotations]) {
	return weeklyRotations.map(
		({
			rotationName,
			annualRotationID,
			rotationSlotStart,
			rotationSlotEnds,
		}) => ({
			rotation: rotationName,
			startDate: new Date(
				parse(
					rotationSlotStart?.substr(0, 10),
					"yyyy-MM-dd",
					new Date()
				).setHours(0, 0)
			),
			endDate: new Date(
				parse(
					rotationSlotEnds?.substr(0, 10),
					"yyyy-MM-dd",
					new Date()
				).setHours(23, 59)
			),
			id: annualRotationID,
			eventName: rotationName,
			rotationSlotStart,
		})
	);
}

export function getColors(value) {
	const idx = value % DAILY_WEEKLY_ROTATION_CONSTANTS.ROTATION_COLORS.length;
	return DAILY_WEEKLY_ROTATION_CONSTANTS.ROTATION_COLORS[idx];
}

export function getRotationResources(rotations = []) {
	return rotations.map((res, idx) => ({
		id: res.rotationName,
		text: res.rotationName,
		color: getColors(idx),
	}));
}

export function getDailyRotationsViewData([...dailyShifts]) {
	return dailyShifts.map(
		({
			shiftName,
			dateTimestamp,
			crewId,
			shiftColor,
			shiftId,
			actualValues: { startHours, startMinutes, endHours, endMinutes } = {},
			mockValues: {
				mockStartHours,
				mockStartMinutes,
				mockEndHours,
				mockEndMinutes,
			} = {},
			endsOn,
			timezone,
			crewName,
		}) => {
			const [year, month, date] = dateTimestamp.split("T")[0].split("-");
			const dateObj = new Date(dateTimestamp);
			dateObj.setFullYear(year);
			dateObj.setMonth(parseInt(month, 10) - 1);
			dateObj.setDate(date);
			return {
				title: shiftName,
				eventName: crewName,
				startDate: new Date(dateObj.setHours(mockStartHours, mockStartMinutes)),
				endDate: new Date(dateObj.setHours(mockEndHours, mockEndMinutes)),
				crew: crewId,
				id: `${dateTimestamp}${shiftName}`,
				color: shiftColor,
				shiftId,
				dateTimestamp,
				actualStartTime: { startHours, startMinutes },
				actualEndTime: { endHours, endMinutes },
				date: dateObj,
				endsOn,
				timezone,
				crewName,
			};
		}
	);
}

export function getCrewResources(crews) {
	return crews.map((res) => ({
		id: res.crewID,
		text: res.crewName,
	}));
}

/**
 * Function to get message for Interdependency Modal
 * @param {Object} state Object contains state for Interdependent steps
 * @returns String
 */
export function getMessageForNavigationModal(
	state = { shift: {}, crew: {}, rotation: {} }
) {
	const isShiftActive = state.shift.status === "ACTIVE";
	const isCrewActive = state.crew.status === "ACTIVE";
	const isRotationActive = state.rotation.status === "ACTIVE";
	if (isShiftActive) {
		if (isCrewActive) {
			if (isRotationActive)
				return MESSAGE_STRINGS[
					"InterDependencyModal.shiftAddAndCrewRotationDelete.text"
				];
			return MESSAGE_STRINGS["InterDependencyModal.shiftAddAndCrewDelete.text"];
		}
		if (isRotationActive)
			return MESSAGE_STRINGS[
				"InterDependencyModal.shiftAddAndRotationDelete.text"
			];
		return MESSAGE_STRINGS["InterDependencyModal.shiftAdd.text"];
	}
	if (isCrewActive) {
		if (isRotationActive)
			return MESSAGE_STRINGS[
				"InterDependencyModal.crewDeleteAndRotationDelete.text"
			];
		return MESSAGE_STRINGS["InterDependencyModal.crewDelete.text"];
	}
	if (isRotationActive) {
		return MESSAGE_STRINGS["InterDependencyModal.rotationDelete.text"];
	}
	return "";
}

export const showErrorType = () => ({
	type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
	payload: {
		message: MESSAGE_STRINGS["Toast.message.ERROR"],
	},
});

export const getUploadeInfoMessage = ({
	uploadedBy = "-",
	uploadedDate = "-",
	uploadedTime = "-",
	uploadedTimezone = "-",
} = {}) =>
	`${MESSAGE_STRINGS["EquipmentIntelligence.uploadedInfo.partA"]} ${uploadedBy} ${MESSAGE_STRINGS["EquipmentIntelligence.uploadedInfo.partB"]} ${uploadedDate} ${MESSAGE_STRINGS["EquipmentIntelligence.uploadedInfo.partC"]} ${uploadedTime} ${uploadedTimezone}`;
