import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

function ShiftNameCell({ getValue }) {
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<Typography noWrap>{getValue()}</Typography>
		</Box>
	);
}

ShiftNameCell.propTypes = {
	getValue: PropTypes.func,
};

export default ShiftNameCell;
