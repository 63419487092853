import { createContext, useContext, useMemo, useReducer } from "react";
import {
	GENERAL_CONFIGURATION_STEPS,
	INTERDEPENDENCY_CONTEXT,
	STEPPER_STATUSES,
} from "../constants";

export const InterDependecyContext = createContext();

export const useInterDependecyContext = () => useContext(InterDependecyContext);

const initialState = {
	shift: { status: "INACTIVE", data: [] },
	crew: { status: "INACTIVE", data: [] },
	rotation: { status: "INACTIVE", data: [] },
};

function interDependecyModalReducer(state, action) {
	const { type, payload } = action;
	switch (type) {
		case INTERDEPENDENCY_CONTEXT.SET_STATE: {
			const { newState } = payload;
			return { ...state, ...newState };
		}
		case INTERDEPENDENCY_CONTEXT.UPDATE_SHIFT_MODAL: {
			const newShift = state.shift;
			const { data = {} } = payload;
			newShift.isShiftAdded = true;
			newShift.data = [...state.shift.data, data];
			return { ...state, shift: newShift };
		}
		case INTERDEPENDENCY_CONTEXT.UPDATE_CREW_MODAL: {
			const newCrew = state.crew;
			const { data = {} } = payload;
			newCrew.isAssignedCrewDeleted = true;
			newCrew.data = [...state.crew.data, data];
			return { ...state, crew: newCrew };
		}
		case INTERDEPENDENCY_CONTEXT.UPDATE_ROTATION_MODAL: {
			const newRotation = state.rotation;
			const { data = {} } = payload;
			newRotation.isAssignedRotationDeleted = true;
			newRotation.data = [...state.rotation.data, data];
			return { ...state, rotation: newRotation };
		}
		case INTERDEPENDENCY_CONTEXT.UPDATE_STATE_AFTER_STATUS: {
			const { status } = payload;
			let newShift = { isShiftAdded: false, status: "INACTIVE", data: [] };
			let newCrew = {
				isAssignedCrewDeleted: false,
				status: "INACTIVE",
				data: [],
			};
			let newRotation = {
				isAssignedRotationDeleted: false,
				status: "INACTIVE",
				data: [],
			};
			if (
				state.shift.isShiftAdded &&
				status[GENERAL_CONFIGURATION_STEPS.ROTATION - 1].stepStatus ===
					STEPPER_STATUSES.IN_PROGRESS
			) {
				newShift = { ...state.shift, status: "ACTIVE" };
			}
			if (
				state.crew.isAssignedCrewDeleted &&
				status[GENERAL_CONFIGURATION_STEPS.ROTATION - 1].stepStatus ===
					STEPPER_STATUSES.IN_PROGRESS
			) {
				newCrew = { ...state.crew, status: "ACTIVE" };
			}
			if (
				state.rotation.isAssignedRotationDeleted &&
				status[GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION - 1].stepStatus ===
					STEPPER_STATUSES.IN_PROGRESS
			) {
				newRotation = { ...state.rotation, status: "ACTIVE" };
			}
			return {
				...state,
				shift: { ...state.shift, ...newShift },
				crew: { ...state.crew, ...newCrew },
				rotation: { ...state.rotation, ...newRotation },
			};
		}

		default:
			return state;
	}
}

export function updateShiftAddModalState(dispatch, data = {}) {
	dispatch({
		type: INTERDEPENDENCY_CONTEXT.UPDATE_SHIFT_MODAL,
		payload: {
			data,
		},
	});
}
export function updateCrewModalState(dispatch, data = {}) {
	dispatch({
		type: INTERDEPENDENCY_CONTEXT.UPDATE_CREW_MODAL,
		payload: {
			data,
		},
	});
}
export function updateRotationModalState(dispatch, data = {}) {
	dispatch({
		type: INTERDEPENDENCY_CONTEXT.UPDATE_ROTATION_MODAL,
		payload: {
			data,
		},
	});
}
export function updateModalStateAfterStatus(dispatch, status = []) {
	dispatch({
		type: INTERDEPENDENCY_CONTEXT.UPDATE_STATE_AFTER_STATUS,
		payload: {
			status,
		},
	});
}

// eslint-disable-next-line react/prop-types
export function InterDependecyModalProvider({ children }) {
	const [interDependecyState, interDependecyDispatch] = useReducer(
		interDependecyModalReducer,
		initialState
	);

	const interDependecyData = useMemo(
		() => ({ interDependecyState, interDependecyDispatch }),
		[interDependecyState]
	);

	return (
		<InterDependecyContext.Provider value={interDependecyData}>
			{children}
		</InterDependecyContext.Provider>
	);
}
