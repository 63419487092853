import { Box, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

import { CALENDAR_TEST_IDS } from "../../../constants";
import GeneralTooltip from "../../GeneralTooltip";

function ToolbarLegend({ legends = [] }) {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = debounce(
			() => setScreenWidth(window.innerWidth),
			2000
		);
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Box display="flex" flexGrow={1} justifyContent="flex-end" width="45%">
			<Box
				alignItems="center"
				display="flex"
				flexWrap="wrap"
				width="100%"
				minWidth={400}
				justifyContent="flex-end"
				data-testid={CALENDAR_TEST_IDS.LEGEND_ITEMS}
			>
				{legends.map((resource, index) => (
					<Box
						key={`legend-${resource.id}`}
						sx={{
							display: "flex",
							flexGrow: "0",
							width: "25%",
							flexBasis: "20%",
							justifyContent: "flex-start",
							alignItems: "center",
							margin: screenWidth > 1600 ? 0 : "0 .75rem 0 0",
						}}
					>
						<Box
							sx={{
								height: "0.5rem",
								width: "1.5rem",
								borderRadius: "1rem",
								display: "inline-flex",
								margin: "0 0.75rem 0 0",
								backgroundColor: resource.color,
							}}
						/>
						<GeneralTooltip
							message={resource.text.length > 11 && resource.text}
						>
							<Typography
								data-testid={`${CALENDAR_TEST_IDS.LEGEND_ITEM}-${index + 1}`}
								noWrap
							>
								{resource.text.length > 11
									? `${resource.text.substring(0, 9)}...`
									: resource.text}
							</Typography>
						</GeneralTooltip>
					</Box>
				))}
			</Box>
		</Box>
	);
}

ToolbarLegend.propTypes = {
	legends: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.string,
		})
	),
};
export default ToolbarLegend;
