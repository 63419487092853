/* eslint-disable no-unused-vars */
import { Box, Checkbox, Dialog, styled, Typography } from "@mui/material";

export const StyledSubHeading = styled(Typography)(() => ({
	fontWeight: 700,
	fontSize: "1rem",
	lineHeight: "1.375rem",
	marginRight: "1rem",
}));

export const StyledHeaderTitle = styled(Typography)(() => ({
	letterSpacing: "0.015rem",
	fontFamily: "'Open Sans'",
	fontStyle: "normal",
	fontWeight: 700,
	fontSize: "1.25rem",
	lineHeight: "1.688rem",
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	".MuiDialog-paper": {
		background: theme.palette.background.blackGrey,
		maxWidth: "75rem",
	},
}));
export const StyledModalContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.blackGrey,
	color: "white",
	fontFamily: "Open Sans",
	borderRadius: ".25rem",
	// width: 1200,
}));
export const StyledModalContent = styled(Box)(({ theme }) => ({
	padding: "1.5rem",
	paddingBottom: 0,
}));

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
	height: "4rem",
	display: "flex",
	alignItems: "center",
	paddingLeft: "1.5rem",
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));

export const StyledHierarchyContainer = styled(Box)(({ theme }) => ({
	border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
	backgroundColor: theme.palette.background.blackGrey,
	color: "white",
	fontFamily: "Open Sans",
	fontWeight: 400,
	fontSize: ".875rem",
	lineHeight: "1.188rem",
	borderRadius: "0.25rem",
	marginTop: "1rem",
}));
export const StyledHierarchyHeader = styled(Box)(({ theme }) => ({
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
	backgroundColor: theme.palette.background.darkishBlackBlue,
	height: "3.5rem",
	display: "flex",
	alignItems: "center",
	borderRadius: "0.25rem",
}));

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
	color: "white",
	"&.Mui-checked": {
		color: theme.palette.background.saveGreen,
	},
	"& .MuiSvgIcon-root": {
		height: "1.25rem",
		width: "1.25rem",
	},
	"&.Mui-disabled": {
		color: theme.palette.background.darkGrey,
	},
}));
