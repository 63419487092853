import { Box, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ErrorIcon from "../../assets/img/errorIcon16.svg";

const MessageLabel = styled(Typography)(() => ({
	fontSize: "0.75rem",
}));

function InlineErrorMessages({
	message = "",
	width = "20rem",
	alignRight = false,
	marginTop = 0,
	color = "white",
	dataTestId = "inline-error-message",
}) {
	return (
		<Box
			sx={{
				width,
				display: "flex",
				alignItems: "center",
				marginTop,
				color,
			}}
		>
			<Box sx={{ marginRight: 1, display: "flex", alignItems: "center" }}>
				<ErrorIcon height={16} width={16} />
			</Box>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<MessageLabel
					variant="subtitle1"
					component="span"
					align={alignRight ? "right" : "left"}
					data-testid={dataTestId}
				>
					{message}
				</MessageLabel>
			</Box>
		</Box>
	);
}

InlineErrorMessages.propTypes = {
	width: PropTypes.string,
	message: PropTypes.string,
	alignRight: PropTypes.bool,
	marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	dataTestId: PropTypes.string,
};

export default InlineErrorMessages;
