const SCHEDULE_ACTIONS = {
	SET_PRODUCTION_DAY: "SET_PRODUCTION_DAY",
	SET_PRODUCTION_DAY_START: "SET_PRODUCTION_DAY_START",
	SET_PRODUCTION_DAY_END: "SET_PRODUCTION_DAY_END",
	SET_SHIFTS: "SET_SHIFTS",
	LOAD_DATA: "LOAD_DATA",
	UPDATE_PRODUCTION_DAY_AND_SHIFTS: "UPDATE_PRODUCTION_DAY_AND_SHIFTS",
	RESET_PRODUCTION_DAY_AND_SHIFTS: "RESET_PRODUCTION_DAY_AND_SHIFTS",
	TOGGLE_DELETE_SHIFT_MODAL_VISIBILITY: "TOGGLE_DELETE_SHIFT_MODAL_VISIBILITY",
	TOGGLE_CREATE_SHIFT_MODAL_VISIBILITY: "TOGGLE_CREATE_SHIFT_MODAL_VISIBILITY",
	TOGGLE_UPDATE_SHIFT_MODAL_VISIBILITY: "TOGGLE_UPDATE_SHIFT_MODAL_VISIBILITY",
	SET_SHOW_UPDATE_SHIFT_MODAL: "SET_SHOW_UPDATE_SHIFT_MODAL",
	SET_HIDE_UPDATE_SHIFT_MODAL: "SET_HIDE_UPDATE_SHIFT_MODAL",
	CHANGE_SHIFT_NAME: "CHANGE_SHIFT_NAME",
	CHANGE_SHIFT_START_TIME: "CHANGE_SHIFT_START_TIME",
	CHANGE_SHIFT_END_TIME: "CHANGE_SHIFT_END_TIME",
	ADD_NEW_SHIFT: "ADD_NEW_SHIFT",
	DELETE_LOCAL_SHIFT: "DELETE_LOCAL_SHIFT",
	CHANGE_ENDS_ON: "CHANGE_ENDS_ON",
	CHANGE_TIME_ZONE: "CHANGE_TIME_ZONE",
	TRIGGER_SHOW_ERRORS: "TRIGGER_SHOW_ERRORS",
	TRIGGER_EMPTY_SHIFTS_ERROR: "TRIGGER_EMPTY_SHIFTS_ERROR",
	TRIGGER_SHOW_PRODUCTION_ERRORS: "TRIGGER_SHOW_PRODUCTION_ERRORS",
	UPDATE_SHIFT_STATE: "UPDATE_SHIFT_STATE",
	ADD_NEW_SUB_SHIFT: "ADD_NEW_SUB_SHIFT",
	EDIT_SUB_SHIFT: "EDIT_SUB_SHIFT",
	REMOVE_BREAK_FROM_STATE: "REMOVE_BREAK_FROM_STATE",
	OVERLAP_ERROR_CHECK: "OVERLAP_ERROR_CHECK",
};

export default SCHEDULE_ACTIONS;
