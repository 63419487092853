import { Box } from "@mui/material";
import React from "react";

function DefaultEmptyTable() {
	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				paddingLeft: "1rem",
				top: "-15rem",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					left: "100%",
				}}
			>
				No Data Present
			</Box>
		</Box>
	);
}

export default DefaultEmptyTable;
