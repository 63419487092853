import React from "react";
import PropTypes from "prop-types";
import { COMMON_MODAL_SYSTEM } from "../constants";

export const GeneralModalDispatchContext = React.createContext();
export const GeneralModalStateContext = React.createContext();

export const useGeneralModalDispatchContext = () =>
	React.useContext(GeneralModalDispatchContext);

export const useGeneralModalStateContext = () =>
	React.useContext(GeneralModalStateContext);

const initialState = {
	modals: [],
	context: null,
};

let id = 0;

function reducer(state, action) {
	switch (action.type) {
		case COMMON_MODAL_SYSTEM.PUSH_MODAL: {
			return {
				...state,
				modals: [...state.modals, action.payload.node],
			};
		}
		case COMMON_MODAL_SYSTEM.POP_MODAL: {
			const newModals = [...state.modals];
			newModals.pop();
			const newContext = action.payload.clearContext ? null : state.context;
			return {
				...state,
				modals: newModals,
				context: newContext,
			};
		}
		default: {
			return {
				...state,
			};
		}
	}
}

export function pushModal(dispatch, node) {
	id += 1;
	// eslint-disable-next-line no-param-reassign
	// node.key = id;
	dispatch({
		type: COMMON_MODAL_SYSTEM.PUSH_MODAL,
		payload: {
			node,
		},
	});
	return id;
}

export function closeRecentModal(dispatch, clearContext = false) {
	dispatch({
		type: COMMON_MODAL_SYSTEM.POP_MODAL,
		payload: {
			clearContext,
		},
	});
}
export function ConfirmModalProvider({ children }) {
	const [state, dispatch] = React.useReducer(reducer, initialState);

	return (
		<GeneralModalDispatchContext.Provider value={dispatch}>
			<GeneralModalStateContext.Provider value={state}>
				{children}
				{state.modals.map((modal, i) => {
					return { ...modal, key: `modal-${i + 1}` };
				})}
			</GeneralModalStateContext.Provider>
		</GeneralModalDispatchContext.Provider>
	);
}

ConfirmModalProvider.propTypes = {
	children: PropTypes.node,
};
