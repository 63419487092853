import { ThemeProvider } from "@mui/material";
import { theme } from "@smf/ui-util-app";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClientProvider, QueryClient } from "react-query";
import enLocale from "date-fns/locale/en-US";
import App from "./containers/Routes";
import { GeneralConfigProvider } from "./context/generalConfigContext";
import { ToastProvider } from "./context/toastContext";
import { InterDependecyModalProvider } from "./context/interDependecyModalContext";

export default function Root() {
	const queryClient = new QueryClient();
	return (
		<ThemeProvider theme={theme}>
			<QueryClientProvider client={queryClient}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={enLocale}
				>
					<GeneralConfigProvider>
						<InterDependecyModalProvider>
							<ToastProvider>
								<App />
							</ToastProvider>
						</InterDependecyModalProvider>
					</GeneralConfigProvider>
				</LocalizationProvider>
			</QueryClientProvider>
		</ThemeProvider>
	);
}
