import PropTypes from "prop-types";
import { forwardRef } from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import GeneralTextField from "../GeneralTextField";

const TimePicker = forwardRef((props, ref) => {
	const {
		value = null,
		handleChange = () => null,
		textFieldPlaceHolder = "HH:MM",
		isError = false,
		isDisabled = false,
		id,
		dataTestId,
		timeString,
		...others
	} = props;

	return (
		<DesktopTimePicker
			value={value}
			disabled={isDisabled}
			onChange={handleChange}
			error={isError}
			key={id}
			renderInput={(params) => (
				<GeneralTextField
					{...params}
					error={isError}
					{...others}
					inputProps={{
						autoComplete: "off",
						...params.inputProps,
						value:
							params.inputProps.value === ""
								? timeString || params.inputProps.value
								: params.inputProps.value,
						placeholder: textFieldPlaceHolder,
					}}
					data-testid={dataTestId}
				/>
			)}
			disableOpenPicker
			label=""
			ampm={false}
			ampmInClock={false}
			inputFormat="HH:mm"
			minTime={new Date(0, 0, 0, 0, 0, 0, 0)}
			maxTime={new Date(0, 0, 0, 23, 59, 59, 59)}
			ref={ref}
		/>
	);
});

TimePicker.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date),
	]),
	handleChange: PropTypes.func.isRequired,
	textFieldPlaceHolder: PropTypes.string,
	isError: PropTypes.bool,
	isDisabled: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	dataTestId: PropTypes.string,
	timeString: PropTypes.string,
};

export default TimePicker;

// Usage:
// <TimePicker value={null | Date} onChange={} sx={{ width: 160 }} />
// Error rendering is controlled in the parent
