import { entity, factoryGeneralConfig } from "@smf/ui-util-app";

export async function getEquipmentIntelligenceExportDataUrl(plantId) {
	return entity.getEquipmentIntelligenceExportDataUrl(plantId);
}

export async function getEquipmentIntelligenceExportData(param) {
	return entity.getEquipmentIntelligenceExportDataUrl(param);
}

export async function getPresignedURLForAIP(param) {
	return entity.getEquipmentIntelligencePresignedUploadUrl(param);
}

export async function uploadFileToS3(presignedUrl, file) {
	return entity.uploadEquipmentIntelligence(presignedUrl, file);
}

export async function processEquipmentIntelligence(data) {
	return entity.processEquipmentIntelligence(data);
}

export async function getCrews(data) {
	return factoryGeneralConfig.upsertCrews(data);
}
export async function deleteCrew(data) {
	return factoryGeneralConfig.deleteCrew({ data });
}

export async function getEquipmentIntelligenceMetaData(params) {
	return entity.getEquipmentIntelligenceMetaData(params);
}

export async function getFinancialsData(params) {
	return factoryGeneralConfig.getFinancialsData(params);
}

export async function submitFinancialsData(data) {
	return factoryGeneralConfig.submitFinancialsData(data);
}

export async function getSchedule(data) {
	const result = await factoryGeneralConfig.getSchedule(data);
	return result.data;
}

export async function mutateSchedule(data) {
	return factoryGeneralConfig.upsertSchedule(data);
}

export async function deleteScheduleShift(data) {
	return factoryGeneralConfig.deleteScheduleShifts({
		data,
	});
}
export async function getGeneralConfigStatus(params) {
	return factoryGeneralConfig.getGeneralConfigStatus(params);
}
export async function updateGeneralConfigStep(data) {
	return factoryGeneralConfig.updateGeneralConfigStatus(data);
}

export async function getRotations(params) {
	return factoryGeneralConfig.getRotations(params);
}

export async function deleteRotations(data) {
	return factoryGeneralConfig.deleteRotations({
		data,
	});
}

export async function updateRotations(data) {
	return factoryGeneralConfig.updateRotations(data);
}

export async function getWeeklyRotationsExportData(params) {
	return factoryGeneralConfig.getWeeklyRotationsExportData(params);
}

export async function getUploadPresignedUrl(param) {
	return factoryGeneralConfig.getUploadPresignedUrl(param);
}

export async function uploadToPresignedUrl(presignedUrl, file) {
	return factoryGeneralConfig.uploadToPresignedUrl(presignedUrl, file);
}

export async function processWeeklyRotations(data) {
	return factoryGeneralConfig.processWeeklyRotationsUpload(data);
}

export async function getWeeklyRotationsData(params) {
	return factoryGeneralConfig.fetchWeeklyRotations(params);
}
export async function getDailyRotationsData(params) {
	return factoryGeneralConfig.fetchDailyRotations(params);
}

export async function fetchDailyRotationsExportData(params) {
	return factoryGeneralConfig.fetchDailyRotationsExportData(params);
}

export async function updateWeeklyRotations(data) {
	return factoryGeneralConfig.updateWeeklyRotations(data);
}
export async function updateDailyRotations(data) {
	return factoryGeneralConfig.updateDailyRotations(data);
}

export async function discardFactoryGeneral(data) {
	return factoryGeneralConfig.discradFactoryGeneral(data);
}

export async function getEquipmentHierarchyData(params) {
	return factoryGeneralConfig.getEquipmentHierarchyData(params);
}
