import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	alpha,
	CircularProgress,
	Box,
	styled,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
	"& .MuiTableCell-head": {
		backgroundColor: theme.palette.background.darkishBlackBlue,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	borderColor: theme.palette.border.darkGrey,
}));

const StyledTableCell = styled(TableCell, {
	shouldForwardProp: (prop) => prop !== "hasLeftCellBorder",
})(({ theme, hasLeftCellBorder }) => ({
	borderLeft: hasLeftCellBorder && `.1em solid`,
	borderLeftColor: alpha(theme.palette.border.darkGrey, 0.3),
	borderBottom: 0,
	"&:first-of-type": {
		paddingLeft: "1.5rem",
	},
}));

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
	background: theme.palette.background.blackGrey,
	minHeight: "3.5em",
	border: `.1em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
	"&.general-table-missing-row": {
		border: ".1em solid transparent",
	},
}));

function BasicTableFetchingUI({ isEmpty = false }) {
	if (isEmpty) {
		return (
			<Box
				sx={{
					position: "relative",
					minHeight: "34rem",
				}}
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "100%",
					}}
				>
					<CircularProgress color="inherit" />
				</Box>
			</Box>
		);
	}
	return (
		<TableRow key="fakeBody">
			<TableCell colSpan={100}>
				<Box
					sx={{
						position: "relative",
					}}
				>
					<Box
						sx={{
							minHeight: "34rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							position: "absolute",
							left: "50%",
							transform: "translateY(-100%)",
						}}
					>
						<CircularProgress color="inherit" />
					</Box>
				</Box>
			</TableCell>
		</TableRow>
	);
}

BasicTableFetchingUI.propTypes = {
	isEmpty: PropTypes.bool,
};

function MissingRows({ enableMissingRows = true, noOfMissing = 0 }) {
	const missingRows = [];

	if (enableMissingRows) {
		const missingRowLength = noOfMissing;
		missingRows.length = missingRowLength < 0 ? 0 : missingRowLength;
		missingRows.fill(1);
	}

	return (
		<>
			{missingRows.map((_row, index) => (
				<StyledTableRow key={`missingrow-${index + 1}`}>
					<StyledTableCell colSpan={100} rowSpan={1}>
						&#8205;
					</StyledTableCell>
				</StyledTableRow>
			))}
		</>
	);
}

MissingRows.propTypes = {
	enableMissingRows: PropTypes.bool,
	noOfMissing: PropTypes.number,
};

// Just a presentational component
function BasicGeneralTable({
	headerGroups = [],
	rows = [],
	showHeader = true,
	showFetchingUI = false,
	showErrorUI = false,
	FetchingComponent = BasicTableFetchingUI,
	ErrorComponent = null,
	state,
	enablePagination,
}) {
	return (
		<TableContainer
			component={Paper}
			className="basicGeneralTable"
			data-testid="basicGeneralTable"
		>
			<Table stickyHeader>
				{showHeader && (
					<StyledTableHead className="basicGeneralTable_header">
						{headerGroups.map((headerGroup) => (
							<StyledTableRow
								{...headerGroup.getHeaderGroupProps()}
								className="basicGeneralTable_headerRow"
							>
								{headerGroup.headers.map((column) => (
									<StyledTableCell
										{...column.getHeaderProps({
											style: { minWidth: column.minWidth, width: column.width },
										})}
									>
										{column.render("Header")}
									</StyledTableCell>
								))}
							</StyledTableRow>
						))}
					</StyledTableHead>
				)}

				{showFetchingUI && rows.length === 0 && <FetchingComponent isEmpty />}
				{showErrorUI && ErrorComponent && <ErrorComponent />}

				<TableBody className="basicGeneralTable_body">
					{rows.map((row, i) => {
						return (
							<StyledTableBodyRow
								{...row.getRowProps()}
								className="basicGeneralTable_bodyRow"
								data-testid="basicGeneralTable_bodyRow"
							>
								{row.cells.map((cell) =>
									cell.column.enableRowSpan && i !== 0 ? null : (
										<StyledTableCell
											data-testid="basicGeneralTable_bodyCell"
											{...cell.getCellProps()}
											rowSpan={`${cell.column.rowSpan ?? 1}`}
											className="basicGeneralTable_bodyCell"
											id="basicGeneralTable_bodyCell"
										>
											{cell.render("Cell")}
										</StyledTableCell>
									)
								)}
							</StyledTableBodyRow>
						);
					})}

					<MissingRows
						enableMissingRows={enablePagination && !showFetchingUI}
						noOfMissing={state.pageSize - rows.length}
					/>

					{showFetchingUI && rows.length > 0 && <FetchingComponent />}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

BasicGeneralTable.propTypes = {
	headerGroups: PropTypes.arrayOf(PropTypes.shape({})),
	rows: PropTypes.arrayOf(PropTypes.shape({})),
	showHeader: PropTypes.bool,
	showFetchingUI: PropTypes.bool,
	showErrorUI: PropTypes.bool,
	FetchingComponent: PropTypes.node,
	ErrorComponent: PropTypes.func,
	enablePagination: PropTypes.bool,
	state: PropTypes.shape({
		pageSize: PropTypes.number,
	}),
};

export default BasicGeneralTable;
