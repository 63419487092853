import { forwardRef, useState } from "react";
import {
	Box,
	ButtonBase,
	Typography,
	styled,
	LinearProgress,
	ListItem,
	List,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import DownloadIcon from "../../assets/img/download.svg";
import UploadIcon from "../../assets/img/whiteUploadIcon.svg";
import InfoToolTip from "../../assets/img/greyInfo.svg";
import FileUploadIcon from "../../assets/img/fileUploadIcon.svg";
import XLSXFileIcon from "../../assets/img/fileIconXLSX.svg";
import CheckedIcon from "../../assets/img/checkedTickMark.svg";
import ErrorIcon from "../../assets/img/errorIcon.svg";
import {
	formatBytes,
	getAcceptType,
	getUploadeInfoMessage,
} from "../../utils/helpers";
import MESSAGE_STRINGS from "../../constants/en-us";
import BlockLevelIndicator from "../BlockLevelIndicator";
import GeneralTooltip from "../GeneralTooltip";
import LoadingIndicator from "../LoadingIndicator";
import NotificationDialog from "../GeneralDialog/NotificationDialog";
import { GeneralDialog } from "../GeneralDialog";
import GeneralButton from "../GeneralButton";
import { BUTTON_TYPE, MODAL_TYPES } from "../../constants";

const CustomizedInfoTooltip = styled(InfoToolTip)`
	height: '0.75rem',
	width: '0.75rem',
`;

const CustomizedDropZone = styled(Box)(({ theme }) => ({
	border: `.1rem dashed ${theme.customColors.saveGreen}`,
	borderRadius: "0.5rem",
	color: theme.palette.text.primary,
}));

const CustomizeBaseButton = styled(ButtonBase)(({ theme }) => ({
	padding: "0.5rem",
	"&.Mui-disabled": {
		color: theme.palette.text.darkGrey,
		opacity: 0.5,
		filter:
			"invert(34%) sepia(3%) saturate(801%) hue-rotate(175deg) brightness(92%) contrast(90%)",
	},
}));
const CustomizeLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: "0.438rem",
	backgroundColor: theme.customColors.progressBarEmpty,
	borderRadius: ".25rem",
	marginTop: "1.5rem",
	width: "90%",
	"& .MuiLinearProgress-barColorPrimary": {
		backgroundColor: theme.customColors.progressBarBlue,
		borderRadius: "1rem",
	},
}));
const FileComponentContainer = styled(Box)(({ theme }) => ({
	background: theme.palette.background.blackGrey,
	boxShadow: "0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)",
	borderRadius: ".25rem",
	display: "flex",
	alignItems: "center",
	padding: "1.5rem 1.25rem 1.5rem 1.5rem",
	width: 500,
}));

function CustomFileComponent({ error = false, file = {}, uploadUserInfo }) {
	const { name = "", size = 0 } = file;
	return (
		<>
			<FileComponentContainer>
				<XLSXFileIcon height={52} width={52} />
				<Box
					sx={{
						marginLeft: "2rem",
						width: "75%",
					}}
				>
					<Typography
						variant="subtitle2"
						data-testid="filename_EI"
						sx={{
							textTransform: "none",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{name}
					</Typography>
					{!error ? (
						<Typography
							data-testid="filesize_EI"
							variant="subtitle2"
							sx={{
								color: (theme) => theme.palette.text.lightWhiteGrey,
							}}
						>
							{formatBytes(size)}
						</Typography>
					) : (
						<CustomizeLinearProgress
							variant="determinate"
							color="primary"
							value={Math.round(75)}
						/>
					)}
				</Box>
				{error ? (
					<ErrorIcon height={24} width={24} data-testid="fileUpload_error" />
				) : (
					<CheckedIcon
						height={24}
						width={24}
						data-testid="fileUpload_success"
					/>
				)}
			</FileComponentContainer>
			{!error && uploadUserInfo && (
				<Typography
					sx={{
						fontSize: ".875rem",
						minWidth: 475,
						color: (theme) => theme.palette.text.blockGrey,
						marginY: "0.5rem",
					}}
					data-testid="uploaded-info"
				>
					{getUploadeInfoMessage(uploadUserInfo)}
				</Typography>
			)}
		</>
	);
}
CustomFileComponent.propTypes = {
	error: PropTypes.bool,
	file: PropTypes.shape({ name: PropTypes.string, size: PropTypes.number }),
	uploadUserInfo: PropTypes.shape({
		uploadedBy: PropTypes.string,
		uploadedDate: PropTypes.string,
		uploadedTime: PropTypes.string,
	}),
};
function getDropzoneProps(isPropEnabled, getRootProps) {
	return isPropEnabled ? getRootProps({ className: "dropzone" }) : {};
}

const UploadFile = forwardRef((props, ref) => {
	const {
		tabTitle,
		exportCsvTitle,
		popoverContent = null,
		onExport,
		uploadTitle = "",
		isFileUploadError = false,
		showInitialStep = true,
		fileType = "XLSX",
		getUploadUrl = () => null,
		isLoading = false,
		fileObject = null,
		setFileObject = () => null,
		errorMessages = "",
		reUploadModalData = {
			enabled: false,
			customText: null,
		},
		uploadTooltipContent = "",
		isPreviewComponentEnabled = false,
		isTopUploadVisible = true,
		uploadUserInfo = null,
		children,
	} = props;
	const [isReUploadModalOpen, setIsReUploadModal] = useState(false);
	function onDropAccepted(acceptedFilesData) {
		setFileObject(acceptedFilesData[0]);
		getUploadUrl(acceptedFilesData[0]);
	}
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		multiple: false,
		onDropAccepted,
		accept: getAcceptType(fileType),
	});
	const isUploadFromBoxEnabled = showInitialStep || isFileUploadError;
	return (
		<Box display="flex" flexDirection="column" height="100%" ref={ref}>
			<Box display="flex" alignItems="center">
				<Box>
					<Typography variant="h5" data-testid="header_EI_title">
						{tabTitle}
					</Typography>
				</Box>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="flex-end"
					flexGrow={1}
				>
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						<CustomizeBaseButton
							onClick={onExport}
							disabled={false}
							data-testid="export-button"
						>
							<DownloadIcon height={32} width={32} />
							<Typography
								variant="h5"
								sx={{
									marginLeft: "0.75em",
								}}
							>
								{exportCsvTitle}
							</Typography>
						</CustomizeBaseButton>
						{popoverContent && (
							<GeneralTooltip
								data-testid="export-tooltip-icon"
								maxWidth="23.438rem"
								message={
									<Typography
										variant="subtitle1"
										data-testid="export-tooltip-content"
									>
										{popoverContent}
									</Typography>
								}
							>
								<CustomizedInfoTooltip height={16} width={16} />
							</GeneralTooltip>
						)}
					</Box>
					{!showInitialStep &&
						!isLoading &&
						!isFileUploadError &&
						isTopUploadVisible && (
							<Box
								display="flex"
								alignItems="center"
								justifyContent="flex-end"
								marginLeft="1.4rem"
							>
								<CustomizeBaseButton
									data-testid="upload-button"
									onClick={() => setIsReUploadModal(true)}
									{...getDropzoneProps(
										!reUploadModalData.enabled,
										getRootProps
									)}
								>
									{!reUploadModalData.enabled ? (
										<input {...getInputProps()} />
									) : null}
									<UploadIcon height={32} width={32} />
									<Typography
										variant="h5"
										sx={{
											marginLeft: "0.75em",
											color: (theme) => theme.palette.text.primary,
										}}
									>
										{
											MESSAGE_STRINGS[
												`UploadComponent.secondaryUpload.${fileType}`
											]
										}
									</Typography>
								</CustomizeBaseButton>
								<GeneralTooltip
									data-testid="upload-tooltip-icon"
									maxWidth="23.438rem"
									message={
										<Typography
											variant="subtitle1"
											data-testid="upload-tooltip-content"
										>
											{uploadTooltipContent}
										</Typography>
									}
								>
									<CustomizedInfoTooltip height={16} width={16} />
								</GeneralTooltip>
							</Box>
						)}
				</Box>
			</Box>
			{!isPreviewComponentEnabled ? (
				<>
					<Box minHeight="4.68rem">
						{isFileUploadError &&
							errorMessages &&
							typeof errorMessages === "string" && (
								<BlockLevelIndicator>
									<List disablePadding>
										{errorMessages.split("\n").map((msg, idx) => (
											<ListItem
												key={`errorItem-${idx + 1}`}
												disablePadding
												sx={{
													marginBottom: "0.375rem",
												}}
											>
												<Typography
													variant="subtitle1"
													data-testid={`errorItem-${idx + 1}`}
												>{`${idx + 1}. ${msg}`}</Typography>
											</ListItem>
										))}
									</List>
								</BlockLevelIndicator>
							)}
					</Box>
					<Box
						display="flex"
						height="23.125rem"
						justifyContent="center"
						alignItems="center"
					>
						<CustomizedDropZone
							data-testid="primary-upload-button"
							{...getDropzoneProps(isUploadFromBoxEnabled, getRootProps)}
							width={790}
							minHeight="14.75rem"
							sx={{
								justifyContent: "center",
								display: "flex",
								alignItems: "center",
								flexDirection: "column",
								paddingY: "1rem",
								"&:hover": {
									cursor: isUploadFromBoxEnabled ? "pointer" : "inherit",
								},
							}}
						>
							{!isLoading && (
								<>
									{showInitialStep && acceptedFiles.length === 0 && (
										<>
											<input {...getInputProps()} />
											<FileUploadIcon
												width={50}
												height={50}
												data-testid="upload_EI_Icon"
											/>
											<Typography
												variant="subtitle1"
												sx={{ margin: "0.65rem" }}
											>
												{uploadTitle}
											</Typography>
										</>
									)}
									{(fileObject || acceptedFiles.length > 0) && (
										<>
											<CustomFileComponent
												file={fileObject || acceptedFiles[0]}
												error={isFileUploadError}
												uploadUserInfo={uploadUserInfo}
											/>
											{isFileUploadError && (
												<Box
													display="flex"
													alignItmes="center"
													justifyContent="center"
													flexDirection="column"
													marginTop="1rem"
													alignItems="center"
												>
													<input {...getInputProps()} />
													<FileUploadIcon
														width={50}
														height={50}
														data-testid="upload_EI_Icon"
													/>
													<Typography
														variant="subtitle1"
														sx={{ marginTop: "0.65rem" }}
													>
														{MESSAGE_STRINGS["UploadComponent.reUpload.text"]}
													</Typography>
												</Box>
											)}
										</>
									)}
								</>
							)}
							{isLoading && (
								<Box display="flex" alignItems="center">
									<LoadingIndicator size={32} />
									<Typography sx={{ marginLeft: "1.25rem" }}>
										{MESSAGE_STRINGS["UploadComponent.loading"]}
									</Typography>
								</Box>
							)}
						</CustomizedDropZone>
					</Box>
				</>
			) : (
				children
			)}
			<GeneralDialog
				open={isReUploadModalOpen}
				fullWidth={false}
				sx={{
					"& .notificationdialog-title": { fontWeight: "700" },
				}}
			>
				<NotificationDialog
					minWidth="20rem"
					maxWidth="34rem"
					type={MODAL_TYPES.CONFIRM}
					customTitle={
						MESSAGE_STRINGS["UploadComponent.reUploadModal.customTitle"]
					}
					customText={reUploadModalData.customText}
					noteText={MESSAGE_STRINGS["UploadComponent.reUploadModal.noteText"]}
					lightText={false}
					customActions={
						<>
							<GeneralButton
								data-testid="reupload-no-button"
								type={BUTTON_TYPE.SECONDARY}
								text={
									MESSAGE_STRINGS["UploadComponent.reUploadModal.action.NO"]
								}
								onClick={() => setIsReUploadModal(false)}
							/>
							<GeneralButton
								data-testid="reupload-yes-button"
								type={BUTTON_TYPE.PRIMARY}
								{...getRootProps()}
								onClick={(e) => {
									getRootProps().onClick(e);
									setIsReUploadModal(false);
								}}
							>
								<input {...getInputProps()} />
								<Typography variant="subtitle1">
									{MESSAGE_STRINGS["UploadComponent.reUploadModal.action.YES"]}
								</Typography>
							</GeneralButton>
						</>
					}
				/>
			</GeneralDialog>
		</Box>
	);
});

UploadFile.propTypes = {
	tabTitle: PropTypes.string,
	exportCsvTitle: PropTypes.string,
	popoverContent: PropTypes.node,
	onExport: PropTypes.func,
	uploadTitle: PropTypes.string,
	showInitialStep: PropTypes.bool,
	isFileUploadError: PropTypes.bool,
	isFileUploadedToS3: PropTypes.bool,
	fileType: PropTypes.string,
	getUploadUrl: PropTypes.func,
	isLoading: PropTypes.bool,
	fileObject: PropTypes.shape({
		name: PropTypes.string,
		size: PropTypes.number,
	}),
	setFileObject: PropTypes.func,
	errorMessages: PropTypes.string,
	reUploadModalData: PropTypes.shape({
		enabled: PropTypes.bool,
		customText: PropTypes.string,
	}),
	uploadTooltipContent: PropTypes.string,
	isPreviewComponentEnabled: PropTypes.bool,
	children: PropTypes.node,
	isTopUploadVisible: PropTypes.bool,
	uploadUserInfo: PropTypes.shape({
		uploadedBy: PropTypes.string,
		uploadedDate: PropTypes.string,
		uploadedTime: PropTypes.string,
	}),
};

export default UploadFile;
