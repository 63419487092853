import { globalstate$ } from "@smf/ui-util-app";
import { useEffect, useState } from "react";

const useRxjsState = () => {
	const [rxjsState, setState] = useState({});

	useEffect(() => {
		if (globalstate$) {
			const subscription = globalstate$.subscribe((globalstate) => {
				setState(globalstate);
			});

			return () => {
				if (subscription) {
					subscription.unsubscribe();
				}
			};
		}
		return null;
	}, []);

	return { rxjsState };
};
export default useRxjsState;
