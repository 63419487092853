import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";
import CustomCalendar from "./CustomCalendar";

import { DAILY_WEEKLY_ROTATION_CONSTANTS } from "../../constants";

import MESSAGE_STRINGS from "../../constants/en-us";
import CalendarEvent from "./CalendarEvent";
import CalendarToolbar from "./CalendarToolbar";
import MonthView from "./MonthView";

function WeeklyAndDailyCalendar({
	viewData = [],
	onDateChanges = () => null,
	isWeeklyRotation = true,
	handleViewChange = () => null,
	rotations = [],
	crews = [],
	onEventSave = () => null,
	isDuplicateCrewAssigned = () => null,
	defaultView = DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.WEEK,
	...restProps
}) {
	const shiftResources = uniqBy(viewData, "title").map((d) => ({
		...d,
		text: d.title,
	}));

	const legendData = isWeeklyRotation
		? rotations
		: shiftResources.sort((a, b) => (a.shiftId < b.shiftId ? -1 : 1));

	const eventPropGetter = useCallback(
		(event) => {
			if (isWeeklyRotation && rotations) {
				const rotationColor = rotations.find((d) => d.text === event.rotation);
				if (rotationColor) {
					return {
						...{
							style: {
								backgroundColor: rotationColor.color,
								borderRadius: "0.5rem",
							},
						},
					};
				}
			}
			return {
				...(event.color && {
					style: {
						backgroundColor: event.color,
						borderRadius: "1rem",
					},
				}),
			};
		},
		[isWeeklyRotation, rotations]
	);

	const { views, messages, components } = useMemo(
		() => ({
			views: {
				[DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.WEEK]: MonthView,
				[DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.DAY]: MonthView,
			},
			messages: {
				[DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.WEEK]:
					MESSAGE_STRINGS["WeeklyAndDailyRotation.RotationsCalendar.weekly"],
				[DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.DAY]:
					MESSAGE_STRINGS["WeeklyAndDailyRotation.RotationsCalendar.daily"],
			},
			components: {
				toolbar: (props) => <CalendarToolbar {...props} legends={legendData} />,
				event: (props) => (
					<CalendarEvent
						isWeeklyRotation={isWeeklyRotation}
						crewList={crews}
						rotationsList={rotations}
						selectDropdownOptions={isWeeklyRotation ? rotations : crews}
						onSave={onEventSave}
						isDuplicateCrewAssigned={isDuplicateCrewAssigned}
						{...props}
					/>
				),
			},
		}),
		[
			crews,
			isDuplicateCrewAssigned,
			isWeeklyRotation,
			legendData,
			onEventSave,
			rotations,
		]
	);

	return (
		<CustomCalendar
			views={views}
			messages={messages}
			events={viewData}
			onNavigate={onDateChanges}
			startAccessor="startDate"
			endAccessor="endDate"
			titleAccessor="eventName"
			onView={handleViewChange}
			eventPropGetter={eventPropGetter}
			components={components}
			defaultView={defaultView}
			onSelectEvent={() => null}
			{...restProps}
		/>
	);
}

export default WeeklyAndDailyCalendar;

WeeklyAndDailyCalendar.propTypes = {
	viewData: PropTypes.arrayOf(PropTypes.shape({})),
	onDateChanges: PropTypes.func,
	isWeeklyRotation: PropTypes.bool,
	handleViewChange: PropTypes.func,
	rotations: PropTypes.arrayOf(PropTypes.shape({})),
	doesNewRotationExist: PropTypes.bool,
	areRotationsPresentForNextDay: PropTypes.bool,
	areRotationsPresentForNextWeek: PropTypes.bool,
	tabTitle: PropTypes.string,
	onExportData: PropTypes.func,
	onUploadCsv: PropTypes.func,
	crews: PropTypes.arrayOf(
		PropTypes.shape({ id: PropTypes.number, text: PropTypes.string })
	),
	onEventSave: PropTypes.func,
	isDuplicateCrewAssigned: PropTypes.func,
	defaultView: PropTypes.string,
};
