/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	styled,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { flexRender } from "@tanstack/react-table";

const StyledConfigShiftsFormContainer = styled("div")(() => ({
	display: "flex",
	width: "100%",
	"& .basicGeneralTable_bodyCell": {
		borderBottom: `1px solid #373737`,
		paddingBottom: "0.625rem",
		paddingTop: "0.625rem",
	},
	"& .basicGeneralTable_headerRow .MuiTableCell-head:last-child": {
		textAlign: "center",
	},
	"& .basicGeneralTable_bodyRow .MuiTableCell-body:last-child": {
		textAlign: "center",
	},
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
	background: theme.palette.background.darkishBlackBlue,
	padding: ".96875rem 0.5rem",
	color: theme.palette.text.lightUnitGrey,
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));

const TableBodyRow = styled(TableRow)(({ theme }) => ({
	height: "3.375rem",
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
	"&:last-child": {
		border: "none",
	},
	"& .smf-shifts-table-body-cell:nth-of-type(2)": {
		maxWidth: 0,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
}));
const TableBodyCell = styled(TableCell)(() => ({
	padding: "0.5rem 0.5rem",
	height: "100%",
	border: "none",
}));
function ConfigShiftsForm({ instance }) {
	return (
		<StyledConfigShiftsFormContainer>
			<Table
				data-testid="shifts-table"
				aria-label="table"
				className="smf-shifts-table"
				sx={{
					border: "none",
				}}
			>
				{instance.getHeaderGroups().map((headerGroup) => (
					<TableHead key={headerGroup.id}>
						<TableRow>
							{headerGroup.headers.map((header) => (
								<HeaderCell
									key={header.id}
									data-testid={`shifts-header-${header.id}`}
								>
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
								</HeaderCell>
							))}
						</TableRow>
					</TableHead>
				))}
				<TableBody>
					{instance.getRowModel().rows.map((row) => {
						return (
							<TableBodyRow
								tabIndex={-1}
								key={row.id}
								data-testid={`shifts-table-body-row-${row.id}`}
							>
								{row.getVisibleCells().map((cell) => {
									return (
										<TableBodyCell
											className="smf-shifts-table-body-cell"
											key={cell.id}
											align={cell.align}
											sx={{ width: cell.column.columnDef.width }}
											data-testid={`shifts-table-body-cell-${cell.id}`}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableBodyCell>
									);
								})}
							</TableBodyRow>
						);
					})}
				</TableBody>
			</Table>
		</StyledConfigShiftsFormContainer>
	);
}
ConfigShiftsForm.propTypes = {
	showProductionErrorsOnNext: PropTypes.shape({
		productionDay: PropTypes.bool,
		shifts: PropTypes.shape({
			shiftId: PropTypes.bool,
		}),
	}),
};
export default ConfigShiftsForm;
