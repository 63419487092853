import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { every, some } from "lodash";
import {
	CREWS_CONFIGURATION_REDUCER_CONSTANTS,
	GENERAL_CONFIGURATION_STEPS,
	QUERY_CONSTANTS,
	TOAST_REDUCER_CONSTANTS,
} from "../constants";
import {
	closeRecentModal,
	pushModal,
	useGeneralModalDispatchContext,
} from "../context/generalModalContext";
import MESSAGE_STRINGS from "../constants/en-us";
import NotificationDialog from "../components/GeneralDialog/NotificationDialog";
import { GeneralDialog } from "../components/GeneralDialog";
import { useToastContext } from "../context/toastContext";
import { deleteCrew, getCrews } from "../utils/apiHelper";
import {
	updateStepStatusCompleted,
	updateStepStatusInProgress,
	useGeneralConfigContext,
} from "../context/generalConfigContext";
import {
	updateCrewModalState,
	useInterDependecyContext,
} from "../context/interDependecyModalContext";

const initialValues = {
	crews: [],
	uid: "0-localCrew",
	visibleErrors: new Set(),
};

const updateUid = (uid) => `${parseInt(uid, 10) + 1}-localCrew`;

function setCrews(state, action) {
	const crews = [];
	if (action.payload.length > 0) {
		crews.push(...action.payload.map((crew) => ({ ...crew, id: crew.crewId })));
	} else {
		crews.push({
			crewId: state.uid,
			id: state.uid,
			crewName: "",
			status: "inactive",
			isError: true,
			showError: false,
			errorMsg: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
			isLocal: true,
			isUpdated: false,
		});
	}
	return crews;
}

function updateCrewMemberFunc(state, action) {
	const newCrews = [...state.crews].map((crew) => ({ ...crew }));
	const { id, newValue } = action.payload;
	const toUpdateCrew = newCrews.find((crew) => crew.id === id);
	const newVisibleErrors = new Set(state.visibleErrors);
	if (toUpdateCrew) {
		toUpdateCrew.isUpdated = true;
		toUpdateCrew.isError = false;
		toUpdateCrew.errorMsg = null;
		toUpdateCrew.showError = false;
		toUpdateCrew.crewName = (newValue || "").trimStart();

		newVisibleErrors.delete(toUpdateCrew.id);

		const nameSet = newCrews.reduce(
			(acc, crew) => new Set([...acc]).add(crew.crewName.trimEnd()),
			new Set()
		);

		const isUnique =
			newCrews.filter(
				(crew) => crew.crewName.trimEnd() === toUpdateCrew.crewName.trimEnd()
			).length === 1;

		if ([...nameSet.keys()].length === newCrews.length) {
			// eslint-disable-next-line no-restricted-syntax
			for (const crew of newCrews) {
				if (crew.crewName !== "") {
					crew.isError = false;
					crew.errorMsg = null;
					newVisibleErrors.delete(crew.id);
				}
			}
		} else if (!isUnique) {
			toUpdateCrew.isError = true;
			toUpdateCrew.showError = true;
			toUpdateCrew.errorMsg = MESSAGE_STRINGS["ERROR_MESSAGES.mustBeUnique"];
			newVisibleErrors.add(toUpdateCrew.id);
		}
		if (
			toUpdateCrew.crewName.length < 1 ||
			toUpdateCrew.crewName.trim().length === 0
		) {
			toUpdateCrew.isError = true;
			toUpdateCrew.showError = true;
			toUpdateCrew.errorMsg = MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"];
			newVisibleErrors.add(toUpdateCrew.id);
		}
	}
	return { newCrews, newVisibleErrors };
}

function reducer(state, action) {
	switch (action.type) {
		case CREWS_CONFIGURATION_REDUCER_CONSTANTS.SET_CREWS: {
			const crews = setCrews(state, action);
			return {
				...state,
				crews,
				uid: action.payload.length > 0 ? state.uid : updateUid(state.uid),
			};
		}
		case CREWS_CONFIGURATION_REDUCER_CONSTANTS.ADD_CREW_MEMBER: {
			const newCrewMember = {
				id: state.uid,
				crewId: state.uid,
				crewName: "",
				isLocal: true,
				isError: true,
				showError: false,
				errorMsg: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
				isUpdated: true,
			};
			return {
				...state,
				crews: [...state.crews, newCrewMember],
				uid: updateUid(state.uid),
			};
		}
		case CREWS_CONFIGURATION_REDUCER_CONSTANTS.DELETE_LOCAL_CREW_MEMBER: {
			const { id } = action.payload;
			const crewToBeDeleted = state.crews.find((crew) => crew.id === id);
			const newCrews = [...state.crews].filter((crew) => crew.id !== id);
			const newVisibleErrors = new Set(state.visibleErrors);
			// remove visible error
			newVisibleErrors.delete(id);

			const duplicateCrew = newCrews.find(
				(crew) =>
					crew.crewName === crewToBeDeleted.crewName && crew.crewName !== ""
			);
			if (duplicateCrew) {
				duplicateCrew.isError = false;
				duplicateCrew.errorMsg = null;
				newVisibleErrors.delete(duplicateCrew.id);
			}
			return {
				...state,
				crews: newCrews,
				visibleErrors: newVisibleErrors,
			};
		}
		case CREWS_CONFIGURATION_REDUCER_CONSTANTS.UPDATE_CREW_MEMBER: {
			const crewAndErrorObject = updateCrewMemberFunc(state, action);
			return {
				...state,
				crews: crewAndErrorObject.newCrews,
				visibleErrors: crewAndErrorObject.newVisibleErrors,
			};
		}
		case CREWS_CONFIGURATION_REDUCER_CONSTANTS.DELETE_CREW_SUCCESS: {
			const { crewId } = action.payload;

			return {
				...state,
				crews: state.crews.filter((crew) => crew.crewId !== crewId),
			};
		}
		case CREWS_CONFIGURATION_REDUCER_CONSTANTS.SET_SHOW_ERROR: {
			const crewsToUpdate = [...state.crews];
			const newCrews = crewsToUpdate.map((obj) => ({
				...obj,
				showError: true,
			}));
			const newVisibleErrors = new Set(state.visibleErrors);
			newCrews.forEach((crew) => {
				if (crew.showError && crew.isError) {
					newVisibleErrors.add(crew.id);
				} else {
					newVisibleErrors.delete(crew.id);
				}
			});
			return {
				...state,
				crews: newCrews,
				visibleErrors: newVisibleErrors,
			};
		}
		default:
			throw new Error("Invalid Action - ", action.type);
	}
}

export function useCrews(plantId, refetchGeneralStatus) {
	const [state, dispatch] = React.useReducer(reducer, initialValues);
	const { toastDispatch } = useToastContext();
	const modalDispatch = useGeneralModalDispatchContext();
	const {
		generalConfigDispatch,
		generalConfigState: { isMaintenanceMode },
	} = useGeneralConfigContext();
	const { interDependecyDispatch } = useInterDependecyContext();
	const queryClient = useQueryClient();
	const { isFetching: isCrewsDataFetching } = useQuery(
		[QUERY_CONSTANTS.FETCH_CREWS, plantId],
		async () => {
			const result = await getCrews({
				factoryID: plantId,
			});

			dispatch({
				type: CREWS_CONFIGURATION_REDUCER_CONSTANTS.SET_CREWS,
				payload: result.data,
			});

			return result.data;
		},
		{
			refetchOnWindowFocus: false,
			retry: false,
		}
	);

	const { mutateAsync: deleteCrewAPI, isLoading: isCrewDeleting } = useMutation(
		({ crewId }) => deleteCrew({ factoryID: plantId, crewId }),
		{
			onSuccess: (_d, passedParams) => {
				if (isMaintenanceMode && passedParams.assigned) {
					updateCrewModalState(
						interDependecyDispatch,
						passedParams.deletedCrew
					);
				}
				if (
					!some(
						state.crews.filter((crew) => crew.crewId !== passedParams.crewId),
						(crew) => crew.isUpdated
					)
				) {
					updateStepStatusCompleted(
						generalConfigDispatch,
						GENERAL_CONFIGURATION_STEPS.CREWS
					);
				}
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
					payload: {
						message:
							MESSAGE_STRINGS[
								"GENERAL_CONFIGURATIONS.CREWS.MESSAGES.deleteCrewSuccess"
							],
					},
				});
				dispatch({
					type: CREWS_CONFIGURATION_REDUCER_CONSTANTS.DELETE_CREW_SUCCESS,
					payload: {
						crewId: passedParams.crewId,
					},
				});
				refetchGeneralStatus();
			},
			onError: (error) => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message:
							error?.response?.data?.message ||
							MESSAGE_STRINGS[
								"GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.deleteCrewFailed"
							],
					},
				});
			},
		}
	);

	const {
		mutateAsync: updateCrewData,
		status: updateCrewStatus,
		reset: updateCrewReset,
	} = useMutation(
		({ plantId: factoryID, itemsToBeUpdated, crewsToBeCreated }) =>
			getCrews({ factoryID, itemsToBeUpdated, crewsToBeCreated }),
		{
			onSuccess: (res) => {
				queryClient.invalidateQueries(QUERY_CONSTANTS.FETCH_CREWS);
				if (res && res.message) {
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
						payload: {
							message: MESSAGE_STRINGS["Toast.message.SUCCESS"],
						},
					});
				}
				updateStepStatusCompleted(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.CREWS
				);
				refetchGeneralStatus();
				updateCrewReset();
			},
			onError: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message:
							MESSAGE_STRINGS[
								"GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.updateCrewFailed"
							],
					},
				});
				setTimeout(updateCrewReset, 2000);
			},
		}
	);

	async function addCrewMember() {
		updateStepStatusInProgress(
			generalConfigDispatch,
			GENERAL_CONFIGURATION_STEPS.CREWS
		);
		dispatch({
			type: CREWS_CONFIGURATION_REDUCER_CONSTANTS.ADD_CREW_MEMBER,
		});
		return true;
	}

	function deleteLocalCrew({ id }) {
		dispatch({
			type: CREWS_CONFIGURATION_REDUCER_CONSTANTS.DELETE_LOCAL_CREW_MEMBER,
			payload: {
				id,
			},
		});
	}

	async function handleOnDeleteCrewMember({ id, assigned, deletedCrew }) {
		deleteCrewAPI({ crewId: id, assigned, deletedCrew }).then(() =>
			closeRecentModal(modalDispatch)
		);
	}

	function deleteCrewMember({ id, isLocal = false }) {
		if (isLocal) {
			deleteLocalCrew({ id });
			if (
				every(
					state.crews.filter((crew) => crew.crewId !== id),
					(crew) => !crew.isUpdated && crew.isLocal
				)
			) {
				updateStepStatusInProgress(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.CREWS
				);
			} else if (
				!some(
					state.crews.filter((crew) => crew.crewId !== id),
					(crew) => crew.isUpdated
				)
			) {
				updateStepStatusCompleted(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.CREWS
				);
			}
		} else {
			const deletedCrew = state.crews.find((d) => d.crewId === id);
			pushModal(
				modalDispatch,
				<GeneralDialog open fullWidth={false}>
					<NotificationDialog
						type="confirm"
						handleSave={() =>
							handleOnDeleteCrewMember({
								id,
								assigned: deletedCrew && deletedCrew.status === "ASSIGNED",
								deletedCrew,
							})
						}
						handleClose={() => closeRecentModal(modalDispatch)}
						cancelText={
							MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText"]
						}
						confirmText={
							MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText"]
						}
						customTitle={
							MESSAGE_STRINGS[
								"GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customTitle"
							]
						}
						customText={
							isCrewDeleting
								? MESSAGE_STRINGS[
										"GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText.inProgress"
								  ]
								: `${MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText"]} ${deletedCrew.crewName}?`
						}
						noteText=""
					/>
				</GeneralDialog>
			);
		}
	}

	function updateCrewMember({ id, newName }) {
		updateStepStatusInProgress(
			generalConfigDispatch,
			GENERAL_CONFIGURATION_STEPS.CREWS
		);
		dispatch({
			type: CREWS_CONFIGURATION_REDUCER_CONSTANTS.UPDATE_CREW_MEMBER,
			payload: {
				id,
				newValue: newName,
			},
		});
	}

	function isAnyErrorPresent() {
		return some(state.crews, (crew) => crew.isError);
	}

	function isAnyUpdatesNotSaved() {
		return some(state.crews, (crew) => crew.isUpdated);
	}

	async function handleSave() {
		const isAnyUpdated = isAnyUpdatesNotSaved();
		const isAnyEmptyField = some(state.crews, (crew) => crew.crewName === "");

		if (!isAnyEmptyField && !isAnyUpdated) {
			return isAnyUpdated;
		}

		const isErrorPresent = isAnyErrorPresent();
		if (isErrorPresent || isAnyEmptyField) {
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"],
				},
			});
			dispatch({ type: CREWS_CONFIGURATION_REDUCER_CONSTANTS.SET_SHOW_ERROR });
			throw new Error(`Errors Present`);
		}
		const crewsToBeCreated = state.crews.reduce((acc, curr) => {
			if (curr.isLocal) {
				const { crewName } = curr;
				return [
					...acc,
					{
						crewName: (crewName || "").trim(),
					},
				];
			}
			return acc;
		}, []);

		const itemsToBeUpdated = state.crews.reduce((acc, curr) => {
			if (!curr.isLocal && curr.isUpdated) {
				const { crewName, crewId } = curr;
				return [
					...acc,
					{
						crewName: (crewName || "").trim(),
						crewId,
					},
				];
			}
			return acc;
		}, []);

		return updateCrewData({
			plantId,
			itemsToBeUpdated,
			crewsToBeCreated,
		});
	}

	const isLoading = isCrewsDataFetching || updateCrewStatus === "loading";
	const isDeleting = isCrewDeleting;

	const isError = state.visibleErrors.size > 0;

	return {
		data: state.crews,
		dispatch,
		isLoading,
		isDeleting,
		isError,
		addCrewMember,
		deleteCrewMember,
		updateCrewMember,
		isAnyErrorPresent,
		isAnyUpdatesNotSaved,
		updateCrewStatus,
		handleSave,
	};
}

export default {};
