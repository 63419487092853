import { forEach, isEqual, some } from "lodash";
import { DROPDOWN_CONSTANTS } from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";

//	for Checking same rotation assignment
export const sameRotationCheck = (rotations) => {
	const sameRotations = new Set();
	for (let i = 0; i < rotations.length - 1; i += 1) {
		for (let j = i + 1; j < rotations.length; j += 1) {
			if (isEqual(rotations[i].shifts, rotations[j].shifts)) {
				sameRotations.add(rotations[i].rotationName);
				sameRotations.add(rotations[j].rotationName);
			}
		}
	}
	if (sameRotations.size > 0) return { sameRotations, error: true };
	return { error: false };
};

//	for checking if rotation has any empty values
export const isEmptyCheck = (rotations) => {
	const rotationsCheck = [...rotations];
	const out = {
		error: false,
		newRotation: [],
		sameAssignedError: false,
	};
	// eslint-disable-next-line no-restricted-syntax
	for (const rotation of rotationsCheck) {
		// eslint-disable-next-line no-restricted-syntax
		for (const shift of rotation.shifts) {
			let k;
			for (k = 1; k <= Object.keys(shift.error).length; k += 1) {
				if (shift[`production_Day_${k}`] === "") {
					shift.error[`production_Day_${k}`].add(0);
					out.error = true;
				}
				if (shift.error[`production_Day_${k}`].size > 0) {
					out.sameAssignedError = true;
				}
			}
		}
	}
	out.newRotation = [...rotationsCheck];
	return out;
};

//	function to convert production days to day sequence
const daySequenceListMapper = (rotationsData) => {
	const prodToDay = {};
	forEach(rotationsData.message.daySequenceList, (value) => {
		prodToDay[value.dayKey] = `${value.daySequence}`;
	});
	return prodToDay;
};

// function to get correct crew IDs
const crewNameToIdMapper = (rotationsData) => {
	const nameToId = {};
	forEach(rotationsData.message.crews, (crew) => {
		nameToId[crew.crewName] = crew.crewId;
	});
	return nameToId;
};

//	update call formatter function
export const putFormatter = (rotations, rotationsData) => {
	const prodToDay = daySequenceListMapper(rotationsData);
	const nameToId = crewNameToIdMapper(rotationsData);
	const rotationsToBeUpdated = [];
	forEach(rotations, (rotation) => {
		if (rotation.isUpdated.size > 0) {
			forEach(rotation.shifts, (shift) => {
				if (rotation.isUpdated.has(shift.shiftName)) {
					const obj = {};
					obj.rotationName = rotation.rotationName;
					if (rotation.rotationID) obj.rotationID = rotation.rotationID;
					obj.shiftID = shift.shiftID;
					obj[prodToDay.production_Day_1] = nameToId[shift.production_Day_1];
					obj[prodToDay.production_Day_2] = nameToId[shift.production_Day_2];
					obj[prodToDay.production_Day_3] = nameToId[shift.production_Day_3];
					obj[prodToDay.production_Day_4] = nameToId[shift.production_Day_4];
					obj[prodToDay.production_Day_5] = nameToId[shift.production_Day_5];
					obj[prodToDay.production_Day_6] = nameToId[shift.production_Day_6];
					obj[prodToDay.production_Day_7] = nameToId[shift.production_Day_7];
					rotationsToBeUpdated.push(obj);
				}
			});
		}
	});
	return {
		factoryID: rotationsData.message.factoryID,
		rotationsToBeUpdated,
	};
};

export const getRotationMessage = (isNextDay = false) => {
	return isNextDay
		? MESSAGE_STRINGS["ROTATION.productionDay.caption.nextDay"]
		: MESSAGE_STRINGS["ROTATION.productionDay.caption.sameDay"];
};

export const checkIfUpdatedStateExists = (rotations) =>
	some(rotations, (data) => data.isUpdated?.size > 0 || data.isNewelyAdded);

/**
 * Function to return Crew Dropdown value
 * @param {Object} shift Shift Object
 * @param {String} key Target Key
 * @returns String value
 */
export const getCrewDropdownValue = (shift, key) =>
	shift[key] === "" ? DROPDOWN_CONSTANTS.DEFAULT_VALUE : shift[key];

export function updateErrorObject(rotation, updatedShift, key, shift) {
	// eslint-disable-next-line array-callback-return
	rotation.shifts.map((_d, z) => {
		if (rotation.shifts[z].shiftName === updatedShift.shiftName) return;

		if (
			rotation.shifts[z][key] === updatedShift[key] &&
			updatedShift[key] !== "None" &&
			updatedShift[key] !== ""
		) {
			updatedShift.error[key].add(rotation.shifts[z].shiftName);
			return;
		}

		if (updatedShift.error[key].has(rotation.shifts[z].shiftName))
			updatedShift.error[key].delete(rotation.shifts[z].shiftName);
		if (rotation.shifts[z].error[key].has(shift.shiftName))
			rotation.shifts[z].error[key].delete(shift.shiftName);
	});
}
