import React, { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import { every } from "lodash";
import {
	CONFIG_CONTEXT,
	GENERAL_CONFIGURATION_STEPS,
	STEPPER_STATUSES,
} from "../constants";

export const GeneralConfigContext = createContext();

export const useGeneralConfigContext = () => useContext(GeneralConfigContext);

const initialState = {
	currentStep: null,
	isMaintenanceMode: false,
	generalConfigTotalSteps: 5,
	steps: [
		{ stepStatus: "NOT_STARTED", stepName: "Equipment Intelligence" },
		{ stepStatus: "NOT_STARTED", stepName: "Schedule" },
		{ stepStatus: "NOT_STARTED", stepName: "Crews" },
		{ stepStatus: "NOT_STARTED", stepName: "Rotation" },
		{
			stepStatus: "NOT_STARTED",
			stepName: "Weekly & Daily Rotation",
		},
	],
	stepsFromApi: [],
	isFirstAPICall: true,
};

const getNewCurrentStep = (state, isMaintenanceMode, currentStep) => {
	if (state.isFirstAPICall && isMaintenanceMode) {
		return GENERAL_CONFIGURATION_STEPS.EQUIPMENT_INTELLIGENCE;
	}
	if (isMaintenanceMode) {
		return state.currentStep;
	}
	return currentStep;
};
function generalConfigReducer(state, action) {
	const { type, payload } = action;
	switch (type) {
		case CONFIG_CONTEXT.STEPPER_CURRENT_STATE: {
			const {
				isMaintenanceMode,
				generalConfigTotalSteps,
				currentStep,
				steps,
				isFirstCall = false,
			} = payload;
			return {
				...state,
				currentStep:
					isFirstCall && isMaintenanceMode
						? GENERAL_CONFIGURATION_STEPS.EQUIPMENT_INTELLIGENCE
						: Number(currentStep),
				isMaintenanceMode,
				generalConfigTotalSteps,
				steps,
				stepsFromApi: [...steps],
				isGeneralConfigCompleted: every(
					steps,
					(step) => step.stepStatus === STEPPER_STATUSES.COMPLETED
				),
			};
		}

		case CONFIG_CONTEXT.SET_CURRENT_STEP: {
			const { isCurrentStepInProgress, stepData, updatedStep, currentStep } =
				payload;
			const newSteps = [...state.steps];
			if (isCurrentStepInProgress) {
				// Revert to old status
				newSteps[currentStep - 1] = stepData.steps[currentStep - 1];
			}
			return {
				...state,
				currentStep: updatedStep,
				steps: newSteps,
			};
		}

		case CONFIG_CONTEXT.SET_STEPPER_STATUS: {
			const { stepToBeUpdated, updatedStatus } = payload;
			const newSteps = state.steps.map((step) => ({ ...step }));
			newSteps[stepToBeUpdated - 1].stepStatus = updatedStatus;
			return {
				...state,
				steps: newSteps,
			};
		}
		case CONFIG_CONTEXT.STEPPER_CURRENT_STATE_SILENT_UPDATE: {
			const {
				isMaintenanceMode,
				generalConfigTotalSteps,
				steps,
				currentStep: currentStepAPI,
			} = payload;
			const newStep = [...steps];
			newStep[state.currentStep - 1] = state.steps[state.currentStep - 1];
			const isGeneralConfigCompleted = every(
				steps,
				(step) => step.stepStatus === STEPPER_STATUSES.COMPLETED
			);
			const newCurrentStep = getNewCurrentStep(
				state,
				isMaintenanceMode,
				currentStepAPI
			);
			return {
				...state,
				currentStep: newCurrentStep,
				isMaintenanceMode,
				generalConfigTotalSteps,
				steps: newStep,
				isGeneralConfigCompleted,
				stepsFromApi: [...newStep],
				isFirstAPICall: false,
			};
		}
		default:
			return state;
	}
}

export function updateStepStatusInProgress(dispatch, currentStep) {
	dispatch({
		type: CONFIG_CONTEXT.SET_STEPPER_STATUS,
		payload: {
			stepToBeUpdated: currentStep,
			updatedStatus: STEPPER_STATUSES.IN_PROGRESS,
		},
	});
}
export function updateStepStatusCompleted(dispatch, currentStep) {
	dispatch({
		type: CONFIG_CONTEXT.SET_STEPPER_STATUS,
		payload: {
			stepToBeUpdated: currentStep,
			updatedStatus: STEPPER_STATUSES.COMPLETED,
		},
	});
}
export function updateStepStatus(dispatch, currentStep, updatedStatus) {
	dispatch({
		type: CONFIG_CONTEXT.SET_STEPPER_STATUS,
		payload: {
			stepToBeUpdated: currentStep,
			updatedStatus,
		},
	});
}
export function updateStep(dispatch, newStep) {
	dispatch({
		type: CONFIG_CONTEXT.SET_CURRENT_STEP,
		payload: { updatedStep: newStep },
	});
}
export function GeneralConfigProvider({ children }) {
	const [generalConfigState, generalConfigDispatch] = useReducer(
		generalConfigReducer,
		initialState
	);

	const generalConfigData = useMemo(
		() => ({ generalConfigState, generalConfigDispatch }),
		[generalConfigState]
	);

	return (
		<GeneralConfigContext.Provider value={generalConfigData}>
			{children}
		</GeneralConfigContext.Provider>
	);
}

GeneralConfigProvider.propTypes = {
	children: PropTypes.node,
};
