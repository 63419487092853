import { DialogActions, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import {
	StyledModalContainer,
	StyledDialog,
	StyledModalContent,
	StyledHeaderBox,
} from "../styles";
import { BUTTON_TYPE } from "../../../../constants";
import GeneralButton from "../../../../components/GeneralButton";
import InfoIcon from "../../../../assets/img/infoIcon.svg";

export default function DeleteShiftModal({ onCancelClick, onSave }) {
	return (
		<StyledDialog
			data-testid="delete-shift-modal"
			open
			sx={{
				".MuiDialog-paper": { width: "30rem" },
			}}
			className="shift-edit-modal"
		>
			<StyledModalContainer>
				<StyledHeaderBox>
					<InfoIcon
						height="2rem"
						width="2rem"
						style={{ marginRight: ".75rem" }}
					/>
					<Typography sx={{ fontSize: "1.25rem" }}>
						<strong>Confirm</strong>
					</Typography>
				</StyledHeaderBox>
				<StyledModalContent sx={{ padding: "1.5rem 0 1rem 4.25rem" }}>
					<Typography>You are about to delete a break.</Typography>
					<br />
					<Typography>
						<strong>Would you like to continue?</strong>
					</Typography>
				</StyledModalContent>
				<DialogActions sx={{ paddingBottom: "1.5rem", paddingRight: "1.5rem" }}>
					<GeneralButton
						text="No"
						type={BUTTON_TYPE.SECONDARY}
						onClick={onCancelClick}
						dataTestId="general-button-previous"
					/>

					<GeneralButton
						text="Yes"
						onClick={onSave}
						dataTestId="general-button-next"
						type={BUTTON_TYPE.PRIMARY}
					/>
				</DialogActions>
			</StyledModalContainer>
		</StyledDialog>
	);
}

DeleteShiftModal.propTypes = {
	onCancelClick: PropTypes.func,
	onSave: PropTypes.func,
};
