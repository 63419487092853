/* eslint-disable no-unused-vars */
import * as React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import GeneralTextField from "../GeneralTextField";

const paperSx = {
	backgroundColor: (theme) => theme.palette.background.configLighterDark,
	color: "white",
	"& .MuiTypography-root": {
		color: "white",
	},
	"& .MuiPickersDay-root": {
		backgroundColor: (theme) => theme.palette.background.configLighterDark,
	},
	"& .MuiPickersDay-today": {
		border: (theme) =>
			`0.0625em solid ${theme.palette.border.primary} !important`,
	},
	"& .MuiSvgIcon-root": {
		color: (theme) => theme.palette.text.primary,
	},
	"& .MuiPickersDay-dayOutsideMonth": {
		color: (theme) => theme.palette.background.lightWhiteGrey,
	},
	"& .Mui-selected": {
		backgroundColor: (theme) =>
			`${theme.palette.background.saveGreen} !important`,
		color: (theme) =>
			`${theme.palette.background.configLighterDark} !important`,
		"&:focus": {
			backgroundColor: (theme) =>
				`${theme.palette.background.saveGreen} !important`,
			color: (theme) =>
				`${theme.palette.background.configLighterDark} !important`,
		},
		"&:hover": {
			backgroundColor: (theme) => theme.palette.background.saveGreen,
			color: (theme) => theme.palette.text.configLighterDark,
		},
	},
	"& .PrivatePickersSlideTransition-root": {
		minHeight: "15rem",
	},
};

function DatePicker({ value = null, onChange = () => null, ...props }) {
	const [open, setOpen] = React.useState(false);
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				value={value}
				onChange={onChange}
				renderInput={(params) => (
					<GeneralTextField
						{...params}
						sx={{ width: "10rem" }}
						inputProps={{
							...params.inputProps,
							placeholder: "MM/DD/YYYY",
							form: {
								autocomplete: "off",
							},
						}}
					/>
				)}
				InputProps={{
					sx: {
						"& .MuiSvgIcon-root": {
							color: (theme) => theme.palette.border.lightWhiteGrey,
						},
					},
				}}
				PaperProps={{
					sx: paperSx,
				}}
				{...props}
			/>
		</LocalizationProvider>
	);
}
DatePicker.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date),
	]),
	onChange: PropTypes.func,
};
export default DatePicker;
