import { useEffect, useMemo, useReducer, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { add, compareAsc, format, isValid, parse, set } from "date-fns";
import { globalstate$ } from "@smf/ui-util-app";
import { isEmpty, some } from "lodash";
import { Box, Typography } from "@mui/material";
import {
	getCoreRowModel,
	getExpandedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import GeneralConfigBody from "../layouts/GeneralConfigBody";
import GeneralConfigFooter from "../layouts/GeneralConfigFooter";
import scheduleReducer, {
	scheduleInitialValues,
	deleteLocalShift,
	changeEndsOn,
	changeTimeZone,
	triggerShowEmptyRowErrors,
	triggerShowProdDayErrors,
	addNewShift,
	updateShiftState,
	addNewSubShift,
	editSubShift,
	removeBreakFromState,
	triggerOverlapError,
} from "./ScheduleReducer";
import ProductionDay from "./ProductionDay";
import MESSAGE_STRINGS from "../../constants/en-us";
import SCHEDULE_ACTIONS from "./ScheduleActions";
import {
	ENDS_ON_VALUES,
	SHIFT_TEST_ID,
	TIME_FIELD_TYPES,
} from "./ScheduleConstants";
import ConfigShifts from "../../components/ConfigShifts";
import {
	closeRecentModal,
	pushModal,
	useGeneralModalDispatchContext,
} from "../../context/generalModalContext";
import { GeneralDialog } from "../../components/GeneralDialog";
import NotificationDialog from "../../components/GeneralDialog/NotificationDialog";
import ScheduleContextProvider from "../../context/scheduleContext";
import ConfigShiftsForm from "../../components/ConfigShiftsForm";
import ScheduleTimeLine from "../../components/ScheduleTimeLine";

import {
	deleteScheduleShift,
	getEquipmentHierarchyData,
	getSchedule,
	mutateSchedule,
} from "../../utils/apiHelper";
import { useToastContext } from "../../context/toastContext";
import {
	APP_URL,
	BLOCK_LEVEL_INDICATOR_TYPES,
	GENERAL_CONFIGURATION_STEPS,
	NAVIAGTION_TYPE,
	STEPPER_STATUSES,
	QUERY_CONSTANTS,
	TOAST_REDUCER_CONSTANTS,
} from "../../constants";
import CommonBackdrop from "../../components/CommonBackdrop";
import {
	updateStepStatusCompleted,
	updateStepStatusInProgress,
	useGeneralConfigContext,
} from "../../context/generalConfigContext";
import BlockLevelIndicator from "../../components/BlockLevelIndicator";
import {
	updateShiftAddModalState,
	useInterDependecyContext,
} from "../../context/interDependecyModalContext";
import useRxjsState from "../../hooks/useRxjsState";
import {
	updateFactoryGeneralEditAction,
	updateRxjsState,
} from "../../utils/rxjs";
import NavigationModal from "../layouts/NavigationModal/NavigationModal";
import {
	isAnyUpdatedStateExists,
	isShiftError,
	isShiftNull,
	isShiftErrorOnNext,
	getItemsToBeCreated,
	getItemsToBeUpdated,
	getShiftItemsToBeCreated,
} from "./scheduleHelpers";
import { showErrorType } from "../../utils/helpers";
import LoadingIndicator from "../../components/LoadingIndicator";
import ShiftNameCell from "./ShiftTableCells/ShiftNameCell";
import ExpandCell from "./ShiftTableCells/ExpandCell";
import ShiftTimeCell from "./ShiftTableCells/ShiftTimeCell";
import ActionCell from "./ShiftTableCells/ActionCell";
import ShiftEditModal from "./ShiftEditModal";
import { ShiftEditProvider } from "./ShiftEditModal/shiftEditContext";
import { validateOverlappingSubShifts } from "./ShiftEditModal/helpers";

function Schedule({
	onNextClickAPI,
	navigateTo,
	isInterDepedencyState,
	refetchGeneralStatus,
	onPreviousClickAPI,
}) {
	const plantId = globalstate$?.value?.plantId;
	const [state, dispatch] = useReducer(scheduleReducer, scheduleInitialValues);
	const modalDispatch = useGeneralModalDispatchContext();
	const queryClient = useQueryClient();
	const { interDependecyDispatch } = useInterDependecyContext();
	const { toastDispatch } = useToastContext();
	const { rxjsState } = useRxjsState();
	const {
		generalConfigState: { isMaintenanceMode, steps },
		generalConfigDispatch,
	} = useGeneralConfigContext();
	const {
		productionDayStartTime,
		productionDayEndTime,
		productionDayTimezone,
		productionDayEndsOn,
		hasStartTimeChanged: startTimeChanged,
	} = state;
	const {
		factoryGeneralEditState: { action: factoryGeneralAction = {} } = {},
	} = rxjsState;

	const [showProductionErrorsOnNext, setshowProductionErrorsOnNext] = useState({
		productionDay: false,
		shifts: {},
	});
	const { data: hierarchyData } = useQuery({
		queryKey: ["getEIHierarchy"],
		queryFn: () =>
			getEquipmentHierarchyData({
				plantId,
				level: "Asset",
			}),
		retry: false,
		refetchOnWindowFocus: false,
	});
	// eslint-disable-next-line no-unused-vars
	const { isLoading: isScheduleLoading, isFetching: isScheduleFetching } =
		useQuery(
			[QUERY_CONSTANTS.FETCH_SCHEDULE, plantId],
			() =>
				getSchedule({
					factoryID: plantId,
				}),
			{
				onSuccess: (result) => {
					const { shifts, productionDay, timezoneOptions } = result;
					dispatch({
						type: SCHEDULE_ACTIONS.LOAD_DATA,
						payload: {
							shifts,
							productionDay,
							timezoneOptions,
						},
					});
				},
				refetchOnWindowFocus: false,
			}
		);

	const { mutateAsync: updateShift, isLoading: isUpdateScheduleLoading } =
		useMutation((data) => mutateSchedule(data), {
			onSuccess: (_res, data) => {
				if (isMaintenanceMode && data.shiftsToBeCreated) {
					updateShiftAddModalState(
						interDependecyDispatch,
						data.shiftsToBeCreated
					);
				}
				queryClient.invalidateQueries(QUERY_CONSTANTS.FETCH_SCHEDULE);
				updateStepStatusCompleted(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.SCHEDULE
				);
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
					payload: {
						message: MESSAGE_STRINGS["Toast.message.SUCCESS"],
					},
				});
				if (isMaintenanceMode) {
					updateRxjsState({ isEIPorUoMChanged: true });
					refetchGeneralStatus();
				}
			},
			onError: () => {
				toastDispatch(showErrorType());
			},
		});

	const { mutateAsync: deleteShiftAPI, isLoading: isDeleteLoading } =
		useMutation(
			({ shiftId }) =>
				deleteScheduleShift({ shiftID: shiftId, factoryID: plantId }),
			{
				onSuccess: (_d, passedParams) => {
					if (!isAnyUpdatedStateExists(state, passedParams.shiftId)) {
						updateStepStatusCompleted(
							generalConfigDispatch,
							GENERAL_CONFIGURATION_STEPS.SCHEDULE
						);
					}
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS,
						payload: {
							message: MESSAGE_STRINGS["Toast.message.SUCCESS"],
						},
					});
					refetchGeneralStatus();
				},
				onError: () => {
					toastDispatch(showErrorType());
				},
			}
		);
	const handleDeleteBreak = useCallback(
		async (row) => {
			await deleteShiftAPI({ shiftId: row.id, plantId });
			removeBreakFromState(dispatch, { row });
		},
		[deleteShiftAPI, dispatch, plantId]
	);
	async function handleChecks() {
		const errorString = validateOverlappingSubShifts(state?.shifts ?? []);
		if (errorString !== "") {
			triggerOverlapError(dispatch, errorString);
			throw new Error(
				MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"]
			);
		}
		if (errorString === "" && state?.overlapError !== "")
			triggerOverlapError(dispatch, "");
		const isShiftsAreDisabled =
			state.productionDayStartTime.value === null ||
			state.productionDayStartTime.isError ||
			state.productionDayEndTime.value === null ||
			state.productionDayEndTime.isError ||
			state.productionDayEndsOn.value === null ||
			state.productionDayTimezone.value === null ||
			state.productionDayTimezone.isError;
		if (isShiftsAreDisabled) {
			triggerShowProdDayErrors(dispatch);
			setshowProductionErrorsOnNext({
				...showProductionErrorsOnNext,
				productionDay: true,
			});
			throw new Error(
				MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"]
			);
		}

		if (state.isAnyShiftsError) {
			throw new Error(
				MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"]
			);
		}

		const isAnyRowEmpty = some(state.shifts, isShiftNull);

		if (isAnyRowEmpty) {
			const newShifts = showProductionErrorsOnNext.shifts;
			state.shifts.forEach((shift) => {
				newShifts[shift.shiftId] = true;
			});
			triggerShowEmptyRowErrors(dispatch);
			setshowProductionErrorsOnNext({
				...showProductionErrorsOnNext,
				newShifts,
			});
			throw new Error(
				MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"]
			);
		}

		const isAnyShiftsError = some(state.shifts, isShiftError);

		if (isAnyShiftsError) {
			throw new Error(
				MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"]
			);
		}
		if (state.shifts.length === 0) {
			throw new Error(MESSAGE_STRINGS["Schedule.error.emptyShift"]);
		}

		return true;
	}

	function onNextClickWrapper(action) {
		return action === MESSAGE_STRINGS["FGC.save"] ? null : onNextClickAPI();
	}

	function handleNavigation(location = "") {
		if (factoryGeneralAction?.type === NAVIAGTION_TYPE.STEP) {
			navigateTo(location, factoryGeneralAction?.type);
			return;
		}
		if (factoryGeneralAction.location) {
			navigateTo(location);
		}
	}

	async function handleSave() {
		const data = {};

		if (
			state.productionDayStartTime.isUpdated ||
			state.productionDayEndTime.isUpdated ||
			state.productionDayTimezone.isUpdated ||
			state.productionDayEndsOn.isUpdated
		) {
			data.productionDay = {
				productionDayID: state.productionDay.productionDayID,
				startTime: format(
					parse(state.productionDayStartTime.value, "HH:mm", new Date()),
					"HH:mm"
				),
				endTime: format(
					parse(state.productionDayEndTime.value, "HH:mm", new Date()),
					"HH:mm"
				),
				timezone: state.productionDayTimezone.value,
				endsOn:
					state.productionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY
						? "sameDay"
						: "nextDay",
			};
		}

		const shiftsToBeCreated = getShiftItemsToBeCreated(state);
		const itemsToBeCreated = getItemsToBeCreated(state);
		const itemsToBeUpdated = getItemsToBeUpdated(state);

		if (itemsToBeUpdated.length > 0) {
			data.itemsToBeUpdated = itemsToBeUpdated;
		}
		if (itemsToBeCreated.length > 0) {
			data.itemsToBeCreated = itemsToBeCreated;
		}

		if (shiftsToBeCreated.length > 0) {
			data.shiftsToBeCreated = shiftsToBeCreated;
		}

		if (Object.keys(data).length > 0) {
			data.factoryID = plantId;
			await updateShift(data);
			return data;
		}
		return data;
	}

	async function onConfirmSaveSchedule(actionType, isEditModal) {
		try {
			const data = await handleSave();
			closeRecentModal(modalDispatch);
			onNextClickWrapper(actionType);
			if (isEditModal && !data?.shiftsToBeCreated) {
				handleNavigation(factoryGeneralAction?.location);
			}
		} catch (e) {
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: e.message,
				},
			});
		}
	}
	async function handlePreviousClick() {
		onPreviousClickAPI();
	}
	async function handleNextClick(actionType = null, isEditModal = false) {
		try {
			await handleChecks();
		} catch (e) {
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: e.message,
				},
			});
			return;
		}
		const itemToUpdate = getItemsToBeUpdated(state);
		if (
			!state.isFirstTime &&
			(state.productionDayStartTime.isUpdated ||
				state.productionDayEndTime.isUpdated ||
				state.productionDayEndsOn.isUpdated ||
				state.productionDayTimezone.isUpdated ||
				itemToUpdate?.length)
		) {
			pushModal(
				modalDispatch,
				<GeneralDialog open fullWidth={false}>
					<NotificationDialog
						type="confirm"
						handleSave={() => onConfirmSaveSchedule(actionType, isEditModal)}
						handleClose={() => {
							closeRecentModal(modalDispatch);
						}}
						cancelText={
							MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText"]
						}
						confirmText={
							MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText"]
						}
						customTitle="Schedule"
						customText={
							MESSAGE_STRINGS["Schedule.updateScheduleModal.customText"]
						}
						noteText={
							MESSAGE_STRINGS["Schedule.updateScheduleModal.customNote.1"]
						}
					/>
				</GeneralDialog>
			);
		} else {
			const isAnyUpdatesLeft = some(
				state.shifts,
				(shift) =>
					shift.isUpdated ||
					some(shift.breaks, (b) => b.isUpdated) ||
					some(
						shift.subShifts,
						(subShift) =>
							some(subShift.breaks, (b) => b.isUpdated) || subShift.isUpdated
					)
			);
			if (
				isAnyUpdatesLeft ||
				state.productionDayStartTime.isUpdated ||
				state.productionDayEndTime.isUpdated ||
				state.productionDayEndsOn.isUpdated ||
				state.productionDayTimezone.isUpdated
			) {
				await onConfirmSaveSchedule(actionType, isEditModal);
			} else {
				await onNextClickWrapper(actionType);
				if (isEditModal) handleNavigation(factoryGeneralAction?.location);
			}
		}
	}

	function handleSaveClick() {
		handleNextClick(MESSAGE_STRINGS["FGC.save"]);
	}

	function handleSameDayChange(e) {
		changeEndsOn(dispatch, {
			newEndsOn: e.target.value,
		});
		updateStepStatusInProgress(
			generalConfigDispatch,
			GENERAL_CONFIGURATION_STEPS.SCHEDULE
		);
	}

	function handleProductionDayTimeChange(parsedDate, keyboardInput, type) {
		if (type === TIME_FIELD_TYPES.START_TIME) {
			dispatch({
				type: SCHEDULE_ACTIONS.SET_PRODUCTION_DAY_START,
				payload: {
					newStartTime: {
						...productionDayStartTime,
						value: keyboardInput,
						formattedTime: parsedDate,
						renderTime: parsedDate,
					},
				},
			});
		} else {
			dispatch({
				type: SCHEDULE_ACTIONS.SET_PRODUCTION_DAY_END,
				payload: {
					newEndTime: {
						...productionDayEndTime,
						value: keyboardInput,
						formattedTime: parsedDate,
						renderTime: parsedDate,
					},
				},
			});
		}
		updateStepStatusInProgress(
			generalConfigDispatch,
			GENERAL_CONFIGURATION_STEPS.SCHEDULE
		);
	}

	const onConfirmDeleteShift = useCallback(
		({ id, type, parentId }) => {
			deleteShiftAPI({
				shiftId: id,
				plantId,
			})
				.then(() => {
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
						payload: {
							message: `Successfully deleted ${
								type === "shift" ? "shift" : "sub-shift"
							}`,
						},
					});
					deleteLocalShift(dispatch, { id, type, parentId });
					closeRecentModal(modalDispatch);
				})
				.catch((axObj) => {
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
						payload: {
							message: axObj?.response?.data?.message || "error!",
						},
					});
					closeRecentModal(modalDispatch);
				});
		},
		[deleteShiftAPI, modalDispatch, plantId, toastDispatch]
	);

	function handleTimezoneChange(e) {
		changeTimeZone(dispatch, {
			newTimezoneValue: e.target.value,
		});
		updateStepStatusInProgress(
			generalConfigDispatch,
			GENERAL_CONFIGURATION_STEPS.SCHEDULE
		);
	}

	useEffect(() => {
		if (
			steps[GENERAL_CONFIGURATION_STEPS.SCHEDULE - 1].stepStatus ===
				STEPPER_STATUSES.IN_PROGRESS &&
			isMaintenanceMode === true
		) {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: true });
		} else {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: false });
		}
		return () => {
			updateFactoryGeneralEditAction(
				{ isUserNavigating: false, location: "", type: null },
				{ isUnsavedChange: false }
			);
		};
	}, [steps, isMaintenanceMode]);

	const onShiftEditSave = (newShift, actionType) => {
		if (newShift.isUpdated || newShift.breaks?.some((br) => br.isUpdated)) {
			updateStepStatusInProgress(
				generalConfigDispatch,
				GENERAL_CONFIGURATION_STEPS.SCHEDULE
			);
		}
		if (actionType === "ADD_NEW_SHIFT") {
			return addNewShift(dispatch, { addedShift: newShift });
		}
		if (actionType === "EDIT_SHIFT") {
			return updateShiftState(dispatch, { shift: newShift });
		}
		if (actionType === "ADD_NEW_SUB_SHIFT") {
			return addNewSubShift(dispatch, { addedSubShift: newShift });
		}
		return editSubShift(dispatch, { subShift: newShift });
	};
	function onClickCreateNewShift() {
		pushModal(
			modalDispatch,
			<ShiftEditProvider>
				<ShiftEditModal
					actionType="ADD_NEW_SHIFT"
					onCancelClick={() => {
						closeRecentModal(modalDispatch);
					}}
					state={state}
					onSaveClick={onShiftEditSave}
					hierarchyData={hierarchyData?.data}
					factoryId={plantId}
				/>
			</ShiftEditProvider>
		);
	}

	const handleEditClick = useCallback(
		({ row, type }) => {
			const parentShift = state?.shifts?.find(
				(shift) => shift.id === row?.parentId
			);
			pushModal(
				modalDispatch,
				<ShiftEditProvider>
					<ShiftEditModal
						actionType={type === "subShift" ? "EDIT_SUB_SHIFT" : "EDIT_SHIFT"}
						selectedShift={row}
						onCancelClick={() => {
							closeRecentModal(modalDispatch);
						}}
						state={state}
						parentShift={parentShift} // Only for sub-shift
						onSaveClick={onShiftEditSave}
						hierarchyData={hierarchyData?.data}
						shiftType={type}
						factoryId={plantId}
						deleteBreak={handleDeleteBreak}
					/>
				</ShiftEditProvider>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[hierarchyData?.data, modalDispatch, state]
	);
	const handleAddSubShift = useCallback(
		({ row }) => {
			const parentShift = state?.shifts?.find((shift) => shift.id === row?.id);
			pushModal(
				modalDispatch,
				<ShiftEditProvider>
					<ShiftEditModal
						actionType="ADD_NEW_SUB_SHIFT"
						onCancelClick={() => {
							closeRecentModal(modalDispatch);
						}}
						state={state}
						parentShift={parentShift}
						onSaveClick={onShiftEditSave}
						hierarchyData={hierarchyData?.data}
						shiftType="subShift"
						factoryId={plantId}
					/>
				</ShiftEditProvider>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[hierarchyData?.data, modalDispatch, state]
	);
	const handleOnDeleteShift = useCallback(
		({ id, isLocal, shiftName, type, parentId }) => {
			if (!isLocal) {
				pushModal(
					modalDispatch,
					<GeneralDialog open fullWidth={false}>
						<NotificationDialog
							type="confirm"
							handleSave={() => onConfirmDeleteShift({ id, type, parentId })}
							handleClose={() => {
								closeRecentModal(modalDispatch);
							}}
							cancelText={
								MESSAGE_STRINGS[
									"GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText"
								]
							}
							confirmText={
								MESSAGE_STRINGS[
									"GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText"
								]
							}
							customTitle={
								MESSAGE_STRINGS[`Schedule.deleteModal.title.${type}`]
							}
							customText={`${MESSAGE_STRINGS["Actions.permissions.doYouWishToDelete"]} ${shiftName}?`}
							noteText={
								type === "subShift"
									? ""
									: "Note: Deleting a shift will affect the rotation screen and will also delete all assigned crews."
							}
						/>
					</GeneralDialog>
				);
			} else {
				deleteLocalShift(dispatch, { id, type, parentId });
				if (!isAnyUpdatedStateExists(state, id)) {
					updateStepStatusCompleted(
						generalConfigDispatch,
						GENERAL_CONFIGURATION_STEPS.SCHEDULE
					);
				}
			}
		},
		[generalConfigDispatch, modalDispatch, onConfirmDeleteShift, state]
	);

	function handleNextClickstate() {
		if (
			showProductionErrorsOnNext.productionDay &&
			!state.productionDayStartTime.isError &&
			!state.productionDayTimezone.isError &&
			!state.productionDayEndTime.isError
		) {
			setshowProductionErrorsOnNext({
				...showProductionErrorsOnNext,
				productionDay: false,
			});
		} else if (!isEmpty(showProductionErrorsOnNext.shifts)) {
			const isAnyShiftsError = some(state.shifts, isShiftErrorOnNext);
			if (!isAnyShiftsError) {
				const shifts = Object.keys(showProductionErrorsOnNext.shifts);
				const newShifts = showProductionErrorsOnNext.shifts;
				shifts.forEach((element) => {
					delete newShifts[element];
				});
				setshowProductionErrorsOnNext({
					...showProductionErrorsOnNext,
					newShifts,
				});
			}
		}
	}

	function handleErrorChecks() {
		const isShiftsAreDisabled =
			(state.productionDayStartTime.value !== null &&
				state.productionDayStartTime.isError) ||
			(state.productionDayTimezone.value !== null &&
				state.productionDayTimezone.isError) ||
			(state.productionDayEndTime.value !== null &&
				state.productionDayEndTime.isError &&
				state.productionDayEndTime.value !== "");
		if (isShiftsAreDisabled || showProductionErrorsOnNext.productionDay) {
			handleNextClickstate();
			return true;
		}

		if (
			state.isAnyShiftsError ||
			Boolean(state.subShiftMappingError) ||
			Boolean(state.shiftMappingError)
		) {
			return true;
		}

		let isAnyShiftsError = false;

		if (isEmpty(showProductionErrorsOnNext.shifts) && state?.shifts?.length) {
			isAnyShiftsError = some(state.shifts, isShiftError);
		}

		if (isAnyShiftsError || !isEmpty(showProductionErrorsOnNext.shifts)) {
			handleNextClickstate();
			return true;
		}

		return false;
	}

	const mappedMethods = useMemo(
		() => ({
			handleOnDeleteShift,
			handleEditClick,
			handleAddSubShift,
		}),
		[handleOnDeleteShift, handleEditClick, handleAddSubShift]
	);
	const add25Hrs = add(productionDayStartTime.formattedTime, {
		hours: 25,
	});
	const graphTimeLineEnd =
		compareAsc(add25Hrs, productionDayEndTime.formattedTime) === -1
			? productionDayEndTime.formattedTime
			: add25Hrs;

	const isShiftsDisabled =
		state.productionDayStartTime.value === null ||
		state.productionDayStartTime.isError ||
		state.productionDayEndTime.value === null ||
		state.productionDayEndTime.isError ||
		state.productionDayEndsOn.value === null ||
		state.productionDayTimezone.value === null ||
		state.productionDayTimezone.isError;

	const isLoading =
		isScheduleLoading ||
		isScheduleFetching ||
		isUpdateScheduleLoading ||
		isDeleteLoading;

	const isSaveEnabled = handleErrorChecks();
	const isSameLocation =
		factoryGeneralAction.location === APP_URL.CONFIG ||
		factoryGeneralAction.location === APP_URL.GENERAL_CONFIG;
	const columns = useMemo(() => {
		return [
			{
				accessorKey: "expander",
				header: () => "",
				cell: ExpandCell,
				width: "10.38%",
			},
			{
				header: (
					<Typography data-testid={SHIFT_TEST_ID.HEADER_NAME}>
						{MESSAGE_STRINGS["Schedule.Shifts.tableHeader.shiftName"]}
					</Typography>
				),
				accessorKey: "shiftName",
				cell: ShiftNameCell,
				width: "22.94%",
			},
			{
				header: (
					<Typography data-testid={SHIFT_TEST_ID.HEADER_ST}>
						{MESSAGE_STRINGS["Schedule.Shifts.tableHeader.startTime"]}
					</Typography>
				),
				accessorKey: "renderStartTime",
				cell: ShiftTimeCell,
				width: "22.94%",
			},
			{
				header: (
					<Typography data-testid={SHIFT_TEST_ID.HEADER_ET}>
						{MESSAGE_STRINGS["Schedule.Shifts.tableHeader.endTime"]}
					</Typography>
				),
				accessorKey: "renderEndTime",
				cell: ShiftTimeCell,
				width: "20.77%",
			},
			{
				header: (
					<Typography data-testid={SHIFT_TEST_ID.HEADER_ACTION}>
						{MESSAGE_STRINGS["Schedule.Shifts.tableHeader.action"]}
					</Typography>
				),
				accessorKey: "action",
				cell: ActionCell,
				width: "15%",
			},
		];
	}, []);
	const [expanded, setExpanded] = useState({});
	const instance = useReactTable({
		data: state.shifts,
		columns,
		state: {
			expanded,
		},
		enableSubRowSelection: true,
		onExpandedChange: setExpanded,
		getExpandedRowModel: getExpandedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSubRows: (originalRow) => originalRow.subShifts,
	});
	const timeLineShifts = instance
		?.getRowModel()
		?.rows?.map(({ original }, i) => ({
			...original,
			id: i + 1,
		}));
	const validatedGraphTimeLineStart = isValid(
		productionDayStartTime.formattedTime
	)
		? productionDayStartTime.formattedTime
		: add(parse("00:00", "HH:mm", new Date()), {
				hours: 0,
		  });
	const validatedGraphTimeLineEnd = isValid(graphTimeLineEnd)
		? graphTimeLineEnd
		: add(parse("01:00", "HH:mm", new Date()), {
				hours: 24,
		  });

	return (
		<ScheduleContextProvider mappedMethods={mappedMethods}>
			<GeneralConfigBody tabTitle={MESSAGE_STRINGS["Schedule.tabHeading"]}>
				{startTimeChanged && (
					<Box mt=".75rem">
						<BlockLevelIndicator
							type={BLOCK_LEVEL_INDICATOR_TYPES.INFO}
							width="fit-content"
							variant="small"
						>
							{MESSAGE_STRINGS["Schedule.updateProductionDay"]}
						</BlockLevelIndicator>
					</Box>
				)}
				{(!!state.shiftMappingError ||
					!!state.subShiftMappingError ||
					!!state.overlapError) && (
					<Box mt=".75rem">
						<BlockLevelIndicator
							type={BLOCK_LEVEL_INDICATOR_TYPES.ERROR}
							width="fit-content"
							variant="small"
						>
							{state.shiftMappingError ||
								state.subShiftMappingError ||
								state.overlapError}
						</BlockLevelIndicator>
					</Box>
				)}
				<ConfigShifts
					productionDayStart={productionDayStartTime.formattedTime}
					productionDayEnd={productionDayEndTime.formattedTime}
					shifts={state.shifts}
					dispatch={dispatch}
					isShiftsDisabled={isShiftsDisabled}
					onClickCreateNewShift={onClickCreateNewShift}
					configShiftsFormComponent={
						<ConfigShiftsForm
							instance={instance}
							shifts={state.shifts}
							dispatch={dispatch}
							isShiftsDisabled={isShiftsDisabled}
							generalConfigDispatch={generalConfigDispatch}
							showProductionErrorsOnNext={showProductionErrorsOnNext}
						/>
					}
					timeLineComponent={
						<ScheduleTimeLine
							shifts={timeLineShifts}
							startTime={productionDayStartTime.formattedTime}
							endTime={productionDayEndTime.formattedTime}
							graphTimeLineStart={validatedGraphTimeLineStart}
							graphTimeLineEnd={validatedGraphTimeLineEnd}
							dayChangeTime={set(validatedGraphTimeLineEnd, {
								hours: 0,
								minutes: 0,
								seconds: 0,
							})}
							hierarchyData={hierarchyData?.data}
						/>
					}
				/>
				<ProductionDay
					startTime={productionDayStartTime}
					endTime={productionDayEndTime}
					timezone={productionDayTimezone}
					endsOn={productionDayEndsOn}
					isEndsOnEmpty={productionDayEndsOn.value === null}
					handleProductionDayTimeChange={handleProductionDayTimeChange}
					handleSameDayChange={handleSameDayChange}
					handleTimezoneChange={handleTimezoneChange}
					timezones={state.timezoneOptions}
					showProductionErrorsOnNext={showProductionErrorsOnNext}
				/>
				<CommonBackdrop open={isLoading}>
					<LoadingIndicator />
				</CommonBackdrop>
			</GeneralConfigBody>
			<GeneralConfigFooter
				showSave={isMaintenanceMode}
				onNextClick={handleNextClick}
				onClickSave={handleSaveClick}
				onPreviousClick={handlePreviousClick}
				isSaveButtonEnabled={isSaveEnabled}
				isSaveEnabled={isSaveEnabled}
			/>
			<NavigationModal
				open={
					Boolean(factoryGeneralAction?.isUserNavigating) &&
					!isSameLocation &&
					!isInterDepedencyState
				}
				onSave={async () => {
					updateFactoryGeneralEditAction({
						isUserNavigating: false,
						type: null,
					});
					handleNextClick(MESSAGE_STRINGS["FGC.save"], true);
				}}
				onClose={() => handleNavigation(factoryGeneralAction?.location)}
			/>
		</ScheduleContextProvider>
	);
}

Schedule.propTypes = {
	onNextClickAPI: PropTypes.func,
	navigateTo: PropTypes.func,
	isInterDepedencyState: PropTypes.bool,
	refetchGeneralStatus: PropTypes.func,
	onPreviousClickAPI: PropTypes.func,
};

export default Schedule;
