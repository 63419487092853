import { createContext, useContext } from "react";
import PropTypes from "prop-types";

export const ScheduleContext = createContext({});

export function useScheduleContext() {
	return useContext(ScheduleContext);
}

function ScheduleContextProvider({ mappedMethods, children }) {
	return (
		<ScheduleContext.Provider value={mappedMethods}>
			{children}
		</ScheduleContext.Provider>
	);
}

ScheduleContextProvider.propTypes = {
	children: PropTypes.node,
	mappedMethods: PropTypes.objectOf(PropTypes.func),
};

export default ScheduleContextProvider;
