import { Box, IconButton } from "@mui/material";
import React, { useRef } from "react";
import GeneralTextField from "../../components/GeneralTextField";
import { GENERAL_SETTINGS_CREWS } from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";
import DustBinSVG from "../../assets/img/dustBin.svg";
import { useGeneralTableContext } from "../../components/GeneralTable";
import InlineErrorMessages from "../../components/InlineErrorMessages";
import { CREW_TESTID } from "./CrewsConstant";
import GeneralTooltip from "../../components/GeneralTooltip";

function CrewsTextField({ ...cellProps }) {
	const crewsTextRef = useRef();
	const {
		value,
		row: { original },
	} = cellProps;
	const { isError, errorMsg, showError } = original;
	const { updateCrewMember } = useGeneralTableContext();
	function onChange(e) {
		updateCrewMember({ id: original.id, newName: e.target.value });
	}
	return (
		<Box maxWidth="10rem">
			<GeneralTooltip
				message={
					crewsTextRef?.current?.firstChild.firstChild.offsetWidth <
					crewsTextRef?.current?.firstChild.firstChild.scrollWidth
						? value
						: "0"
				}
				disableInteractive
				maxWidth="21.25rem"
				sx={{
					visibility:
						crewsTextRef?.current?.firstChild.firstChild.offsetWidth >=
							crewsTextRef?.current?.firstChild.firstChild.scrollWidth &&
						"hidden",
				}}
			>
				<GeneralTextField
					ref={crewsTextRef}
					maxLength={255}
					placeholder="Enter Crew"
					error={isError && showError}
					value={value}
					onChange={onChange}
					isPlaceholderItalic={false}
					data-testid={CREW_TESTID.TEXTFIELD}
					sx={{
						"& .MuiOutlinedInput-input": {
							textOverflow: "ellipsis",
						},
					}}
				/>
			</GeneralTooltip>
			{isError && showError ? (
				<InlineErrorMessages message={errorMsg} marginTop="0.5rem" />
			) : null}
		</Box>
	);
}

function DeleteCell({ ...cellProps }) {
	const { id, isLocal } = cellProps.row.original;
	const { deleteCrewMember } = useGeneralTableContext();
	function handleOnDeleteClicked() {
		deleteCrewMember({ id, isLocal });
	}

	if (cellProps.rows.length === 1) {
		return null;
	}
	const disabled = cellProps?.data[1]?.isLocal && id === cellProps?.data[0].id;
	return (
		<Box className="crews-actionbody">
			<IconButton
				size="small"
				onClick={handleOnDeleteClicked}
				data-testid={CREW_TESTID.DELETE}
				disabled={disabled}
				sx={
					disabled && {
						filter:
							"invert(33%) sepia(6%) saturate(416%) hue-rotate(175deg) brightness(94%) contrast(88%)",
					}
				}
			>
				<DustBinSVG />
			</IconButton>
		</Box>
	);
}

export function useCrewsTable() {
	const columns = React.useMemo(
		() => [
			{
				Header: MESSAGE_STRINGS["GeneralSettings.Crews.column.header.crewName"],
				accessor: GENERAL_SETTINGS_CREWS.ACCESSORS.CREW_NAME,
				Cell: CrewsTextField,
			},
			{
				Header: () => (
					<div className="crews-actionheader">
						{MESSAGE_STRINGS["GeneralSettings.Crews.column.header.actions"]}
					</div>
				),
				accessor: GENERAL_SETTINGS_CREWS.ACCESSORS.IGNORE,
				Cell: DeleteCell,
			},
		],
		[]
	);

	return {
		columns,
	};
}

export default {};
