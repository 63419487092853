import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import GeneralConfig from "../GeneralConfig";

function App() {
	return (
		<Router>
			<Routes>
				<Route
					exact
					path="/config/general_config"
					element={<GeneralConfig />}
				/>
				<Route
					render={() => (
						<Route path="*" element={<Navigate to="/" replace />} />
					)}
				/>
			</Routes>
		</Router>
	);
}

export default App;
