/* eslint-disable react/prop-types */
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React from "react";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { alpha, styled } from "@mui/material";

const locales = {
	"en-US": enUS,
};
const CalendarContainer = styled("div")(({ theme }) => ({
	fontFamily: "Open Sans",
	color: theme.palette.text.primary,
	"& .rbc-day-bg + .rbc-day-bg": {
		borderLeft: `1px solid ${alpha(theme.palette.background.darkGrey, 0.6)}`,
	},
	"& .rbc-month-row + .rbc-month-row": {
		borderTop: `1px solid ${alpha(theme.palette.background.darkGrey, 0.6)}`,
	},
	"& .rbc-month-view": {
		border: `1px solid ${alpha(theme.palette.background.darkGrey, 0.6)}`,
		borderBottom: "none",
		backgroundColor: alpha(theme.palette.background.darkishBlackBlue, 0.3),
	},
	"& .rbc-off-range-bg": { background: "none" },
	"& .rbc-today": { background: "none" },
	"& .rbc-header ": {
		padding: "0.5rem 0",
		borderBottom: `1px solid ${alpha(theme.palette.background.darkGrey, 0.6)}`,
	},
	"& .rbc-header + .rbc-header": {
		borderLeft: `1px solid ${alpha(theme.palette.background.darkGrey, 0.6)}`,
	},
	"& .rbc-event, .rbc-day-slot .rbc-background-event": {},
	"& .rbc-toolbar": {
		border: `1px solid ${alpha(theme.palette.background.darkGrey, 0.6)}`,
		padding: "0 1rem",
		backgroundColor: theme.palette.background.configLighterDark,
		marginBottom: 0,
	},
	"& .rbc-date-cell.rbc-now": {
		"& > span": {
			borderRadius: "50%",
			// backgroundColor: theme.palette.background.mediumLightShadeCyanBlue,
			backgroundColor: "#62B5E5",
			padding: "0.2rem 0.3rem",
			color: theme.palette.background.configLighterDark,
		},
	},
	"& .rbc-date-cell": {
		textAlign: "center",
		padding: "0.25rem 0",
		margin: "0.125rem 0",
		fontSize: "0.75rem",
	},
	"& .rbc-off-range": {
		color: `${alpha(theme.palette.text.primary, 0.5)}`,
	},
	"& .rbc-event": {
		padding: 0,
		borderRadius: 0,
	},
	"& .rbc-month-row": {
		overflow: "visible",
		borderBottom: `1px solid ${alpha(theme.palette.background.darkGrey, 0.6)}`,
	},
	position: "relative",
}));

function CustomCalendar({
	views,
	events = [],
	startAccessor,
	endAccessor,
	titleAccessor,
	onNavigate,
	messages = {},
	...props
}) {
	const classes = {};
	const localizer = dateFnsLocalizer({
		format,
		parse,
		startOfWeek,
		getDay,
		locales,
	});
	return (
		<CalendarContainer
			className="calendar-styles"
			id="react-big-calendar"
			data-testid="custom-calendar"
		>
			<Calendar
				views={views}
				localizer={localizer}
				events={events}
				startAccessor={startAccessor}
				endAccessor={endAccessor}
				titleAccessor={titleAccessor}
				style={{ height: 600 }}
				messages={messages}
				onNavigate={onNavigate}
				className={classes.root}
				showAllEvents
				drilldownView={null}
				{...props}
			/>
		</CalendarContainer>
	);
}

export default CustomCalendar;
