import React from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import PlusIcon from "../../../assets/img/plusInsideCircle.svg";
import EllipsisIcon from "../../../assets/img/ellipsis.svg";
import { useScheduleContext } from "../../../context/scheduleContext";
import GeneralTooltip from "../../../components/GeneralTooltip";

function ActionCell({ ...cellProps }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const {
		row: {
			original: { id, isLocal, shiftName, type, parentId },
		},
		table,
	} = cellProps;
	const isOnlyRow =
		type === "shift" &&
		table?.getRowModel().rows?.filter(({ depth }) => depth === 0).length === 1;

	const { handleOnDeleteShift, handleEditClick, handleAddSubShift } =
		useScheduleContext();
	const isDeleteDisabled =
		table?.getRowModel().rows[1]?.isLocal &&
		id === table?.getRowModel().rows[0]?.id;

	const handleTooltipClose = () => {
		setIsTooltipOpen(false);
	};

	const handleTooltipOpen = () => {
		setIsTooltipOpen(true);
	};

	return (
		<Box display="flex" justifyContent="flex-end">
			{type !== "subShift" && (
				<GeneralTooltip
					message="Add Sub-Shift"
					onClose={handleTooltipClose}
					onOpen={handleTooltipOpen}
					placement="top"
					open={isTooltipOpen}
				>
					<IconButton
						sx={{ padding: 0, marginRight: "1rem" }}
						onClick={() => handleAddSubShift({ row: cellProps.row.original })}
						data-testid="add-sub-shift"
					>
						<PlusIcon />
					</IconButton>
				</GeneralTooltip>
			)}
			<IconButton
				sx={{ padding: 0 }}
				className="shifts-table-options"
				onClick={handleClick}
				data-testid="edit-action"
			>
				<EllipsisIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				sx={{
					".MuiMenu-list": {
						justifyContent: "center",
					},
				}}
			>
				<MenuItem
					onClick={() => {
						handleEditClick({ row: cellProps.row.original, type });
						handleClose();
					}}
					data-testid="shift-table-option-edit"
				>
					Edit
				</MenuItem>
				<MenuItem
					disabled={isOnlyRow || isDeleteDisabled}
					onClick={() => {
						handleOnDeleteShift({
							id,
							isLocal: !!isLocal,
							shiftName,
							type,
							parentId,
						});
						handleClose();
					}}
					data-testid="shift-table-option-delete"
				>
					Delete
				</MenuItem>
			</Menu>
		</Box>
	);
}

ActionCell.propTypes = {};

export default ActionCell;
