import { Box, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";

const CustomizedGeneralConfigBody = styled(Box)(({ theme }) => ({
	background: theme.palette.background.layoutBackgroundColor,
	width: "100%",
	display: "flex",
	paddingTop: "2.5rem",
	flexGrow: "1",
	flexDirection: "column",
}));

const CustomizedTabHeader = styled(Typography)(() => ({
	fontSize: "1rem",
	fontWeight: "700",
}));

function GeneralConfigBody({ tabTitle = "", children, ...props }) {
	return (
		<CustomizedGeneralConfigBody {...props}>
			{tabTitle && (
				<Box>
					<CustomizedTabHeader variant="h3">{tabTitle}</CustomizedTabHeader>
				</Box>
			)}
			{children}
		</CustomizedGeneralConfigBody>
	);
}

GeneralConfigBody.propTypes = {
	children: PropTypes.node,
	tabTitle: PropTypes.string,
};

export default GeneralConfigBody;
