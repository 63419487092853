import { globalstate$ } from "@smf/ui-util-app";

/**
 * Function to update RxJs status
 * @param {Boolean} status Status to update factory general sidebar status
 */
export const updateGeneralConfigStatus = (
	status = false,
	factoryGeneralAction = {}
) => {
	const state = globalstate$.getValue();
	globalstate$.next({
		...state,
		configStatus: { ...state.configStatus, factoryGeneral: status },
		factoryGeneralEditState: {
			...state.factoryGeneralEditState,
			action: {
				...state.factoryGeneralEditState.action,
				...factoryGeneralAction,
			},
		},
	});
};

export const updateFactoryGeneralEditAction = (
	factoryGeneralAction = {},
	editState = {}
) => {
	const state = globalstate$.getValue();
	globalstate$.next({
		...state,
		factoryGeneralEditState: {
			...state.factoryGeneralEditState,
			...editState,
			action: {
				...state.factoryGeneralEditState.action,
				...factoryGeneralAction,
			},
		},
	});
};

export const updateRxjsState = (data) => {
	const state = globalstate$.getValue();
	globalstate$.next({ ...state, ...data });
};

export default {};
