import PropTypes from "prop-types";
import { alpha, Dialog, styled } from "@mui/material";
import GeneralModalPaper from "../GeneralModalPaper";
import LoadingIndicator from "../LoadingIndicator";
import CommonBackdrop from "../CommonBackdrop";

const StyledDialog = styled(Dialog)(({ theme }) => ({
	borderColor: theme.palette.background.blackGrey,
	background: alpha(theme.palette.background.paper, 0.8),
}));

// eslint-disable-next-line import/prefer-default-export
export function GeneralDialog({
	open,
	fullWidth = true,
	maxWidth = "sm",
	children,
	isLoading = false,
	...other
}) {
	return (
		<StyledDialog
			data-testid="general-dialog-component"
			open={open}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			PaperComponent={GeneralModalPaper}
			{...other}
		>
			{isLoading && (
				<CommonBackdrop open>
					<LoadingIndicator />
				</CommonBackdrop>
			)}
			{children}
		</StyledDialog>
	);
}

GeneralDialog.propTypes = {
	open: PropTypes.bool,
	fullWidth: PropTypes.bool,
	maxWidth: PropTypes.string,
	children: PropTypes.node,
	isLoading: PropTypes.bool,
};
