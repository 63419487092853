import {
	Box,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Typography,
	styled,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import MESSAGE_STRINGS from "../../../constants/en-us";
import GeneralDropdown from "../../GeneralDropdown";
import PrevPageSVG from "../../../assets/img/prevPage.svg";
import NextPageSVG from "../../../assets/img/nextPage.svg";

const PaginationFooterContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	background: theme.palette.background.darkishBlackBlue,
	borderRadius: 0,
	padding: "0.25rem",
	// Typography is not working properly, fix this later
	fontSize: "0.875rem",
	fontWeight: 400,
	[theme.breakpoints.down("xs")]: {
		flexDirection: "column-reverse",
	},
	"& .tablePaginationContainer-displayingMessage": {
		marginRight: "1rem",
		[theme.breakpoints.down("xs")]: {
			paddingTop: "1rem",
			paddingBottom: "1rem",
		},
	},
	"& .tablePaginationContainer-rowsPerPage": {
		marginRight: "1rem",
		display: "flex",
		alignItems: "center",
		"& .tablePaginationContainer-rowLabelTitle": {
			marginRight: "0.625rem",
		},
	},
	"& .tablePaginationContainer-pageNav": {
		display: "flex",
		alignItems: "center",
	},
}));

const FooterIconButton = styled(IconButton)(({ theme }) => ({
	padding: "0.75rem 0.25rem",
	"&:disabled": {
		stroke: theme.palette.background.shuttleGray,
		filter:
			"invert(39%) sepia(5%) saturate(349%) hue-rotate(175deg) brightness(97%) contrast(90%)",
	},
}));

function TablePaginationFooter({
	pageIndex,
	pageSize,
	pageLength,
	rowsLength,
	pageSizeList = [10, 20, 30, 40, 50],
	nextPage,
	setPageSize,
	previousPage,
	canPreviousPage,
	canNextPage,
	leftHandMessage = null,
}) {
	function onPageChange(e) {
		setPageSize(+e.target.value);
	}
	const totalPages = Math.ceil(rowsLength / pageSize);
	return (
		<PaginationFooterContainer
			className="tablePaginationContainer"
			data-testid="tablePaginationContainer"
		>
			<Box>{leftHandMessage}</Box>
			<Box className="tablePaginationContainer-displayingMessage">
				{`${MESSAGE_STRINGS["GeneralTable.Pagination.caption.label1"]}
						${pageIndex === 0 ? "01" : pageIndex * pageSize} -
						${pageIndex * pageSize + pageLength}
						${MESSAGE_STRINGS["GeneralTable.Pagination.caption.label2"]}
						${rowsLength}
						${MESSAGE_STRINGS["GeneralTable.Pagination.caption.label3"]}`}
			</Box>
			<div className="tablePaginationContainer-rowsPerPage">
				<Typography
					variant="subtitle"
					className="tablePaginationContainer-rowLabelTitle"
				>
					{MESSAGE_STRINGS["GeneralTable.Pagination.caption.rowsLabel"]}
				</Typography>
				<FormControl>
					<GeneralDropdown
						value={pageSize}
						onChange={onPageChange}
						borderRadius={4}
					>
						{pageSizeList.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</GeneralDropdown>
				</FormControl>
			</div>
			<Box className="tablePaginationContainer-pageNav">
				<FooterIconButton
					onClick={previousPage}
					disabled={!canPreviousPage}
					data-testid="leftArrowIcon"
				>
					<img src={PrevPageSVG} alt="prev page" height={16} />
				</FooterIconButton>
				<div data-testid="pageLabel">{`Page ${
					pageIndex + 1
				} of ${totalPages}`}</div>
				<FooterIconButton
					onClick={nextPage}
					disabled={!canNextPage}
					data-testid="rightArrowIcon"
				>
					<img src={NextPageSVG} alt="next page" height={16} />
				</FooterIconButton>
			</Box>
		</PaginationFooterContainer>
	);
}

TablePaginationFooter.propTypes = {
	pageIndex: PropTypes.number,
	pageSize: PropTypes.number,
	pageLength: PropTypes.number,
	rowsLength: PropTypes.number,
	pageSizeList: PropTypes.arrayOf(PropTypes.number),
	nextPage: PropTypes.func,
	setPageSize: PropTypes.func,
	previousPage: PropTypes.func,
	canPreviousPage: PropTypes.bool,
	canNextPage: PropTypes.bool,
	leftHandMessage: PropTypes.node,
};

export default TablePaginationFooter;
