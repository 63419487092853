import { compareAsc, parse, add } from "date-fns";
import { countBy } from "lodash";
import { TIME_MASK_VALUE } from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";
import { ENDS_ON_VALUES } from "./ScheduleConstants";
import { compareTimeHHMM } from "./scheduleHelpers";

export function isValid24HrTime(time = "00:00") {
	if (!time || (time || "").length !== 5) return null;
	if (time) return time.match(/^(0\d|1\d|2[0-3]):([0-5]\d|\d)$/gm);
	return null;
}

function newProductionDayStartTimeCheck(newProductionDayStartTime) {
	const errObject = { errorText: "", isError: false };
	if (!newProductionDayStartTime.value) {
		// Time is empty
		errObject.errorText = MESSAGE_STRINGS["Schedule.Error.requiredField"];
		errObject.isError = true;
	} else if (!isValid24HrTime(newProductionDayStartTime.value)) {
		errObject.errorText = MESSAGE_STRINGS["Schedule.Error.invalidTime"];
		errObject.isError = true;
	} else {
		errObject.errorText = "";
		errObject.isError = false;
	}
	return errObject;
}

function timeCheck(
	isSameDay,
	isEndTime,
	paddedTime,
	prodStartTime,
	prodEndTime,
	parsedTime
) {
	let enteredTime = null;
	if (!isSameDay) {
		// START TIME CHECKS
		if (!isEndTime) {
			if (compareTimeHHMM(paddedTime, prodStartTime) === -1) {
				// Entered time's hour is less than production day start time's hour
				// Implies this shift starts on second day of the production day
				enteredTime = add(parsedTime, {
					hours: 24,
				});
			} else {
				// This shift starts on first day of production day
				enteredTime = parsedTime;
			}
		}
		// END TIME CHECKS
		else if (
			compareTimeHHMM(paddedTime, prodEndTime) === 1 &&
			compareTimeHHMM(paddedTime, prodStartTime) === 1
		) {
			// Entered time's hour is greater than production day end time's hour
			// Implies this shift ends on first day of the production day
			enteredTime = parsedTime;
		} else {
			// This shift ends on second day of production day
			enteredTime = add(parsedTime, {
				hours: 24,
			});
		}
	} else {
		enteredTime = parsedTime;
	}
	return enteredTime;
}

export function isTimeValid(enteredTime) {
	if (
		enteredTime === TIME_MASK_VALUE ||
		enteredTime === "" ||
		enteredTime === null
	) {
		// Time is empty
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
		};
	}
	if (!isValid24HrTime(enteredTime)) {
		// Time is invalid
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["Schedule.Error.invalidTime"],
		};
	}
	return {
		isError: false,
		errorText: "",
	};
}

export function getErrorAndErrorText(
	isDuplicate,
	time,
	shiftStartTime,
	prodTime,
	isSameDay,
	isEndTime,
	keyboardTime
) {
	// bhargavi's checks
	let enteredTime = null;
	const { prodStartTime, prodEndTime } = prodTime;

	if (!prodStartTime || !prodEndTime) {
		return {};
	}

	if (!time) {
		// Time is empty
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["Schedule.Error.requiredField"],
		};
	}
	const paddedTime = time
		? time
				.split(":")
				.map((t) => t.padStart(2, "0"))
				.join(":")
		: "00:00";

	if (!isValid24HrTime(time) || !isValid24HrTime(keyboardTime)) {
		// Time is invalid
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["Schedule.Error.invalidTime"],
		};
	}
	const parsedTime = parse(time, "HH:mm", new Date());
	const parsedProdStartTime = parse(prodStartTime, "HH:mm", new Date());
	const parsedProdEndTime = parse(prodEndTime, "HH:mm", new Date());

	const minTime = parsedProdStartTime; // Production Day Start Time
	const maxTime = add(parsedProdEndTime, {
		hours: !isSameDay ? 24 : 0,
	}); // Production Day End Time

	enteredTime = timeCheck(
		isSameDay,
		isEndTime,
		paddedTime,
		prodStartTime,
		prodEndTime,
		parsedTime
	);

	const startTime = parse(shiftStartTime, "HH:mm", new Date()); // Start Time of that shift only passing props for End time

	if (isDuplicate) {
		// Some two shits are duplicate
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["Schedule.ShiftTimeError.sameShiftTime"],
		};
	}
	if (
		compareAsc(enteredTime, minTime) !== -1 &&
		compareAsc(maxTime, enteredTime) !== -1
	) {
		// Entered time is after production day start time and before production day end time
		if (isEndTime && compareAsc(startTime, enteredTime) > 0 && isSameDay) {
			// Entered time is end time and start time is after end time, throw error
			return {
				isError: true,
				errorText: MESSAGE_STRINGS["Schedule.ShiftTimeError.startTimeError"],
			};
		}

		return { isError: false, errorText: "" };
	}

	if (!time) {
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["Schedule.ShiftEmpty.ErrorText"],
		};
	}
	// Entered time is before production day start time or after production day end time
	return {
		isError: false,
		errorText: "",
	};
}

export function isOverlapping(values, shiftId, timeString) {
	return values.some(
		(shift) =>
			// Don't run the check on the shift that is being edited
			shift.shiftId !== shiftId &&
			// Don't run the check if the shift being checked against has any errors
			// !(shift.startTime.isError || shift.endTime.isError) &&
			// Checks whether the shift being edited lies exclusively between the start time and end time of an existing shift
			compareAsc(timeString, shift.formattedStartTime) === 1 &&
			compareAsc(shift.formattedEndTime, timeString) === 1
	);
}

// bhargavis validate function
export function validateShiftOverlapping(values) {
	return values.map((item) => {
		// Checks whether start time is valid and that it does not overlap with any other existing shifts
		// If the shift being edited overlaps with some other shift, set error, else retain previous state
		const startTimeErrorAndErrorText =
			!item.startTime.isError &&
			compareAsc(item.formattedStartTime, item.formattedEndTime) === -1 &&
			isValid24HrTime(item.startTime.time) &&
			isOverlapping(values, item.shiftId, item.formattedStartTime)
				? {
						isError: true,
						errorText: MESSAGE_STRINGS["Schedule.shiftOverlap.Errortext"],
				  }
				: {
						isError: item.startTime.isError,
						errorText: item.startTime.errorText,
				  };
		// // Checks whether end time is valid and that it does not overlap with any other existing shifts
		// // If the shift being edited overlaps with some other shift, set error, else retain previous state
		const endTimeErrorAndErrorText =
			!item.endTime.isError &&
			compareAsc(item.formattedStartTime, item.formattedEndTime) === -1 &&
			isValid24HrTime(item.endTime.time) &&
			isOverlapping(values, item.shiftId, item.formattedEndTime)
				? {
						isError: true,
						errorText: MESSAGE_STRINGS["Schedule.shiftOverlap.Errortext"],
				  }
				: { isError: item.endTime.isError, errorText: item.endTime.errorText };
		return {
			...item,
			startTime: {
				...item.startTime,
				...startTimeErrorAndErrorText,
			},
			endTime: {
				...item.endTime,
				...endTimeErrorAndErrorText,
			},
		};
	});
}

export function validateShiftTimings({
	shifts = [],
	prodStartTime,
	prodEndTime,
	isSameDay,
}) {
	const counts = countBy(
		shifts.map((shift) => ({
			id: shift.id,
			count: `${shift.startTime.time} ${shift.endTime.time}`,
		})),
		"count"
	);
	const prodTime = { prodStartTime, prodEndTime };

	const updatedShifts = shifts.map((item) => ({
		...item,
		startTime: {
			...item.startTime,
			...getErrorAndErrorText(
				counts[`${item.startTime.time} ${item.endTime.time}`] > 1,
				item.startTime.time,
				"00:00", // For startTime no check in row required
				prodTime,
				isSameDay,
				false,
				item.enteredStartTime
			),
		},
		endTime: {
			...item.endTime,
			...getErrorAndErrorText(
				counts[`${item.startTime.time} ${item.endTime.time}`] > 1,
				item.endTime.time,
				item.startTime.time,
				prodTime,
				isSameDay,
				true,
				item.enteredEndTime
			),
		},
	}));

	return updatedShifts;
}

export function validateProdTimings({
	productionDayStartTime,
	productionDayEndTime,
	productionDayEndsOn,
	timezone,
}) {
	const newProductionDayStartTime = { ...productionDayStartTime };
	const newProductionDayEndTime = { ...productionDayEndTime };
	const newProductionDayEndsOn = { ...productionDayEndsOn };
	const newTimezone = { ...timezone };

	const startTime = parse(newProductionDayStartTime.value, "HH:mm", new Date());
	const endTime = parse(productionDayEndTime.value, "HH:mm", new Date());
	if (newProductionDayEndsOn.isEndsOnEmpty) {
		newProductionDayEndsOn.isError = true;
		newProductionDayEndsOn.errorText =
			MESSAGE_STRINGS["Schedule.Error.requiredField"];
	} else newProductionDayEndsOn.errorText = "";
	if (!newTimezone.value) {
		newTimezone.isError = true;
		newTimezone.errorText = MESSAGE_STRINGS["Schedule.Error.requiredField"];
	}
	const productionDayStartTimeValue = newProductionDayStartTimeCheck(
		newProductionDayStartTime
	);
	newProductionDayStartTime.errorText = productionDayStartTimeValue.errorText;
	newProductionDayStartTime.isError = productionDayStartTimeValue.isError;
	if (!newProductionDayEndTime.value) {
		// Time is empty
		newProductionDayEndTime.errorText =
			MESSAGE_STRINGS["Schedule.Error.requiredField"];
		newProductionDayEndTime.isError = true;
	} else if (!isValid24HrTime(newProductionDayEndTime.value)) {
		newProductionDayEndTime.errorText =
			MESSAGE_STRINGS["Schedule.Error.invalidTime"];
		newProductionDayEndTime.isError = true;
	} else if (
		newProductionDayEndsOn.value === "" ||
		newProductionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY ||
		newProductionDayEndsOn.value === null
	) {
		if (compareAsc(startTime, endTime) !== -1) {
			// Invalid: End Time must be greater than Start Time
			newProductionDayEndTime.errorText =
				MESSAGE_STRINGS["Schedule.ProdTimeError.startTimeError"];
			newProductionDayEndTime.isError = true;
		} else {
			// Valid: Start Time is less than End Time
			newProductionDayEndTime.errorText = "";
			newProductionDayEndTime.isError = false;
		}
	} else if (
		!(
			newProductionDayEndsOn.value === "" ||
			newProductionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY ||
			newProductionDayEndsOn.value === null
		)
	) {
		// if (compareAsc(startTime, endTime) === -1) {
		// 	// Invalid: Start Time must be greater than End Time
		// 	newProductionDayEndTime.errorText =
		// 		MESSAGE_STRINGS[
		// 			"Schedule.productionDay.lessThanProductionDayStartTime"
		// 		];
		// 	newProductionDayEndTime.isError = true;
		// } else {
		// Valid: Start Time is more than End Time
		newProductionDayEndTime.errorText = "";
		newProductionDayEndTime.isError = false;
		// }
	}

	return {
		newProductionDayStartTime,
		newProductionDayEndTime,
		newProductionDayEndsOn,
		newTimezone,
	};
}

export function checkShiftBounds({ formattedStartTime, formattedEndTime }) {
	if (compareAsc(formattedStartTime, formattedEndTime) < 0) {
		return {
			isError: false,
			errorText: "",
		};
	}

	return {
		isError: true,
		errorText: MESSAGE_STRINGS["Schedule.ShiftTimeError.boundError"],
	};
}

export default {};
