import { Paper, styled } from "@mui/material";
import React from "react";

const StyledPaper = styled(Paper)(({ theme }) => ({
	"&.MuiPaper-root": {
		borderColor: theme.palette.background.blackGrey,
		backgroundColor: theme.palette.background.blackGrey,
		padding: "1rem", // According to figma
		color: theme.palette.text.primary,
		outline: "none",
		borderRadius: "0.25rem",
	},
}));

function GeneralModalPaper(props) {
	return <StyledPaper {...props} />;
}

export default GeneralModalPaper;
