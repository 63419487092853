import React from "react";
import ReactDOM from "react-dom/client";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const lifecycles = singleSpaReact({
	React,
	ReactDOM,
	rootComponent: Root,
	// eslint-disable-next-line no-unused-vars
	errorBoundary: (_err, _info, _props) => {
		// Customize the root error boundary for your microfrontend here.
		return null;
	},
	renderType: "createRoot", // ReactDoM.render not supported in React 18
});

export const { bootstrap, mount, unmount } = lifecycles;
