/* eslint-disable no-param-reassign */
import {
	Box,
	Typography,
	IconButton,
	MenuItem,
	Grid,
	styled,
	alpha,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { maxBy } from "lodash";

import { globalstate$ } from "@smf/ui-util-app";
import GeneralConfigBody from "../layouts/GeneralConfigBody";
import GeneralConfigFooter from "../layouts/GeneralConfigFooter";
import {
	deleteRotations,
	getRotations,
	updateRotations,
} from "../../utils/apiHelper";
import CommonBackdrop from "../../components/CommonBackdrop";
import MESSAGE_STRINGS from "../../constants/en-us";
import {
	TOAST_REDUCER_CONSTANTS,
	GENERAL_CONFIGURATION_STEPS,
	QUERY_CONSTANTS,
	STEPPER_STATUSES,
	NAVIAGTION_TYPE,
	APP_URL,
	BLOCK_LEVEL_INDICATOR_TYPES,
} from "../../constants";
import PlusInCircleSVG from "../../assets/img/plusInsideCircle.svg";
import DustBinSVG from "../../assets/img/dustBin.svg";
import DangerIconSvg from "../../assets/img/dangerIcon.svg";
import GeneralTable from "../../components/GeneralTable/index";
import GeneralDropdown from "../../components/GeneralDropdown";
import { useToastContext } from "../../context/toastContext";
import {
	pushModal,
	closeRecentModal,
	useGeneralModalDispatchContext,
} from "../../context/generalModalContext";
import { GeneralDialog } from "../../components/GeneralDialog";
import NotificationDialog from "../../components/GeneralDialog/NotificationDialog";
import BlockLevelIndicator from "../../components/BlockLevelIndicator";
import GeneralTooltip from "../../components/GeneralTooltip";
import ROTATION_ID from "./RotationIds";
import {
	updateStepStatus,
	updateStepStatusCompleted,
	updateStepStatusInProgress,
	useGeneralConfigContext,
} from "../../context/generalConfigContext";
import {
	sameRotationCheck,
	isEmptyCheck,
	putFormatter,
	getRotationMessage,
	checkIfUpdatedStateExists,
	getCrewDropdownValue,
	updateErrorObject,
} from "./utils";
import useRxjsState from "../../hooks/useRxjsState";
import { updateFactoryGeneralEditAction } from "../../utils/rxjs";
import NavigationModal from "../layouts/NavigationModal/NavigationModal";
import {
	updateRotationModalState,
	useInterDependecyContext,
} from "../../context/interDependecyModalContext";
import LoadingIndicator from "../../components/LoadingIndicator";
import InlineErrorMessages from "../../components/InlineErrorMessages";

export function LastCellHeader({
	rotationName,
	onDeleteClick,
	disabled,
	show,
}) {
	let content;
	if (show) {
		if (disabled) {
			content = {
				filter:
					"invert(33%) sepia(6%) saturate(416%) hue-rotate(175deg) brightness(94%) contrast(88%)",
			};
		}
	} else {
		content = {
			visibility: "hidden",
		};
	}
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between">
			<IconButton
				onClick={() => onDeleteClick(rotationName)}
				data-testid={ROTATION_ID.ROTATION_DELETE}
				disabled={disabled}
				sx={content}
			>
				<DustBinSVG />
			</IconButton>
		</Box>
	);
}

LastCellHeader.propTypes = {
	onDeleteClick: PropTypes.func,
	rotationName: PropTypes.string,
	disabled: PropTypes.bool,
	show: PropTypes.bool,
};

const StyledBlockLevelIndicator = styled(Box)({
	".page-level-indicator": {
		minWidth: "50rem",
		width: "fit-content",
	},
});

const StyledRotationsContent = styled("div")(({ theme }) => ({
	".basicGeneralTable_bodyCell": {
		border: `0.0625rem solid ${alpha(theme.palette.background.darkGrey, 0.3)}`,
		borderLeft: "none",
	},
	".MuiTableCell-head": {
		border: `0.0625rem solid ${alpha(theme.palette.background.darkGrey, 0.3)}`,
		borderLeft: "none",
	},
	".MuiTableCell-head:nth-of-type(8)": {
		borderRight: "none",
	},
}));

function Rotations({
	onNextClickAPI,
	onPreviousClickAPI,
	navigateTo,
	isInterDepedencyState,
	refetchGeneralStatus,
}) {
	const [rotations, setRotations] = useState([]);
	const [submitError, setSubmitError] = useState({
		sameRotations: new Set(),
	});
	const [isSaveEnabled, setSaveEnabled] = useState(false);

	const [isErrorInfoVisible, setIsErrorInfoVisible] = useState({
		visible: false,
		api: false,
	});
	const { toastDispatch } = useToastContext();
	const modalDispatch = useGeneralModalDispatchContext();
	const { rxjsState } = useRxjsState();
	const queryClient = useQueryClient();
	const { interDependecyDispatch } = useInterDependecyContext();
	const {
		factoryGeneralEditState: { action: factoryGeneralAction = {} } = {},
	} = rxjsState;
	const {
		generalConfigState: { isMaintenanceMode, steps, stepsFromApi },
		generalConfigDispatch,
	} = useGeneralConfigContext();

	const days = [
		MESSAGE_STRINGS["ROTATIONS.WeekDays.header.Monday"],
		MESSAGE_STRINGS["ROTATIONS.WeekDays.header.Tuesday"],
		MESSAGE_STRINGS["ROTATIONS.WeekDays.header.Wednesday"],
		MESSAGE_STRINGS["ROTATIONS.WeekDays.header.Thursday"],
		MESSAGE_STRINGS["ROTATIONS.WeekDays.header.Friday"],
		MESSAGE_STRINGS["ROTATIONS.WeekDays.header.Saturday"],
		MESSAGE_STRINGS["ROTATIONS.WeekDays.header.Sunday"],
	];
	const entityId = globalstate$?.value?.plantId;

	function handleNextClick(action) {
		return action === MESSAGE_STRINGS["FGC.save"] ? null : onNextClickAPI();
	}
	function handlePreviousClick() {
		onPreviousClickAPI();
	}

	function handleErrorChecks() {
		const isSameRotation = sameRotationCheck(rotations);
		const isEmpty = isEmptyCheck(rotations);
		if (isEmpty.error) {
			return true;
		}
		if (isEmpty.sameAssignedError) {
			return true;
		}

		return !!isSameRotation.error;
	}

	useEffect(() => {
		if (submitError.sameRotations.size > 1) {
			const isSameRotation = sameRotationCheck(rotations);
			if (isSameRotation.error) {
				setSubmitError({
					...submitError,
					sameRotations: isSameRotation.sameRotations,
				});
			} else {
				setSubmitError({
					...submitError,
					sameRotations: new Set(),
					topError: "",
				});
			}
		}
		if (isSaveEnabled) {
			if (!handleErrorChecks()) setSaveEnabled(false);
		}
		if (isErrorInfoVisible.api) {
			const isEmpty = isEmptyCheck(rotations);
			setIsErrorInfoVisible({ ...isErrorInfoVisible, api: false });
			if (isEmpty.error) setRotations([...isEmpty.newRotation]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rotations]);

	const {
		isLoading,
		isFetching,
		data: rotationsData,
		error: initialFetchError,
	} = useQuery(
		[QUERY_CONSTANTS.GET_ROTATIONS, entityId],
		() => getRotations({ factoryID: entityId }),
		{
			onSuccess: (data) => {
				let arr = [];
				arr = data.message.rotations?.map((rotation) => {
					rotation.isUpdated = new Set();
					rotation.shifts.map((shift) => {
						shift.error = {
							production_Day_1: new Set(),
							production_Day_2: new Set(),
							production_Day_3: new Set(),
							production_Day_4: new Set(),
							production_Day_5: new Set(),
							production_Day_6: new Set(),
							production_Day_7: new Set(),
						};
						return shift;
					});
					return rotation;
				});
				setRotations(arr);
				if (
					steps[GENERAL_CONFIGURATION_STEPS.ROTATION - 1].stepStatus ===
						STEPPER_STATUSES.IN_PROGRESS &&
					isMaintenanceMode
				) {
					setIsErrorInfoVisible({ visible: true, api: true });
				}
			},
			onError: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message: MESSAGE_STRINGS["ROTATIONS.addRotation.error"],
					},
				});
			},
			refetchOnWindowFocus: false,
		}
	);

	const { mutate: updateRotationServer, isLoading: updateLoading } =
		useMutation(QUERY_CONSTANTS.UPDATE_ROTATION, updateRotations, {
			onSuccess: (res, args) => {
				queryClient.invalidateQueries(QUERY_CONSTANTS.GET_ROTATIONS);
				if (res && res.message) {
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
						payload: {
							message: MESSAGE_STRINGS["Toast.message.SUCCESS"],
						},
					});
				}
				updateStepStatusCompleted(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.ROTATION
				);
				setIsErrorInfoVisible({ visible: false, api: false });
				handleNextClick(args.action);
				if (isMaintenanceMode) {
					refetchGeneralStatus();
				}
			},
			onError: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message: MESSAGE_STRINGS["ROTATIONS.updateRotationsFailed"],
					},
				});
			},
		});

	const {
		mutate: deleteRotationServer,
		isLoading: deleteLoading,
		data: deleteApiData,
	} = useMutation(QUERY_CONSTANTS.DELETE_ROTATION, deleteRotations, {
		onSuccess: (_data, passedArgs) => {
			if (isMaintenanceMode && passedArgs.isAssigned) {
				updateRotationModalState(interDependecyDispatch, passedArgs.rotationID);
			}
			let rotName = "";
			// eslint-disable-next-line array-callback-return, consistent-return
			const updatedRotations = rotations.filter((rotation) => {
				if (rotation.rotationID === passedArgs.rotationID)
					rotName = rotation.rotationName;
				else return rotation;
			});
			if (!checkIfUpdatedStateExists(updatedRotations)) {
				updateStepStatus(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.ROTATION,
					stepsFromApi[GENERAL_CONFIGURATION_STEPS.ROTATION - 1]?.stepStatus
				);
			}
			setRotations(updatedRotations);
			refetchGeneralStatus();
			closeRecentModal(modalDispatch);
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
				payload: {
					message: `${rotName} ${MESSAGE_STRINGS["ROTATIONS.deleteRotation.success"]}`,
				},
			});
		},
		onError: () => {
			closeRecentModal(modalDispatch);
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: MESSAGE_STRINGS["ROTATIONS.deleteRotation.error"],
				},
			});
		},
	});

	function onDeleteClick(rotationName) {
		const rotationToBeDeleted = rotations.find(
			(rot) => rot.rotationName === rotationName
		);
		if (!rotationToBeDeleted.isLocal) {
			pushModal(
				modalDispatch,
				<GeneralDialog open fullWidth={false}>
					<NotificationDialog
						type="confirm"
						handleSave={() => {
							deleteRotationServer({
								rotationID: rotationToBeDeleted.rotationID,
								factoryID: entityId,
								isAssigned:
									rotationToBeDeleted &&
									rotationToBeDeleted.rotationStatus === "ASSIGNED",
							});
						}}
						handleClose={() => {
							closeRecentModal(modalDispatch);
						}}
						lightText={false}
						cancelText={
							MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText"]
						}
						confirmText={
							MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText"]
						}
						customTitle={
							MESSAGE_STRINGS[
								"GENERAL_CONFIGURATIONS.ROTATIONS.NOTIFICATION.customTitle"
							]
						}
						customText={`${MESSAGE_STRINGS["GENERAL_CONFIGURATIONS.Rotation.NOTIFICATION.customText"]} ${rotationName}?`}
						noteText=""
					/>
				</GeneralDialog>
			);
		} else {
			const updatedRotations = rotations.filter(
				(rotation) => rotation.rotationName !== rotationName
			);
			if (!checkIfUpdatedStateExists(updatedRotations)) {
				updateStepStatus(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.ROTATION,
					stepsFromApi[GENERAL_CONFIGURATION_STEPS.ROTATION - 1]?.stepStatus
				);
			}
			setRotations(updatedRotations);
		}
	}

	function getShiftName(shift, element) {
		if (element === "tooltip") {
			return shift.shiftName.length >= 10 ? shift.shiftName : "hidden";
		}
		return shift.shiftName.length < 10
			? shift.shiftName
			: `${shift.shiftName.substring(0, 8)}...`;
	}

	function createColumn(rotationName, isLocal) {
		const cols = [
			{
				Header: (
					<Box display="flex" justifyContent="center">
						<Box
							display="flex"
							alignItems="center"
							justifyContent="flex-start"
							width="7rem"
							data-testid={ROTATION_ID.ROTATION_HEADER}
						>
							{submitError?.sameRotations.has(rotationName) && (
								<DangerIconSvg width={16} height={16} />
							)}
							{submitError?.sameRotations.has(rotationName) ? (
								<Typography
									variant="h5"
									fontSize="1rem"
									ml=".5rem"
									align="center"
								>{`${rotationName}`}</Typography>
							) : (
								<Typography
									variant="h5"
									fontSize="1rem"
									align="center"
								>{`${rotationName}`}</Typography>
							)}
						</Box>
					</Box>
				),
				accessor: "shiftName",
			},
		];

		for (let i = 1; i <= 7; i += 1) {
			const productionDay = {
				id: i,
				Header: (
					<Box>
						<Box data-testid={ROTATION_ID.PRODUCTION_DAY_ID}>
							<Typography
								variant="h6"
								align="center"
								fontSize="1rem"
								fontWeight="lighter"
							>
								{
									MESSAGE_STRINGS[
										`ROTATIONS.ProductionDays.header.productionDay${i}`
									]
								}
							</Typography>
						</Box>
						<Box data-testid={ROTATION_ID.PRODUCTION_DAY_WEEKDAY}>
							<Typography fontSize=".7rem" align="center" fontWeight="lighter">
								{rotationsData.message.productionDay.isNextDay
									? `(${days[(i - 1) % days.length]} - ${
											days[i % days.length]
									  })`
									: `(${days[(i - 1) % days.length]})`}
							</Typography>
						</Box>
					</Box>
				),
				accessor: `value${i}`,
			};
			cols.push(productionDay);
		}
		if (rotations.length > 1) {
			const isDisabled =
				(rotations[1]?.isLocal && rotations[0].rotationName === rotationName) ||
				(isErrorInfoVisible.visible && !isLocal);
			cols.push({
				Header: () => null,
				enableRowSpan: true,
				rowSpan: "20",
				accessor: "delete",
				Cell: () => (
					<LastCellHeader
						rotationName={rotationName}
						onDeleteClick={onDeleteClick}
						disabled={isDisabled}
						show
					/>
				),
				width: "3.37%",
			});
		} else {
			cols.push({
				Header: () => null,
				enableRowSpan: true,
				rowSpan: "20",
				accessor: "delete",
				Cell: () => (
					<LastCellHeader
						rotationName={rotationName}
						onDeleteClick={onDeleteClick}
						disabled
					/>
				),
				width: "3.37%",
			});
		}
		return cols;
	}

	function onCrewDropdownChange(event, shift, rotation, index) {
		updateStepStatusInProgress(
			generalConfigDispatch,
			GENERAL_CONFIGURATION_STEPS.ROTATION
		);

		shift.error[`production_Day_${index}`].delete(0);

		const updatedShift = {
			...shift,
			[`production_Day_${index}`]: event.target.value,
		};

		if (rotation.shifts.length === 1) {
			updatedShift.error[`production_Day_${index}`] = new Set();
		} else {
			updateErrorObject(
				rotation,
				updatedShift,
				`production_Day_${index}`,
				shift
			);
		}

		const updatedShifts = rotation.shifts.map((filterShift) => {
			if (filterShift.shiftName === shift.shiftName) {
				filterShift = updatedShift;
			}
			return filterShift;
		});

		const updatedRotation = {
			...rotation,
			shifts: [...updatedShifts],
		};

		updatedRotation.isUpdated.add(shift.shiftName);

		const updatedrotations = rotations.map((filterRotation) => {
			if (filterRotation.rotationName === updatedRotation.rotationName) {
				// eslint-disable-next-line no-param-reassign
				filterRotation = updatedRotation;
			}
			return filterRotation;
		});

		setRotations(updatedrotations);
	}

	function createRow(shift, rotation) {
		const row = {
			shiftName: (
				<Box display="flex" justifyContent="center">
					<Box data-testid={ROTATION_ID.WEEKNAME} width="7rem">
						<GeneralTooltip
							message={getShiftName(shift, "tooltip")}
							sx={{
								visibility: getShiftName(shift, "tooltip"),
							}}
						>
							<Typography
								variant="h6"
								fontSize="1rem"
								fontWeight="lighter"
								align="left"
								noWrap
								sx={{ cursor: "default" }}
							>
								{getShiftName(shift)}
							</Typography>
							<Typography
								fontSize=".7rem"
								fontWeight="lighter"
								align="left"
							>{`(${shift.startTime} - ${shift.endTime})`}</Typography>
						</GeneralTooltip>
					</Box>
				</Box>
			),
		};

		for (let i = 1; i <= 7; i += 1) {
			row[`value${i}`] = (
				<Box display="flex" justifyContent="center">
					<GeneralTooltip
						disableInteractive
						data-testid={ROTATION_ID.CREW_ERROR}
						message={
							(shift.error[`production_Day_${i}`].size > 0 && (
								<InlineErrorMessages
									message={
										shift.error[`production_Day_${i}`].has(0)
											? MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"]
											: MESSAGE_STRINGS["ROTATIONS.sameCrewError"]
									}
									width="fit-content"
									data-testid={ROTATION_ID.CREW_ERROR_CONTENT}
								/>
							)) ||
							(shift[`production_Day_${i}`]?.length > 8 &&
								shift[`production_Day_${i}`])
						}
						width="max-content"
						sx={{ zIndex: 10 }}
					>
						<GeneralDropdown
							value={getCrewDropdownValue(shift, `production_Day_${i}`)}
							sx={{ width: "9rem" }}
							onChange={(e) => onCrewDropdownChange(e, shift, rotation, i)}
							error={shift.error[`production_Day_${i}`].size > 0}
							data-testid={ROTATION_ID.CREW_DROPDOWN}
						>
							{rotationsData.message.crews.map((crew, index) => {
								let crewNameSub = "";
								if (crew.crewName.length > 11)
									crewNameSub = `${crew.crewName.substring(0, 11)}...`;
								else crewNameSub = crew.crewName;
								return (
									<MenuItem
										key={`${index + 1}-MN`}
										value={crew.crewName}
										data-testid={ROTATION_ID.CREW_ITEMS}
										title={crew.crewName.length > 11 && crew.crewName}
									>
										{crewNameSub}
									</MenuItem>
								);
							})}
						</GeneralDropdown>
					</GeneralTooltip>
				</Box>
			);
		}
		return row;
	}

	function createTable(rotation, index) {
		const columns = createColumn(rotation.rotationName, rotation.isLocal);
		const rows = rotation.shifts.map((shift) => {
			return createRow(shift, rotation);
		});
		return <GeneralTable columns={columns} data={rows} key={index} />;
	}

	function addRotation() {
		let rotationName;
		const LastClientRotation = Number(
			rotations[rotations.length - 1].rotationName.split(" ")[1]
		);
		if (deleteApiData) {
			const serverRotations = deleteApiData?.responseBody.rotations;
			const check = maxBy(serverRotations, (serverRotation) => {
				return Number(serverRotation.rotationName.split(" ")[1]);
			});
			const LastServerRotation = Number(check.rotationName.split(" ")[1]);
			if (LastServerRotation > LastClientRotation)
				rotationName = `Rotation ${LastServerRotation + 1}`;
			else rotationName = `Rotation ${LastClientRotation + 1}`;
		} else {
			rotationName = `Rotation ${LastClientRotation + 1}`;
		}

		const shifts = rotations[0]?.shifts.reduce((acc, curr) => {
			const newShift = {
				...curr,
				production_Day_1: "",
				production_Day_2: "",
				production_Day_3: "",
				production_Day_4: "",
				production_Day_5: "",
				production_Day_6: "",
				production_Day_7: "",
				error: {
					production_Day_1: new Set(),
					production_Day_2: new Set(),
					production_Day_3: new Set(),
					production_Day_4: new Set(),
					production_Day_5: new Set(),
					production_Day_6: new Set(),
					production_Day_7: new Set(),
				},
			};

			return [...acc, newShift];
		}, []);

		const newRotation = {
			rotationName,
			isLocal: true,
			shifts,
			isUpdated: new Set(),
			isNewelyAdded: true,
		};

		setRotations([...rotations, newRotation]);

		toastDispatch({
			type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
			payload: {
				message: MESSAGE_STRINGS["ROTATIONS.addRotation.success"],
			},
		});
		updateStepStatusInProgress(
			generalConfigDispatch,
			GENERAL_CONFIGURATION_STEPS.ROTATION
		);
	}

	function handleNavigation(location = "") {
		if (factoryGeneralAction?.type === NAVIAGTION_TYPE.STEP) {
			navigateTo(location, factoryGeneralAction?.type);
			return;
		}
		if (factoryGeneralAction.location) {
			navigateTo(location);
		}
	}

	const handleNext = async (actionType = null, isEditModal = false) => {
		const isSameRotation = sameRotationCheck(rotations);
		const isEmpty = isEmptyCheck(rotations);
		if (isEmpty.error) {
			setRotations([...isEmpty.newRotation]);
			setSaveEnabled(true);
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"],
				},
			});
		} else if (isEmpty.sameAssignedError) {
			setSaveEnabled(true);
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"],
				},
			});
		} else if (isSameRotation.error) {
			setSaveEnabled(true);
			setSubmitError({
				...submitError,
				topError: MESSAGE_STRINGS["ROTATIONS.sameRotationError"],
				sameRotations: isSameRotation.sameRotations,
			});
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: MESSAGE_STRINGS["ERROR_MESSAGES.fixAllErrorsBeforeProceed"],
				},
			});
		} else {
			setSubmitError({
				...submitError,
				topError: "",
				sameRotations: new Set(),
			});
			const dataToBeSent = putFormatter(rotations, rotationsData);
			dataToBeSent.action = actionType;
			if (dataToBeSent.rotationsToBeUpdated.length > 0) {
				await updateRotationServer(dataToBeSent);
				if (isEditModal) handleNavigation(factoryGeneralAction?.location);
			} else {
				handleNextClick(actionType);
				if (isEditModal) handleNavigation(factoryGeneralAction?.location);
			}
		}
	};

	function handleSaveClick() {
		handleNext(MESSAGE_STRINGS["FGC.save"]);
	}

	const isSameLocation =
		factoryGeneralAction.location === APP_URL.CONFIG ||
		factoryGeneralAction.location === APP_URL.GENERAL_CONFIG;

	useEffect(() => {
		if (
			steps[GENERAL_CONFIGURATION_STEPS.ROTATION - 1].stepStatus ===
				STEPPER_STATUSES.IN_PROGRESS &&
			checkIfUpdatedStateExists(rotations) &&
			isMaintenanceMode === true
		) {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: true });
		} else {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: false });
		}
		return () => {
			updateFactoryGeneralEditAction(
				{ isUserNavigating: false, location: "", type: null },
				{ isUnsavedChange: false }
			);
		};
	}, [steps, isMaintenanceMode, rotations]);

	return (
		<>
			<CommonBackdrop
				open={isLoading || isFetching || deleteLoading || updateLoading}
			>
				<LoadingIndicator />
			</CommonBackdrop>

			<>
				<GeneralConfigBody
					sx={{
						paddingTop:
							isMaintenanceMode && isErrorInfoVisible.visible ? 0 : null,
					}}
				>
					<Grid item>
						{isMaintenanceMode && isErrorInfoVisible.visible && (
							<BlockLevelIndicator
								type={BLOCK_LEVEL_INDICATOR_TYPES.ERROR}
								width="fit-content"
								sx={{ marginTop: "1rem", marginBottom: "1rem" }}
								data-testid={ROTATION_ID.CREW_INFO_ERROR}
							>
								{MESSAGE_STRINGS["ROTATION.errorInfo.crewAssignment"]}
							</BlockLevelIndicator>
						)}
						<Box display="flex" alignItems="center">
							<Box data-testid={ROTATION_ID.ROTATION_HEADER}>
								<Typography variant="h6">Rotations</Typography>
							</Box>
							<IconButton
								onClick={() => addRotation()}
								data-testid={ROTATION_ID.ROTATION_ADD}
								disabled={rotations === undefined}
							>
								<PlusInCircleSVG />
							</IconButton>
						</Box>
					</Grid>
					{!isLoading && !initialFetchError && rotations ? (
						<Grid item container>
							<Box mt="1rem" data-testid={ROTATION_ID.ROTATION_TIME_BOX}>
								<Typography variant="subtitle" color="#97999B" mr=".5rem">
									{MESSAGE_STRINGS["ROTATIONS.ProductionDay.caption.startTime"]}
								</Typography>
								<Typography variant="subtitle" mr="1rem">
									{`${rotationsData.message.productionDay.startTime} ${rotationsData.message.productionDay.timeZone}`}
								</Typography>
								<Typography variant="subtitle" color="#97999B" mr=".5rem">
									{MESSAGE_STRINGS["ROTATIONS.ProductionDay.caption.endTime"]}
								</Typography>
								<Typography variant="subtitle" mr=".5rem">
									{`${rotationsData.message.productionDay.endTime} ${rotationsData.message.productionDay.timeZone}`}
								</Typography>
								<Typography variant="subtitle">
									(
									{getRotationMessage(
										rotationsData.message.productionDay.isNextDay
									)}
									)
								</Typography>
								{submitError.topError && (
									<Box mt="1rem" data-testid={ROTATION_ID.ROTATION_TOP_ERROR}>
										<StyledBlockLevelIndicator>
											<BlockLevelIndicator>
												{submitError.topError}
											</BlockLevelIndicator>
										</StyledBlockLevelIndicator>
									</Box>
								)}
							</Box>
							<Box
								display="flex"
								sx={{ flexDirection: "column", width: "100%" }}
							>
								{rotations.map((rotation, index) => {
									return (
										<StyledRotationsContent key={`${index + 1}GT`}>
											<Box
												mt="2rem"
												paddingRight="0.25rem"
												overflowX="scroll"
												data-testid={ROTATION_ID.ROTATION_TABLE}
											>
												{createTable(rotation, index)}
											</Box>
										</StyledRotationsContent>
									);
								})}
							</Box>
						</Grid>
					) : (
						!isLoading &&
						!isFetching &&
						rotations === undefined && (
							<Box mt="2rem">
								<BlockLevelIndicator>
									{MESSAGE_STRINGS["ROTATIONS.noRotations.message"]}
								</BlockLevelIndicator>
							</Box>
						)
					)}
				</GeneralConfigBody>

				<GeneralConfigFooter
					onNextClick={() => handleNext()}
					onPreviousClick={handlePreviousClick}
					isSaveButtonEnabled={isSaveEnabled}
					showSave={isMaintenanceMode}
					onClickSave={handleSaveClick}
					isSaveEnabled={isSaveEnabled}
				/>
			</>
			<NavigationModal
				open={
					Boolean(factoryGeneralAction?.isUserNavigating) &&
					!isSameLocation &&
					!isInterDepedencyState
				}
				onSave={async () => {
					updateFactoryGeneralEditAction({
						isUserNavigating: false,
						type: null,
					});
					handleNext(MESSAGE_STRINGS["FGC.save"], true);
				}}
				onClose={() => handleNavigation(factoryGeneralAction?.location)}
			/>
		</>
	);
}
Rotations.propTypes = {
	onNextClickAPI: PropTypes.func,
	onPreviousClickAPI: PropTypes.func,
	navigateTo: PropTypes.func,
	isInterDepedencyState: PropTypes.bool,
	refetchGeneralStatus: PropTypes.func,
};

export default Rotations;
