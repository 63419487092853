import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { PropTypes } from "prop-types";
import {
	CALENDAR_TEST_IDS,
	DAILY_WEEKLY_ROTATION_CONSTANTS,
} from "../../../constants";
import MESSAGE_STRINGS from "../../../constants/en-us";
import InfoIcon from "../../../assets/img/greyInfo.svg";
import GeneralTooltip from "../../GeneralTooltip";

const radioStyles = {
	color: (theme) => theme.palette.text.primary,
	"&.Mui-checked": {
		color: (theme) => theme.palette.text.primary,
	},
};
function ViewSwitcher({ currentViewName, onChange }) {
	return (
		<RadioGroup
			aria-label="Views"
			style={{ flexDirection: "row" }}
			name="views"
			value={currentViewName}
			onChange={onChange}
		>
			<FormControlLabel
				value={DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.WEEK}
				control={
					<Radio
						sx={{ ...radioStyles }}
						data-testid={CALENDAR_TEST_IDS.WEEKLY_RADIO_BUTTON}
					/>
				}
				label={
					MESSAGE_STRINGS["WeeklyAndDailyRotation.RotationsCalendar.weekly"]
				}
			/>
			<FormControlLabel
				value={DAILY_WEEKLY_ROTATION_CONSTANTS.VIEWS.DAY}
				control={
					<Radio
						sx={{ ...radioStyles }}
						data-testid={CALENDAR_TEST_IDS.DAILY_RADIO_BUTTON}
					/>
				}
				label={
					MESSAGE_STRINGS["WeeklyAndDailyRotation.RotationsCalendar.daily"]
				}
			/>
			<Box
				display="flex"
				alignItems="center"
				sx={{ "& .info-tooltip": { marginTop: "0.4rem" } }}
			>
				<GeneralTooltip
					data-testid={CALENDAR_TEST_IDS.VIEWS_TOOLTIP_ICON}
					maxWidth="45.4375rem"
					arrow
					message={
						<Box
							color="text.lightYellowishGray"
							data-testid={CALENDAR_TEST_IDS.VIEWS_TOOLTIP_CONTENT}
						>
							<Box marginBottom="0.5rem">
								<Typography variant="h5">
									{MESSAGE_STRINGS["WeeklyAndDailyRotation.screenName"]}
								</Typography>
							</Box>
							<Typography variant="subtitle2" sx={{ textTransform: "none" }}>
								{MESSAGE_STRINGS["WeeklyAndDailyRotations.tooltip.message"]}
							</Typography>
						</Box>
					}
				>
					<InfoIcon height={16} width={16} className="info-tooltip" />
				</GeneralTooltip>
			</Box>
		</RadioGroup>
	);
}

ViewSwitcher.propTypes = {
	currentViewName: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
export default ViewSwitcher;
