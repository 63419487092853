export const STEPPER_STATUSES = {
	NOT_STARTED: "NOT_STARTED",
	IN_PROGRESS: "IN_PROGRESS",
	COMPLETED: "COMPLETED",
};
export const BUTTON_TYPE = {
	PRIMARY: "primary",
	SECONDARY: "secondary",
};
export const BUTTON_SIZE = {
	STANDARD: "standard",
	SMALL: "small",
};
export const GENERAL_CONFIGURATION_STEPS = {
	EQUIPMENT_INTELLIGENCE: 1,
	SCHEDULE: 2,
	CREWS: 3,
	ROTATION: 4,
	WEEKLY_ROTATION: 5,
	PLANT_FINANCIAL_CALCULATIONS: 6,
	CONFIG_DONE: 7,
};
export const DROPDOWN_CONSTANTS = { DEFAULT_VALUE: "SELECT" };

export const QUERY_CONSTANTS = {
	EQUIPMENT_INTELLIGENCE_EXPORT_DATA: "EQUIPMENT_INTELLIGENCE_EXPORT_DATA",
	EQUIPMENT_INTELLIGENCE_META_DATA: "getEquipmentIntelligenceMetaData",
	GET_FINANCIALS: "getFinancials",
	SUBMIT_FINANCIALS: "Submit Financials",
	GET_STEP_STATUS: "getStepStatus",
	GET_ROTATIONS: "getRotations",
	DELETE_ROTATION: "deleteRotation",
	UPDATE_ROTATION: "updateRotation",
	EXPORT_WEEKLY_ROTATIONS: "exportWeeklyRotations",
	FETCH_WEEKLY_ROTATIONS: "fetchWeeklyRotations",
	EXPORT_DAILY_ROTATIONS: "exportDailyRotations",
	FETCH_DAILY_ROTATIONS: "fetchDailyRotations",
	FETCH_SCHEDULE: "fetchSchedule",
	FETCH_CREWS: "fetchCrews",
	FETCH_PLANT_FINC: "fetchPlantFinc",
	GET_SILENT_STEP_STATUS: "getSilentStepStatus",
	GET_CONFIG_STATUSES: "getConfigStatuses",
};

export const FILE_ACCEPT_TYPES = {
	CSV: ".csv, text/csv",
	IMAGE_PNG_JPEG: ".jpeg,.png,.jpg",
	XLSX: ".xlsx",
};
export const TOAST_TYPE = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
	WARNING: "WARNING",
	INFO: "INFO",
};

export const TOAST_REDUCER_CONSTANTS = {
	SHOW_SUCCESS_TOAST: "SHOW_SUCCESS_TOAST",
	SHOW_ERROR_TOAST: "SHOW_ERROR_TOAST",
	SHOW_WARNING_TOAST: "SHOW_WARNING_TOAST",
	SHOW_INFO_TOAST: "SHOW_INFO_TOAST",
	HIDE_TOAST: "HIDE_TOAST",
};

export const GENERAL_SETTINGS_CREWS = {
	ACCESSORS: {
		CREW_NAME: "crewName",
		IGNORE: "ignore",
	},
};

export const CREWS_CONFIGURATION_REDUCER_CONSTANTS = {
	SET_CREWS: "SET_CREWS",
	ADD_CREW_MEMBER: "ADD_CREW_MEMBER",
	DELETE_LOCAL_CREW_MEMBER: "DELETE_LOCAL_CREW_MEMBER",
	UPDATE_CREW_MEMBER: "UPDATE_CREW_MEMBER",
	DELETE_CREW_SUCCESS: "DELETE_CREW_SUCCESS",
	SET_SHOW_ERROR: "SET_SHOW_ERROR",
};

export const COMMON_MODAL_SYSTEM = {
	PUSH_MODAL: "PUSH_MODAL",
	POP_MODAL: "POP_MODAL",
};

export const BLOCK_LEVEL_INDICATOR_TYPES = {
	ERROR: "error",
	WARNING: "warning",
	SUCCESS: "success",
	INFO: "info",
};

export const INDICATOR_SIZE = { STANDARD: "standard", SMALL: "small" };

export const TIME_MASK_VALUE = "__:__";

export const ENDS_ON = {
	SAME_DAY: "sameDay",
	OVERLAPPING: "overlapping",
	NEXT_DAY: "nextDay",
};

export const FILE_CONTENT_TYPES = {
	XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const UPLOAD_CATEGORY = {
	EQUIPMENT_INTELLIGENCE: "EquipmentIntelligence",
	WEEKLY_ROTATIONS: "WeeklyRotations",
};

export const MODAL_TYPES = {
	ERROR: "error",
	WARNING: "warning",
	SUCCESS: "success",
	CONFIRM: "confirm",
};

export const CONFIG_CONTEXT = {
	STEPPER_CURRENT_STATE: "STEPPER_CURRENT_STATE",
	STORE_CONSTANTS_API: "STORE_CONSTANT_API",
	SET_CURRENT_STEP: "SET_CURRENT_STEP",
	SET_STEPPER_STATUS: "SET_STEPPER_STATUS",
	STEPPER_CURRENT_STATE_SILENT_UPDATE: "STEPPER_CURRENT_STATE_SILENT_UPDATE",
};

export const PFC_VALUES = {
	UNIT: "Unit",
	POUND: "Pound",
	DOLLAR: "Dollar",
};
export const DAILY_WEEKLY_ROTATION_CONSTANTS = {
	ROTATIONS: { title: "Rotation", name: "rotation" },
	CREWS: { title: "Crews", name: "crew" },
	TIME_FORMAT: {
		hour: "2-digit",
		minute: "2-digit",
	},
	VIEWS: { WEEK: "week", DAY: "day" },
	MONTH_CHANGE: { PREV: "PREV", NEXT: "NEXT", TODAY: "TODAY" },
	ROTATION_COLORS: [
		"rgba(78, 185, 248, 0.8)",
		"rgba(62, 250, 197, 0.8)",
		"rgb(149, 119, 234)",
		"rgba(230, 233, 109, 0.8)",
	],
};

export const WEEKLY_DAILY_REDUCER_CONSTANTS = {
	SET_WEEKLY_ROTATIONS: "SET_WEEKLY_ROTATIONS",
	SET_DAILY_ROTATIONS: "SET_DAILY_ROTATIONS",
	ON_UPLOAD: "ON_UPLOAD",
	SET_FILE_OBJECT: "SET_FILE_OBJECT",
	SET_INITIAL_STEP: "SET_INITIAL_STEP",
	SET_ERROR_MESSAGES: "SET_ERROR_MESSAGES",
	SET_PREVIW: "SET_PREVIW",
	SET_VIEW_DATA: "SET_VIEW_DATA",
	SET_SELECTED_DATE: "SET_SELECTED_DATE",
	ON_VIEW_CHANGE: "ON_VIEW_CHANGE",
};

export const COMPONENT_ID = {
	STEPPER: "step-",
	LOADER_TEST_ID: "loading-indicator",
};

export const CALENDAR_TEST_IDS = {
	PREVIOUS_MONTH_BUTTON: "button-previous-month",
	NEXT_MONTH_BUTTON: "button-next-month",
	TODAY_BUTTON: "button-today",
	MONTH_CAPTION: "text-month-caption",
	VIEWS_TOOLTIP_CONTENT: "tooltip-views-content",
	VIEWS_TOOLTIP_ICON: "tooltip-views-icon",
	WEEKLY_RADIO_BUTTON: "radio-button-weekly",
	DAILY_RADIO_BUTTON: "radio-button-daily",
	LEGEND_ITEMS: "legend-items",
	LEGEND_ITEM: "legend-item",
	EVENT_ITEM: "event-item",
	EVENT_POPOVER_TITLE: "event-popover-title",
	EVENT_POPOVER_CAPTION: "event-popover-date-caption",
	EVENT_POPOVER: "event-popover",
	EVENT_POPOVER_CLOSE: "event-popover-close",
	EVENT_POPOVER_EDIT: "event-popover-edit",
	EVENT_POPOVER_DROPDOWN: "event-popover-dropdown",
	EVENT_POPOVER_DROPDOWN_ERROR: "event-popover-dropdown-error",
	EVENT_POPOVER_DROPDOWN_ITEM: "event-popover-dropdown-item",
	EVENT_POPOVER_COLLAPSE: "event-popover-collapse-element",
	EVENT_SAVE: "button-event-save",
};

export const INTERDEPENDENCY_CONTEXT = {
	SET_STATE: "SET_STATE",
	UPDATE_SHIFT_MODAL: "UPDATE_SHIFT_MODAL",
	UPDATE_STATE_AFTER_STATUS: "UPDATE_STATE_AFTER_STATUS",
	UPDATE_CREW_MODAL: "UPDATE_CREW_MODAL",
	UPDATE_ROTATION_MODAL: "UPDATE_ROTATION_MODAL",
};

export const NAVIAGTION_TYPE = {
	STEP: "STEP_NAVIGATION",
};

export const APP_URL = {
	GENERAL_CONFIG: "/config/general_config",
	CONFIG: "/config",
};
export const SHIFT_EDIT_REDUCER_CONSTANTS = {
	ADD_BREAK: "ADD_BREAK",
	REMOVE_BREAK: "REMOVE_BREAK",
	UPDATE_SHIFT_NAME: "UPDATE_SHIFT_NAME",
	UPDATE_DAY_SELECTION: "UPDATE_DAY_SELECTION",
	UPDATE_TIMING: "UPDATE_TIMING",
	LOAD_MAIN_STATE: "LOAD_MAIN_STATE",
	SELECT_HIERARCHY: "SELECT_HIERARCHY",
	UPDATE_BREAK_NAME: "UPDATE_BREAK_NAME",
	UPDATE_BREAK_DAY_SELECTION: "UPDATE_BREAK_DAY_SELECTION",
	UPDATE_BREAK_TIMING: "UPDATE_BREAK_TIMING",
	SHOW_ERRORS: "SHOW_ERRORS",
	UPDATE_BREAK_FIELD_STATE: "UPDATE_BREAK_FIELD_STATE",
};
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9 ]*$/;

export default {};
