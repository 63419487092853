import PropTypes from "prop-types";
import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function ModalBody({
	dialogTitle = "",
	actions = null,
	dialogContent,
	customIcon,
	minWidth = "36rem",
	maxWidth = "40rem",
}) {
	return (
		<Box
			display="flex"
			flexDirection="row"
			flexBasis="100%"
			minWidth={minWidth}
			maxWidth={maxWidth}
		>
			<Box marginTop="0.5rem">{customIcon}</Box>
			<Box display="flex" flexDirection="column" flexBasis="95%">
				<DialogTitle
					className="notificationdialog-title"
					data-testid="modal-header-text"
				>
					{dialogTitle}
				</DialogTitle>
				<DialogContent>{dialogContent}</DialogContent>
				<DialogActions>{actions}</DialogActions>
			</Box>
		</Box>
	);
}

ModalBody.propTypes = {
	dialogTitle: PropTypes.string,
	minWidth: PropTypes.string,
	actions: PropTypes.node,
	dialogContent: PropTypes.node,
	customIcon: PropTypes.node,
	maxWidth: PropTypes.string,
};
