import {
	areIntervalsOverlapping,
	compareAsc,
	isWithinInterval,
	parse,
	add,
	differenceInMinutes,
} from "date-fns";
import { cloneDeep, countBy, isEmpty, uniqueId } from "lodash";
import MESSAGE_STRINGS from "../../../constants/en-us";
import { isValid24HrTime } from "../ScheduleValidations";

/**
 * Function to add days to time
 * @param {String} time  HH:MM
 * @param {String} day  "sameDay"/"nextDay"
 * @returns date-fns date object
 */
export const addDayToTime = (time, day) => {
	if (!isValid24HrTime(time)) return null;
	return add(parse(time, "HH:mm", new Date()), {
		hours: day === "nextDay" ? 24 : 0,
	});
};
/**
 * Function to return formatted shift
 * @param {Object} shift  shift object
 * @returns object
 */
export const formatInitialShift = (shift) => {
	const newShift = shift;
	newShift.formattedStartTime = addDayToTime(
		newShift.startTime?.time,
		newShift.startsOn
	);
	newShift.formattedEndTime = addDayToTime(
		newShift.endTime?.time,
		newShift.endsOn
	);

	if (!newShift.shiftName?.trim()) {
		newShift.nameErrorText = MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"];
	}
	return newShift;
};
/**
 * Function to check given time lies within given interval
 * @param {Date} time
 * @param {Interval} timeInterval  {start: Date, end: Date}
 * @returns Boolean
 */
export const isTimeWithinInterval = (time, timeInterval) => {
	try {
		return isWithinInterval(time, timeInterval);
	} catch {
		return true;
	}
};
/**
 * Custom Function to validate given time for Breaks validation
 * @param {String} breakTime HH:MM
 * @param {Date} formattedTime new Date()
 * @param {Interval} shiftInterval {start: Date, end: Date}
 * @returns Object
 */
export const getBreakTimeErrorMessage = (
	breakTime,
	formattedTime,
	shiftInterval,
	isSubShift
) => {
	if (!breakTime) {
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
		};
	}
	if (!isValid24HrTime(breakTime)) {
		return {
			isError: true,
			errorText: "Not valid entry",
		};
	}
	if (!isTimeWithinInterval(formattedTime, shiftInterval)) {
		return {
			isError: true,
			errorText: `Break time must be within ${
				isSubShift ? "sub-shift" : "shift"
			} timings`,
		};
	}
	return { isError: false, errorText: "" };
};
/**
 * Function to validate if two time intervals are overlapping
 * @param {Interval} leftTime
 * @param {Interval} rightTime
 * @returns Boolean
 */
const areOverlapping = (leftTime, rightTime) => {
	try {
		return areIntervalsOverlapping(
			{ start: leftTime?.formattedStartTime, end: leftTime?.formattedEndTime },
			{
				start: rightTime?.formattedStartTime,
				end: rightTime?.formattedEndTime,
			}
		);
	} catch {
		return false;
	}
};
/**
 * Function to validate all breaks with current break to check for Overlapping
 * @param {Array} breaks
 * @param {Object} currentBreak
 * @returns Boolean
 */
const isBreakTimeOverlapping = (breaks, currentBreak) => {
	return breaks.some(
		(b) =>
			b.id !== currentBreak.id &&
			b.endsOn &&
			b.startsOn &&
			areOverlapping(b, currentBreak)
	);
};
/**
 * Custom function to to validate break Names
 * @param {Array} breaks
 * @returns Array
 */
export const validateBreakNames = (breaks) => {
	const counts = countBy(
		breaks.map((br) => ({
			name: br.name || "",
		})),
		"name"
	);
	return breaks.map((b) => {
		if (!b.name?.trim()) {
			return { ...b, nameError: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"] };
		}
		if (counts[b.name?.trimEnd()] > 1 && b.name?.length !== 0) {
			return { ...b, nameError: "Break names cannot be duplicate" };
		}
		return { ...b, nameError: "" };
	});
};

/**
 * Custom function to to validate Break Timings
 * @param {Array} breaks
 * @returns Array
 */
export const validateBreakTimings = (breaks, shift) => {
	const isSubShift = shift.type === "subShift";
	return breaks.map((breakObj) => {
		const {
			formattedStartTime,
			formattedEndTime,
			endsOn,
			startsOn,
			startTime,
			endTime,
		} = breakObj;
		const shiftInterval = {
			start: shift?.formattedStartTime,
			end: shift?.formattedEndTime,
		};
		let startTimeError = getBreakTimeErrorMessage(
			startTime.time,
			formattedStartTime,
			shiftInterval,
			isSubShift
		);
		let endTimeError = getBreakTimeErrorMessage(
			endTime.time,
			formattedEndTime,
			shiftInterval,
			isSubShift
		);
		/**
		 * Start time and End time Dependency validation
		 * 1. Check starTime <= endTime
		 * 2. Validate if Break times are overlapping
		 */
		if (
			endsOn &&
			startsOn &&
			!startTimeError.isError &&
			!endTimeError.isError
		) {
			if (compareAsc(formattedStartTime, formattedEndTime) > -1) {
				startTimeError = {
					isError: true,
					errorText: "Start time must be before end time",
				};
				endTimeError = {
					isError: true,
					errorText: "End time must be greater than start time",
				};
			} else if (isBreakTimeOverlapping(breaks, breakObj)) {
				startTimeError = {
					isError: true,
					errorText: "Break Timings cannot overlap",
				};
				endTimeError = {
					isError: true,
					errorText: "Break Timings cannot overlap",
				};
			}
		}
		return {
			...breakObj,
			startTime: { ...startTime, ...startTimeError },
			endTime: { ...endTime, ...endTimeError },
		};
	});
};
/**
 * @param {String} type  endsOn or startsOn
 * @param {*} startsOn  value of current startsOn Field
 * @returns Boolean
 */
export const isCalendarDay1Disabled = (type, startsOn) => {
	return !!(type === "endsOn" && startsOn === "nextDay");
};

export const getShiftTimingErrorMessage = (time) => {
	if (!time) {
		return {
			isError: true,
			errorText: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
		};
	}
	if (!isValid24HrTime(time)) {
		return { isError: true, errorText: "Invalid Time" };
	}
	return { isError: false, errorText: "" };
};
/**
 * Function to validate Hierarchy Selection
 * @param {Object} item
 * @param {Array} shifts
 */
export const validateHierarchySelection = (item, shifts, parentShift) => {
	const { selectedHierarchy = {}, id: itemId } = item;
	const filteredHierarchy = Object.keys(selectedHierarchy).filter(
		(key) => selectedHierarchy[key] === true
	);
	let itemsArray = shifts;
	const isSubShift = item.type === "subShift";
	if (isSubShift && parentShift) itemsArray = parentShift.subShifts || [];
	const isHierarchySelectedByOtherShift = filteredHierarchy?.some((key) =>
		itemsArray?.some(
			({ id, selectedHierarchy: hierarchyObj = {}, ...rest }) =>
				itemId !== id &&
				hierarchyObj[key] === true &&
				areOverlapping(item, rest)
		)
	);
	if (isHierarchySelectedByOtherShift) {
		return `The selected hierarchy has been assigned to another ${
			isSubShift ? "sub-shift" : "shift"
		} with the same timings. Please revise your selections.`;
	}
	return "";
};

export const validateShiftTimings = (shift, parentShift, mainState) => {
	let startTimeError = getShiftTimingErrorMessage(shift.startTime.time);
	let endTimeError = getShiftTimingErrorMessage(shift.endTime.time);
	const isSubShift = shift.type === "subShift";
	const errorPrefixMessage = isSubShift ? "Sub-shift" : "Shift";
	if (
		shift.endsOn &&
		shift.startsOn &&
		!startTimeError.isError &&
		!endTimeError.isError
	) {
		if (compareAsc(shift.formattedStartTime, shift.formattedEndTime) > -1) {
			startTimeError = {
				isError: true,
				errorText: "Start time must be before end time",
			};
			endTimeError = {
				isError: true,
				errorText: "End time must be greater than start time",
			};
		} else if (
			differenceInMinutes(shift.formattedEndTime, shift.formattedStartTime) >
			24 * 60
		) {
			endTimeError = {
				isError: true,
				errorText: `${errorPrefixMessage} times cannot be longer than 24 hours`,
			};
			startTimeError = {
				isError: true,
				errorText: `${errorPrefixMessage} times cannot be longer than 24 hours`,
			};
		} else if (shift.type === "subShift" && parentShift) {
			const mainShiftInterval = {
				start: parentShift.formattedStartTime,
				end: parentShift.formattedEndTime,
			};
			if (!isTimeWithinInterval(shift.formattedStartTime, mainShiftInterval)) {
				startTimeError = {
					isError: true,
					errorText: "Value must be within the main shift timings.",
				};
			}
			if (!isTimeWithinInterval(shift.formattedEndTime, mainShiftInterval)) {
				endTimeError = {
					isError: true,
					errorText: "Value must be within the main shift timings.",
				};
			}
		}
	}
	return {
		...shift,
		startTime: { ...shift.startTime, ...startTimeError },
		endTime: { ...shift.endTime, ...endTimeError },
		hierarchyErrorMessage: validateHierarchySelection(
			shift,
			mainState?.shifts,
			parentShift
		),
	};
};
export const validateShiftName = (newShift, mainState, parentShift) => {
	const { shiftName, type } = newShift;
	const isSubShift = type === "subShift";
	if (!shiftName?.trim()) {
		return MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"];
	}
	let itemsArray = mainState?.shifts;
	if (isSubShift && parentShift) itemsArray = parentShift.subShifts || [];
	const counts = countBy(
		itemsArray?.filter((shift) => shift.id !== newShift.id),
		"shiftName"
	);
	if (counts[shiftName?.trimEnd()] > 0 && shiftName?.length !== 0) {
		return `${isSubShift ? "Sub-shift" : "Shift"} names cannot be duplicate`;
	}
	return "";
};
export const isEndsOnBeforeStartsOn = (startsOn, endsOn, field) => {
	return !!(
		field === "startsOn" &&
		startsOn === "nextDay" &&
		endsOn === "sameDay"
	);
};

export const getInitialLoadData = (state, payload) => {
	const {
		mainState,
		selectedShift,
		actionType,
		parentShift,
		factoryId,
		hierarchyData,
	} = payload;
	const newState = { ...state };
	newState.plantEntityId = hierarchyData?.entityId;
	newState.factoryId = factoryId;
	const defaultShift = {
		shiftId: uniqueId("localShift_"),
		shiftName: "",
		startTime: {
			time: null,
			isError: true,
			errorText: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
		},
		endTime: {
			time: null,
			isError: true,
			errorText: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
		},
		renderStartTime: null,
		renderEndTime: null,
		nameErrorText: "",
		breaks: [],
		subShifts: [],
		selectedHierarchy: {},
	};
	if (actionType === "EDIT_SHIFT") {
		if (selectedShift) {
			newState.shift = formatInitialShift(
				cloneDeep({ ...defaultShift, ...selectedShift })
			);
		}
	}
	if (actionType === "ADD_NEW_SHIFT") {
		newState.shift = formatInitialShift(
			cloneDeep({
				...defaultShift,
				isLocal: true,
				isNew: true,
				type: "shift",
			})
		);
	}
	if (actionType === "EDIT_SUB_SHIFT") {
		if (selectedShift) {
			newState.shift = formatInitialShift(
				cloneDeep({ ...defaultShift, ...selectedShift })
			);
			newState.parentShift = cloneDeep(parentShift);
		}
	}
	if (actionType === "ADD_NEW_SUB_SHIFT") {
		newState.shift = formatInitialShift(
			cloneDeep({
				...defaultShift,
				parentId: parentShift.id,
				isLocal: true,
				isNew: true,
				type: "subShift",
				selectedHierarchy: {},
				id: uniqueId("localSubShift_"),
			})
		);
		newState.parentShift = cloneDeep(parentShift);
	}
	if (!newState.shift?.breaks) {
		newState.shift.breaks = [];
	}
	if (mainState) newState.mainState = cloneDeep(mainState);
	return newState;
};

export function handleErrorVisibilityChange(type, func, val) {
	if (type === "blur" && val.dirty) {
		func((prev) => {
			return {
				...prev,
				blur: true,
			};
		});
	} else if (type === "dirty") {
		func((prev) => {
			return {
				...prev,
				dirty: true,
			};
		});
	}
}

export const getBreakItemsForUpdate = (
	newShift,
	id,
	enteredText,
	fieldType,
	value
) => {
	const newBreaks = cloneDeep(newShift.breaks);
	const itemToBeUpdated = newBreaks.find((br) => br.id === id);
	if (itemToBeUpdated) {
		itemToBeUpdated.isUpdated = true;
		if (!isValid24HrTime(enteredText)) {
			itemToBeUpdated[fieldType] = null;
		} else itemToBeUpdated[fieldType] = value;
		const oldFieldState = itemToBeUpdated.fieldState[fieldType];
		itemToBeUpdated.fieldState[fieldType] = {
			...oldFieldState,
			dirty: true,
		};
		if (fieldType === "renderStartTime") {
			itemToBeUpdated.formattedStartTime = addDayToTime(
				enteredText,
				itemToBeUpdated.startsOn
			);
			itemToBeUpdated.startTime.time = enteredText;
		} else {
			itemToBeUpdated.formattedEndTime = addDayToTime(
				enteredText,
				itemToBeUpdated.endsOn
			);
			itemToBeUpdated.endTime.time = enteredText;
		}
	}
	return newBreaks;
};

function getErrorString(subShifts1, subShifts2) {
	if (areOverlapping(subShifts1, subShifts2)) {
		const errorString = validateHierarchySelection(subShifts1, [], {
			subShifts: [subShifts2],
		});
		if (errorString !== "") {
			return MESSAGE_STRINGS["Schedule.shiftOverlap.subShift.errorMessage"];
		}
	}
	return "";
}

function mapOverShifts(subShifts1, subShifts2) {
	let errorVal = "";
	for (let i = 0; i < subShifts1?.length; i += 1) {
		for (let j = 0; j < subShifts2?.length; j += 1) {
			if (errorVal !== "") return errorVal;
			errorVal = getErrorString(subShifts1[i], subShifts2[j]);
		}
	}
	return errorVal;
}

function getOverlappingSubShifts(overlappingShifts) {
	let errorVal = "";
	if (overlappingShifts?.length < 0) return "";

	for (let i = 0; i < overlappingShifts?.length; i += 1) {
		const subShifts1 = overlappingShifts[i][0]?.subShifts;
		const subShifts2 = overlappingShifts[i][1]?.subShifts;
		errorVal = mapOverShifts(subShifts1, subShifts2);
		if (errorVal !== "") {
			break;
		}
	}
	return errorVal;
}

export const validateOverlappingSubShifts = (shifts = []) => {
	let errorVal = "";
	const overlappingShifts = [];
	for (let i = 0; i < shifts.length; i += 1) {
		for (let j = i + 1; j < shifts.length; j += 1) {
			if (areOverlapping(shifts[i], shifts[j])) {
				overlappingShifts.push([shifts[i], shifts[j]]);
			}
		}
	}
	errorVal = getOverlappingSubShifts(overlappingShifts);
	return errorVal;
};

export const toggleDescendants = (nodeList, entity) => {
	if (!entity?.entityChildren?.length) {
		return;
	}
	entity?.entityChildren.forEach((n) => {
		// eslint-disable-next-line no-param-reassign
		nodeList[n.entityId] = true;
		toggleDescendants(nodeList, n);
	});
};
export const getDefaultSelectedHierarchy = (
	entities,
	shift,
	shiftType,
	parentShift,
	state
) => {
	if (isEmpty(shift?.selectedHierarchy) && shiftType === "shift") {
		const nodeList = {};
		nodeList[entities?.entityId] = true;
		toggleDescendants(nodeList, entities);
		const replica = { ...shift };
		replica.selectedHierarchy = nodeList;
		const hierarchyErrorMessage = validateHierarchySelection(
			replica,
			state?.shifts,
			parentShift
		);
		return {
			selectedHierarchy: nodeList,
			isUpdated: true,
			hierarchyErrorMessage,
		};
	}
	if (isEmpty(shift?.selectedHierarchy) && shiftType === "subShift") {
		return {
			selectedHierarchy: parentShift.selectedHierarchy,
			isUpdated: true,
		};
	}
	return { selectedHierarchy: shift.selectedHierarchy };
};
