/* eslint-disable no-unused-vars */
import { IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { some } from "lodash";
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from "@mui/system";
import { ErrorBoundary } from "react-error-boundary";
import { globalstate$ } from "@smf/ui-util-app";
import GeneralConfigBody from "../layouts/GeneralConfigBody";
import GeneralConfigFooter from "../layouts/GeneralConfigFooter";
import { useGeneralConfigContext } from "../../context/generalConfigContext";
import PlusCircle from "../../assets/img/plusInsideCircle.svg";
import GeneralTable from "../../components/GeneralTable";
import { useCrewsTable } from "./useCrewsTable";
import { useCrews } from "../../hooks/useCrews";
import MESSAGE_STRINGS from "../../constants/en-us";
import CommonBackdrop from "../../components/CommonBackdrop";
import { CREW_TESTID } from "./CrewsConstant";
import useRxjsState from "../../hooks/useRxjsState";
import {
	APP_URL,
	GENERAL_CONFIGURATION_STEPS,
	NAVIAGTION_TYPE,
	STEPPER_STATUSES,
} from "../../constants";
import { updateFactoryGeneralEditAction } from "../../utils/rxjs";
import NavigationModal from "../layouts/NavigationModal/NavigationModal";
import LoadingIndicator from "../../components/LoadingIndicator";

const StyledCrewsHeader = styled("div")({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	"& .crewsHeader-addMemberIcon": {
		marginLeft: "0.5rem",
	},
});

const StyledCrewsBody = styled("div")({
	display: "flex",
	"& .crews-actionheader, .crews-actionbody": {
		display: "flex",
		justifyContent: "flex-end",
		paddingRight: "6rem",
	},
});

function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary} type="button">
				Try again
			</button>
		</div>
	);
}

ErrorFallback.propTypes = {
	error: PropTypes.objectOf({
		message: PropTypes.string,
	}),
	resetErrorBoundary: PropTypes.func,
};

function Crews({
	onNextClickAPI,
	onPreviousClickAPI,
	navigateTo,
	isInterDepedencyState,
	refetchGeneralStatus,
}) {
	const plantId = globalstate$?.value?.plantId;
	const {
		data,
		addCrewMember,
		deleteCrewMember,
		updateCrewMember,
		handleSave,
		isLoading,
	} = useCrews(plantId, refetchGeneralStatus);
	const { columns } = useCrewsTable();
	const {
		generalConfigState: { isMaintenanceMode, steps },
	} = useGeneralConfigContext();
	const { rxjsState } = useRxjsState();
	const {
		factoryGeneralEditState: { action: factoryGeneralAction = {} } = {},
	} = rxjsState;

	function handleNavigation(location = "") {
		if (factoryGeneralAction?.type === NAVIAGTION_TYPE.STEP) {
			navigateTo(location, factoryGeneralAction?.type);
			return;
		}
		if (factoryGeneralAction.location) {
			navigateTo(location);
		}
	}
	function handleNextClick(actionType = null) {
		handleSave()
			.then(actionType === MESSAGE_STRINGS["FGC.save"] ? null : onNextClickAPI)
			.catch(() => null);
	}

	function handleSaveClick() {
		handleNextClick(MESSAGE_STRINGS["FGC.save"]);
	}

	function checkErrors() {
		const isErrorPresent = some(data, (crew) => crew.showError && crew.isError);
		return !!isErrorPresent;
	}
	async function handlePreviousClick() {
		onPreviousClickAPI();
	}

	const mappedMethods = React.useMemo(
		() => ({
			addCrewMember,
			deleteCrewMember,
			updateCrewMember,
		}),
		[addCrewMember, deleteCrewMember, updateCrewMember]
	);

	const isSaveEnabled = checkErrors();
	const isSameLocation =
		factoryGeneralAction.location === APP_URL.CONFIG ||
		factoryGeneralAction.location === APP_URL.GENERAL_CONFIG;
	useEffect(() => {
		if (
			steps[GENERAL_CONFIGURATION_STEPS.CREWS - 1].stepStatus ===
				STEPPER_STATUSES.IN_PROGRESS &&
			isMaintenanceMode === true
		) {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: true });
		} else {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: false });
		}
		return () => {
			updateFactoryGeneralEditAction(
				{ isUserNavigating: false, location: "", type: null },
				{ isUnsavedChange: false }
			);
		};
	}, [steps, isMaintenanceMode]);
	return (
		<>
			<GeneralConfigBody>
				<StyledCrewsHeader>
					<Typography variant="h6">
						{MESSAGE_STRINGS["GeneralSettings.Crews.sectionHeader"]}
					</Typography>
					<IconButton
						data-testid={CREW_TESTID.ADD}
						className="crewsHeader-addMemberIcon"
						onClick={addCrewMember}
					>
						<PlusCircle />
					</IconButton>
				</StyledCrewsHeader>
				<StyledCrewsBody>
					<ErrorBoundary FallbackComponent={ErrorFallback}>
						<GeneralTable
							columns={columns}
							data={data}
							isLoading={false}
							isError={false}
							showHeader
							enablePagination={false}
							tableInitState={{ pageIndex: 0 }}
							mappedMethods={mappedMethods}
						/>
					</ErrorBoundary>
					<CommonBackdrop open={isLoading}>
						<LoadingIndicator />
					</CommonBackdrop>
				</StyledCrewsBody>
			</GeneralConfigBody>
			<GeneralConfigFooter
				onNextClick={handleNextClick}
				onPreviousClick={handlePreviousClick}
				showSave={isMaintenanceMode}
				onClickSave={handleSaveClick}
				isSaveButtonEnabled={isSaveEnabled}
				isSaveEnabled={isSaveEnabled}
			/>
			<NavigationModal
				open={
					Boolean(factoryGeneralAction?.isUserNavigating) &&
					!isSameLocation &&
					!isInterDepedencyState
				}
				onSave={async () => {
					updateFactoryGeneralEditAction({
						isUserNavigating: false,
						type: null,
					});
					handleSave().then(() => {
						handleNavigation(factoryGeneralAction?.location);
					});
				}}
				onClose={() => handleNavigation(factoryGeneralAction?.location)}
			/>
		</>
	);
}
Crews.propTypes = {
	onNextClickAPI: PropTypes.func,
	onPreviousClickAPI: PropTypes.func,
	navigateTo: PropTypes.func,
	isInterDepedencyState: PropTypes.bool,
	refetchGeneralStatus: PropTypes.func,
};

export default Crews;
