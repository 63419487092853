/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { MenuItem, Typography, Box } from "@mui/material";
import GeneralTextField from "../../../../components/GeneralTextField";
import GeneralDropdown from "../../../../components/GeneralDropdown";
import TimePicker from "../../../../components/TimePicker";
import { SCHEDULE_TEST_ID } from "../../ScheduleConstants";
import MESSAGE_STRINGS from "../../../../constants/en-us";
import {
	ALPHANUMERIC_REGEX,
	DROPDOWN_CONSTANTS,
	SHIFT_EDIT_REDUCER_CONSTANTS,
} from "../../../../constants";
import InlineErrorMessages from "../../../../components/InlineErrorMessages";
import {
	handleErrorVisibilityChange,
	isCalendarDay1Disabled,
} from "../helpers";

function FieldWrapper({
	label = "",
	children = null,
	maxWidth = 160,
	...rest
}) {
	return (
		<Box maxWidth={maxWidth} width="100%" {...rest}>
			<Typography
				sx={{
					color: (theme) => theme.palette.text.lightUnitGrey,
					fontSize: "0.875rem",
					marginBottom: "0.25rem",
				}}
			>
				{label}
			</Typography>
			{children}
		</Box>
	);
}
function getSameDayValue(value) {
	if (!value) return DROPDOWN_CONSTANTS.DEFAULT_VALUE;
	return value;
}
function DaySelectField({ type = "startsOn", dispatch, shift }) {
	const [visible, setVisible] = useState({ dirty: false, blur: false });
	const value = shift[type];
	useEffect(() => {
		if (shift.isShowError && !visible.blur)
			setVisible({ dirty: true, blur: true });
	}, [shift.isShowError, visible.blur]);
	return (
		<>
			<GeneralDropdown
				fullWidth
				value={getSameDayValue(value)}
				onChange={(e) => {
					if (!visible.dirty)
						handleErrorVisibilityChange("dirty", setVisible, visible);
					dispatch({
						type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_DAY_SELECTION,
						payload: { field: type, value: e.target.value },
					});
				}}
				onBlur={() => handleErrorVisibilityChange("dirty", setVisible, visible)}
				error={visible.blur && !value}
				placeholderText="Select"
			>
				<MenuItem
					value="sameDay"
					data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_ENDS_ON_ITEMS}
					disabled={isCalendarDay1Disabled(type, shift.startsOn)}
				>
					{MESSAGE_STRINGS["Schedule.endsOn.sameDay"]}
				</MenuItem>
				<MenuItem
					value="nextDay"
					data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_ENDS_ON_ITEMS}
				>
					{MESSAGE_STRINGS["Schedule.endsOn.nextDay"]}
				</MenuItem>
			</GeneralDropdown>
			{visible.blur && !value && (
				<InlineErrorMessages
					width="100%"
					message={MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"]}
					marginTop="0.25rem"
				/>
			)}
		</>
	);
}
function ShiftsTimeField({ dispatch, type, shift }) {
	const [visible, setVisible] = useState({ dirty: false, blur: false });
	const value = shift[type];
	const isStartTime = type === "renderStartTime";
	const errorObj = isStartTime ? shift.startTime : shift.endTime;

	function onChange(newDate, enteredText) {
		if (!visible.dirty)
			handleErrorVisibilityChange("dirty", setVisible, visible);
		dispatch({
			type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_TIMING,
			payload: { field: type, value: newDate, enteredText },
		});
	}

	useEffect(() => {
		if (shift.isShowError && !visible.blur)
			setVisible({ dirty: true, blur: true });
	}, [shift.isShowError, visible.blur]);

	return (
		<>
			<TimePicker
				textFieldPlaceHolder="HH:MM"
				value={value}
				handleChange={onChange}
				isError={visible.blur && !!errorObj.isError}
				onBlur={() => handleErrorVisibilityChange("blur", setVisible, visible)}
				dataTestId="edit-modal-timepicker"
			/>
			{visible.blur && !!errorObj.isError && (
				<InlineErrorMessages
					width="100%"
					message={errorObj.errorText}
					marginTop="0.25rem"
				/>
			)}
		</>
	);
}
function NameField({ dispatch, shift }) {
	const [visible, setVisible] = useState({ dirty: false, blur: false });

	function onChange(e) {
		const newValue = e.target.value;
		if (newValue.match(ALPHANUMERIC_REGEX)) {
			if (!visible.dirty)
				handleErrorVisibilityChange("dirty", setVisible, visible);
			dispatch({
				type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_SHIFT_NAME,
				payload: { value: e.target.value },
			});
		}
	}
	useEffect(() => {
		if (shift.isShowError && !visible.blur)
			setVisible({ dirty: true, blur: true });
	}, [shift.isShowError, visible.blur]);
	return (
		<>
			<GeneralTextField
				placeholder="Name"
				isPlaceholderItalic={false}
				value={shift.shiftName}
				onChange={onChange}
				onBlur={() => handleErrorVisibilityChange("blur", setVisible, visible)}
				error={visible.blur && !!shift.nameErrorText}
				maxLength={255}
			/>
			{visible.blur && !!shift.nameErrorText && (
				<InlineErrorMessages
					width="100%"
					message={shift.nameErrorText}
					marginTop="0.25rem"
				/>
			)}
		</>
	);
}

function ShiftsDetails({ state, dispatch }) {
	return (
		<Box
			display="flex"
			justifyContent="space-between"
			marginBottom="1rem"
			data-testid="edit-modal-shifts-details"
		>
			<FieldWrapper label="Name">
				<NameField dispatch={dispatch} shift={state} />
			</FieldWrapper>
			<FieldWrapper label="Starts On" maxWidth={200}>
				<DaySelectField type="startsOn" dispatch={dispatch} shift={state} />
			</FieldWrapper>
			<FieldWrapper label="Start Time">
				<ShiftsTimeField
					type="renderStartTime"
					dispatch={dispatch}
					shift={state}
				/>
			</FieldWrapper>
			<FieldWrapper label="Ends On" maxWidth={200}>
				<DaySelectField type="endsOn" dispatch={dispatch} shift={state} />
			</FieldWrapper>
			<FieldWrapper label="End Time">
				<ShiftsTimeField
					type="renderEndTime"
					dispatch={dispatch}
					shift={state}
				/>
			</FieldWrapper>
		</Box>
	);
}

ShiftsDetails.propTypes = {};

export default ShiftsDetails;
