import React from "react";
import PropTypes from "prop-types";
import {
	Box,
	Step,
	StepButton,
	Stepper,
	Typography,
	alpha,
	styled,
} from "@mui/material";
import { COMPONENT_ID, STEPPER_STATUSES } from "../../constants";
import CheckedTickMark from "../../assets/img/checkedTickMark.svg";
import UncheckedTickMark from "../../assets/img/uncheckedTickMark.svg";

function getStylesForStep(index, selectedStep, isEditing, stepStatus) {
	if (index === selectedStep) {
		return 0;
	}
	if (isEditing) {
		return 1;
	}
	if (index < selectedStep || stepStatus !== STEPPER_STATUSES.NOT_STARTED) {
		return 2;
	}
	return 3;
}
const CustomizedStepper = styled(Stepper)(({ theme }) => ({
	"& .MuiStepper-root": {
		padding: 0,
		background: alpha(theme.palette.background.darkishBlackBlue, 0.3),
		minHeight: 60,
	},
	"& .MuiStepConnector-root": {
		width: "4rem",
	},
	"& .MuiStepConnector-line": {
		border: "none",
	},
}));
const CustomizedStep = styled(Step, {
	shouldForwardProp: (prop) => prop !== "stepType",
})(({ stepType, theme, disabled }) => ({
	padding: 0,
	borderTopLeftRadius: 4,
	borderTopRightRadius: 4,
	width: "100%",
	minHeight: "4rem",
	...(() => {
		if (stepType === 0)
			return {
				background: "#2D3338", // need to add in theme.palette.background.metalGrey,
				border: `1px solid ${theme.palette.background.darkGrey}`,
				borderBottom: `0.25rem solid ${theme.palette.background.saveGreen}`,
			};
		if (stepType === 1 && !disabled)
			return {
				border: `1px solid ${theme.palette.background.darkishBlackBlue}`,
				borderBottom: `0.25rem solid ${theme.palette.background.darkishBlackBlue}`,
				background: theme.palette.background.darkishBlackBlue,
			};
		if (stepType === 2 && !disabled)
			return {
				background: "#2D3338", // Need to add in theme.palette.background.metalGrey,
				border: `1px solid ${theme.palette.background.darkGrey}`,
				borderBottom: `0.25rem solid ${theme.palette.background.metalGrey}`,
			};
		return {
			background: theme.palette.background.darkGrey,
			border: `1px solid ${theme.palette.background.darkGrey}`,
			borderBottom: `0.25rem solid ${theme.palette.background.darkGrey}`,
		};
	})(),
}));

const CustomizedStepButton = styled(StepButton)(() => ({
	"& .MuiStepButton-root": {
		margin: 0,
		padding: 0,
		cursor: "default",
		width: "100%",
	},
	"& .MuiStepLabel-root": {
		width: "100%",
	},
}));

const CustomizedStepNameTitleTypography = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	color: theme.palette.text.primary,
	textAlign: "left",
}));
const CustomizedStepNumberTypography = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	color: theme.palette.text.primary,
}));

function ConfigSteps({
	steps = [],
	currentStep = 0,
	changeCurrentStep = () => null,
	isNonLinear = false,
	stepperWidth = "100%",
	isInitialSetup = true,
}) {
	function getStepIcon(index, selectedStep, stepStatus, disabled) {
		if (index > selectedStep || disabled) {
			if (stepStatus === STEPPER_STATUSES.NOT_STARTED || disabled) {
				return <Box width={16} />;
			}
			return stepStatus === STEPPER_STATUSES.COMPLETED ? (
				<CheckedTickMark height={16} width={16} />
			) : (
				<UncheckedTickMark height={16} width={16} />
			);
		}
		if (stepStatus === STEPPER_STATUSES.COMPLETED)
			return <CheckedTickMark height={16} width={16} />;
		return <UncheckedTickMark height={16} width={16} />;
	}

	return (
		<Box width={stepperWidth}>
			<CustomizedStepper
				activeStep={currentStep ?? 0}
				nonLinear={isNonLinear}
				orientation="horizontal"
			>
				{steps.map(({ stepName, stepStatus, disabled = false }, index) => (
					<CustomizedStep
						disabled={disabled}
						data-testid={`${COMPONENT_ID.STEPPER}-${stepName}`}
						key={stepName}
						stepType={getStylesForStep(
							index + 1,
							currentStep,
							!isInitialSetup,
							stepStatus
						)}
						active={currentStep === index + 1}
						// eslint-disable-next-line react/no-unstable-nested-components
						completed={stepStatus === STEPPER_STATUSES.COMPLETED}
					>
						<CustomizedStepButton
							sx={{ cursor: isInitialSetup ? "inherit" : "pointer" }}
							onClick={() => {
								if (
									isNonLinear &&
									!isInitialSetup &&
									currentStep !== index + 1
								) {
									changeCurrentStep(index + 1);
								}
							}}
							// eslint-disable-next-line react/jsx-no-useless-fragment
							icon={<></>}
						>
							<Box
								display="flex"
								alignItems="flex-start"
								paddingTop={1}
								paddingBottom={2}
								paddingX={1}
								height="100%"
							>
								<Box marginRight={0.5}>
									<CustomizedStepNumberTypography variant="h3">
										{index + 1}
									</CustomizedStepNumberTypography>
								</Box>
								<Box marginLeft={0.5} marginRight={1} marginTop={0.8}>
									<CustomizedStepNameTitleTypography varint="h3">
										{stepName}
									</CustomizedStepNameTitleTypography>
								</Box>
								<Box display="flex" flexGrow={1} justifyContent="flex-end">
									<Box display="flex" alignItems="center" marginTop={0.5}>
										{getStepIcon(index + 1, currentStep, stepStatus, disabled)}
									</Box>
								</Box>
							</Box>
						</CustomizedStepButton>
					</CustomizedStep>
				))}
			</CustomizedStepper>
		</Box>
	);
}

ConfigSteps.propTypes = {
	steps: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.shape({
				index: PropTypes.number,
				stepName: PropTypes.string,
				stepStatus: PropTypes.string,
			}),
			PropTypes.string,
		])
	),
	currentStep: PropTypes.number,
	changeCurrentStep: PropTypes.func,
	isNonLinear: PropTypes.bool,
	stepperWidth: PropTypes.string,
	isInitialSetup: PropTypes.bool,
};

export default ConfigSteps;
