import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import BlockLevelIndicator from "../../../components/BlockLevelIndicator";
import { BLOCK_LEVEL_INDICATOR_TYPES } from "../../../constants";

import MESSAGE_STRINGS from "../../../constants/en-us";

function CalendarContainer({
	doesNewRotationExist = false,
	areRotationsPresentForNextDay = true,
	areRotationsPresentForNextWeek = true,
	requireNewRotationAssignment = false,
	children,
}) {
	return (
		<>
			<Box display="flex" flexDirection="column">
				{doesNewRotationExist && (
					<Box
						marginBottom={
							!areRotationsPresentForNextDay ||
							!areRotationsPresentForNextWeek ||
							requireNewRotationAssignment
								? 2
								: 0
						}
						width="78%"
					>
						<BlockLevelIndicator type={BLOCK_LEVEL_INDICATOR_TYPES.WARNING}>
							<Typography variant="subtitle1" sx={{ fontSize: "0.875rem" }}>
								{
									MESSAGE_STRINGS[
										"WeeklAndDailyRotations.warning.newUnassignedRotationExists"
									]
								}
							</Typography>
						</BlockLevelIndicator>
					</Box>
				)}
				{!areRotationsPresentForNextDay && (
					<Box width="82%">
						<BlockLevelIndicator type={BLOCK_LEVEL_INDICATOR_TYPES.ERROR}>
							<Typography variant="subtitle1" sx={{ fontSize: "0.875rem" }}>
								{
									MESSAGE_STRINGS[
										"WeeklAndDailyRotations.error.rotationsNotPresentForNextDay"
									]
								}
							</Typography>
						</BlockLevelIndicator>
					</Box>
				)}
				{areRotationsPresentForNextDay && !areRotationsPresentForNextWeek && (
					<Box width="78%">
						<BlockLevelIndicator type={BLOCK_LEVEL_INDICATOR_TYPES.WARNING}>
							<Typography variant="subtitle1" sx={{ fontSize: "0.875rem" }}>
								{
									MESSAGE_STRINGS[
										"WeeklAndDailyRotations.warning.rotationsNotPresentForNextWeek"
									]
								}
							</Typography>
						</BlockLevelIndicator>
					</Box>
				)}
				{requireNewRotationAssignment && (
					<BlockLevelIndicator
						type={BLOCK_LEVEL_INDICATOR_TYPES.ERROR}
						width="fit-content"
					>
						<Typography variant="subtitle1" sx={{ fontSize: "0.875rem" }}>
							{
								MESSAGE_STRINGS[
									"WeeklyAndDailyRotation.error.InterdependecyAction"
								]
							}
						</Typography>
					</BlockLevelIndicator>
				)}
			</Box>
			{children}
		</>
	);
}

CalendarContainer.propTypes = {
	doesNewRotationExist: PropTypes.bool,
	areRotationsPresentForNextDay: PropTypes.bool,
	areRotationsPresentForNextWeek: PropTypes.bool,
	children: PropTypes.node,
	requireNewRotationAssignment: PropTypes.bool,
};
export default CalendarContainer;
