import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import GeneralButton from "../../../components/GeneralButton";
import { BUTTON_TYPE } from "../../../constants";
import MESSAGE_STRINGS from "../../../constants/en-us";

function GeneralConfigFooter({
	onPreviousClick,
	onNextClick,
	onClickSave = () => null,
	isSaveEnabled = false,
	hideBackButton = false,
	nextButtonText = "Next",
	showSave = false,
	isSaveButtonEnabled = true,
	showNextButton = true,
}) {
	return (
		<Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={3}>
			{hideBackButton ? null : (
				<GeneralButton
					text="Previous"
					type={BUTTON_TYPE.SECONDARY}
					onClick={onPreviousClick}
					dataTestId="general-button-previous"
				/>
			)}
			{showNextButton && (
				<GeneralButton
					text={nextButtonText}
					onClick={onNextClick}
					disabled={isSaveEnabled}
					dataTestId="general-button-next"
					type={showSave ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY}
				/>
			)}
			{showSave && (
				<GeneralButton
					text={MESSAGE_STRINGS["FGC.save"]}
					disabled={isSaveButtonEnabled}
					onClick={onClickSave}
					dataTestId="general-button-save"
				/>
			)}
		</Box>
	);
}

export default GeneralConfigFooter;

GeneralConfigFooter.propTypes = {
	onPreviousClick: PropTypes.func,
	onNextClick: PropTypes.func,
	onClickSave: PropTypes.func,
	isSaveEnabled: PropTypes.bool,
	hideBackButton: PropTypes.bool,
	nextButtonText: PropTypes.string,
	showSave: PropTypes.bool,
	isSaveButtonEnabled: PropTypes.bool,
	showNextButton: PropTypes.bool,
};
