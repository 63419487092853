import React from "react";
import PropTypes from "prop-types";
import { IconButton, styled } from "@mui/material";
import DownArrow from "../../../assets/img/menuDownArrow.svg";

const ExpandArrow = styled(DownArrow, {
	shouldForwardProp: (prop) => prop !== "isExpanded" && prop !== "isDisabled",
})(({ theme, isExpanded, isDisabled }) => ({
	width: "1rem",
	height: "0.55rem",
	transform: isExpanded ? "rotate(180deg)" : "auto",
	"& path": {
		fill: isDisabled
			? theme.palette.background.darkGrey
			: theme.palette.common.white,
	},
}));
function ExpandCell({ row }) {
	return (
		row.depth === 0 && (
			<IconButton
				onClick={row.getToggleExpandedHandler()}
				disabled={!row.getCanExpand()}
				data-testid="shift-expand-icon"
			>
				<ExpandArrow
					className="shift-expanded-up"
					isExpanded={row.getCanExpand() && row.getIsExpanded()}
					isDisabled={!row.getCanExpand()}
				/>
			</IconButton>
		)
	);
}

ExpandCell.propTypes = {
	row: PropTypes.shape({
		getToggleExpandedHandler: PropTypes.func,
		canExpand: PropTypes.bool,
		getIsExpanded: PropTypes.func,
		getCanExpand: PropTypes.func,
		depth: PropTypes.number,
	}),
};

export default ExpandCell;
