/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import {
	IconButton,
	Box,
	Table,
	TableRow,
	TableHead,
	TableCell,
	styled,
	TableBody,
	MenuItem,
} from "@mui/material";
import PlusIcon from "../../../../assets/img/plusInsideCircle.svg";
import DustBin from "../../../../assets/img/dustBin.svg";

import { StyledSubHeading } from "../styles";
import GeneralDropdown from "../../../../components/GeneralDropdown";
import MESSAGE_STRINGS from "../../../../constants/en-us";

import GeneralTextField from "../../../../components/GeneralTextField";
import TimePicker from "../../../../components/TimePicker";
import {
	ALPHANUMERIC_REGEX,
	DROPDOWN_CONSTANTS,
	SHIFT_EDIT_REDUCER_CONSTANTS,
	TOAST_REDUCER_CONSTANTS,
} from "../../../../constants";
import InlineErrorMessages from "../../../../components/InlineErrorMessages";
import { isCalendarDay1Disabled } from "../helpers";
import {
	closeRecentModal,
	pushModal,
	useGeneralModalDispatchContext,
} from "../../../../context/generalModalContext";
import { ShiftEditProvider } from "../shiftEditContext";
import DeleteShiftModal from "./DeleteShiftModal";
import { useToastContext } from "../../../../context/toastContext";

const HeaderCell = styled(TableCell)(({ theme }) => ({
	background: theme.palette.background.darkishBlackBlue,
	padding: ".96875rem 0.5rem",
	color: theme.palette.text.lightUnitGrey,
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));

const TableBodyRow = styled(TableRow)(() => ({
	minHeight: "3rem",
}));
const TableBodyCell = styled(TableCell)(({ theme }) => ({
	padding: "0.5rem 0.5rem",
	height: "100%",
	verticalAlign: "top",
	borderBottom: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
}));
const columnHelper = createColumnHelper();
const onBreakFieldBlur = (id, visible, type, dispatch) => {
	if (visible.dirty)
		dispatch({
			type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_FIELD_STATE,
			payload: {
				id,
				newFieldState: { ...visible, blur: true },
				fieldType: type,
			},
		});
};
function DaySelectField({
	type = "startsOn",
	getValue,
	row,
	dispatch,
	column,
}) {
	const visible = row.original.fieldState[type];
	useEffect(() => {
		if (row.original.isShowError && !visible.blur)
			dispatch({
				type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_FIELD_STATE,
				payload: {
					id: row.original.id,
					newFieldState: { dirty: true, blur: true },
					fieldType: type,
				},
			});
	}, [dispatch, row.original, type, visible.blur]);
	return (
		<Box maxWidth={200} width="100%">
			<GeneralDropdown
				id={column.id + row.original.id}
				fullWidth
				error={visible.blur && !getValue()}
				value={getValue() || DROPDOWN_CONSTANTS.DEFAULT_VALUE}
				onBlur={() =>
					onBreakFieldBlur(row.original.id, visible, type, dispatch)
				}
				onChange={(e) => {
					dispatch({
						type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_DAY_SELECTION,
						payload: {
							fieldType: type,
							value: e.target.value,
							id: row.original.id,
						},
					});
				}}
				placeholderText="Select"
			>
				<MenuItem
					value="sameDay"
					disabled={isCalendarDay1Disabled(type, row.original.startsOn)}
				>
					{MESSAGE_STRINGS["Schedule.endsOn.sameDay"]}
				</MenuItem>
				<MenuItem value="nextDay">
					{MESSAGE_STRINGS["Schedule.endsOn.nextDay"]}
				</MenuItem>
			</GeneralDropdown>
			{visible.blur && !getValue() && (
				<InlineErrorMessages
					width="100%"
					message={MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"]}
					marginTop="0.25rem"
				/>
			)}
		</Box>
	);
}
function BreakTimeField({
	dispatch,
	cell: { id },
	getValue,
	fieldType,
	row,
	column,
	index,
}) {
	const visible = row.original.fieldState[fieldType];
	const isStartTime = fieldType === "renderStartTime";
	const { isError, errorText, time } = isStartTime
		? row.original.startTime
		: row.original.endTime;

	useEffect(() => {
		if (row.original.isShowError && !visible.blur)
			dispatch({
				type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_FIELD_STATE,
				payload: {
					id: row.original.id,
					newFieldState: { dirty: true, blur: true },
					fieldType,
				},
			});
	}, [dispatch, fieldType, row.original, visible.blur]);
	return (
		<Box
			width="100%"
			maxWidth={160}
			display="flex"
			flexWrap="wrap"
			id={`${id}-${column.id}-${row.original.id}`}
		>
			<TimePicker
				timeString={time}
				id={`${index}-${column.id}-${row.original.id}`}
				textFieldPlaceHolder="HH:MM"
				value={getValue()}
				handleChange={(dateValue, text) => {
					dispatch({
						type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_TIMING,
						payload: {
							enteredText: text,
							value: dateValue,
							fieldType,
							id: row.original.id,
						},
					});
				}}
				isError={visible.blur && isError}
				onBlur={() =>
					onBreakFieldBlur(row.original.id, visible, fieldType, dispatch)
				}
			/>
			{visible.blur && isError && (
				<InlineErrorMessages
					width="100%"
					message={errorText}
					marginTop="0.25rem"
				/>
			)}
		</Box>
	);
}
function DeleteButton({ dispatch, row, deleteAPI }) {
	const modalDispatch = useGeneralModalDispatchContext();
	const toastData = useToastContext();
	const handleDelete = (id) => {
		dispatch({
			type: SHIFT_EDIT_REDUCER_CONSTANTS.REMOVE_BREAK,
			payload: { id },
		});
	};
	return (
		<IconButton
			onClick={() => {
				pushModal(
					modalDispatch,
					<ShiftEditProvider>
						<DeleteShiftModal
							actionType="DELETE_BREAK"
							onCancelClick={() => {
								closeRecentModal(modalDispatch);
							}}
							onSave={() => {
								if (row.original.isLocal) {
									handleDelete(row.original.id);
									closeRecentModal(modalDispatch);
									return;
								}
								deleteAPI(row.original)
									.then(() => {
										toastData?.toastDispatch({
											type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
											payload: {
												message: `Successfully deleted break`,
											},
										});
										handleDelete(row.original.id);
										closeRecentModal(modalDispatch);
									})
									.catch((axObj) => {
										toastData?.toastDispatch({
											type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
											payload: {
												message: axObj?.response?.data?.message || "error!",
											},
										});
										closeRecentModal(modalDispatch);
									});
							}}
						/>
					</ShiftEditProvider>
				);
			}}
			sx={{ paddingY: ".25rem" }}
			data-testid="delete-break"
		>
			<DustBin />
		</IconButton>
	);
}
function BreakNameField({ getValue, row, dispatch }) {
	const visible = row.original.fieldState.name;
	useEffect(() => {
		if (row.original.isShowError && !visible.blur)
			dispatch({
				type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_FIELD_STATE,
				payload: {
					id: row.original.id,
					newFieldState: { dirty: true, blur: true },
					fieldType: "name",
				},
			});
	}, [dispatch, row.original, visible.blur]);
	const errorMessage = row.original.nameError;
	return (
		<Box width="100%" maxWidth={160} display="flex" flexWrap="wrap">
			<GeneralTextField
				isPlaceholderItalic={false}
				placeholder="Name"
				value={getValue()}
				onChange={(e) => {
					const newValue = e.target.value;
					if (newValue.match(ALPHANUMERIC_REGEX)) {
						dispatch({
							type: SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_NAME,
							payload: {
								value: e.target.value,
								id: row.original.id,
							},
						});
					}
				}}
				onBlur={() =>
					onBreakFieldBlur(row.original.id, visible, "name", dispatch)
				}
				error={visible.blur && !!errorMessage}
				maxLength={255}
			/>
			{visible.blur && !!errorMessage && (
				<InlineErrorMessages
					width="100%"
					message={errorMessage}
					marginTop="0.25rem"
				/>
			)}
		</Box>
	);
}
const getColumns = (dispatch, deleteAPI) => [
	columnHelper.accessor("name", {
		id: "name",
		header: "Name",
		width: "18.31%",
		cell: (info) => <BreakNameField {...info} dispatch={dispatch} />,
	}),
	columnHelper.accessor("startsOn", {
		id: "startsOn",
		header: "Starts On",
		width: "18.75%",
		cell: (info) => <DaySelectField dispatch={dispatch} {...info} />,
	}),
	columnHelper.accessor("renderStartTime", {
		id: "startTime",
		header: "Start Time",
		width: "18.22%",
		cell: (info) => (
			<BreakTimeField
				{...info}
				dispatch={dispatch}
				fieldType="renderStartTime"
			/>
		),
	}),
	columnHelper.accessor("endsOn", {
		id: "endsOn",
		header: "Ends On",
		width: "18.75%",
		cell: (info) => (
			<DaySelectField dispatch={dispatch} {...info} type="endsOn" />
		),
	}),
	columnHelper.accessor("renderEndTime", {
		id: "endTime",
		header: "End Time",
		width: "18.22%",
		cell: (info) => (
			<BreakTimeField {...info} dispatch={dispatch} fieldType="renderEndTime" />
		),
	}),
	columnHelper.accessor("action", {
		id: "action",
		header: "Action",
		cell: (info) => (
			<DeleteButton {...info} dispatch={dispatch} deleteAPI={deleteAPI} />
		),
	}),
];
function BreakSelection({ breaks = [], dispatch, deleteAPI }) {
	const columns = useMemo(
		() => getColumns(dispatch, deleteAPI),
		[dispatch, deleteAPI]
	);
	const instance = useReactTable({
		data: breaks,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<Box marginBottom="1rem">
			<Box display="flex" alignItems="center">
				<StyledSubHeading>Breaks</StyledSubHeading>
				<IconButton
					onClick={() => {
						dispatch({
							type: SHIFT_EDIT_REDUCER_CONSTANTS.ADD_BREAK,
						});
					}}
					data-testid="add-break"
				>
					<PlusIcon height="1.5rem" width="1.5rem" />
				</IconButton>
			</Box>
			{instance.getRowModel().rows.length ? (
				<BreaksTable instance={instance} />
			) : null}
		</Box>
	);
}

function BreaksTable({ instance }) {
	return (
		<Table
			stickyHeader
			aria-label="table"
			className="smf-table"
			sx={{
				border: (theme) =>
					`.0625rem solid ${theme.palette.background.wrapperGrey}`,
				borderRadius: "0.25rem",
			}}
		>
			{instance.getHeaderGroups().map((headerGroup) => (
				<TableHead key={headerGroup.id}>
					<TableRow>
						{headerGroup.headers.map((header) => (
							<HeaderCell key={header.id}>
								{flexRender(
									header.column.columnDef.header,
									header.getContext()
								)}
							</HeaderCell>
						))}
					</TableRow>
				</TableHead>
			))}
			<TableBody>
				{instance.getRowModel().rows.map((row) => {
					return (
						<TableBodyRow tabIndex={-1} key={row.id}>
							{row.getVisibleCells().map((cell) => {
								return (
									<TableBodyCell
										key={cell.id}
										align={cell.align}
										sx={{ width: cell.column.columnDef.width }}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableBodyCell>
								);
							})}
						</TableBodyRow>
					);
				})}
			</TableBody>
		</Table>
	);
}
BreaksTable.propTypes = {
	instance: PropTypes.shape({
		getHeaderGroups: PropTypes.func,
		getRowModel: PropTypes.func,
	}),
};
BreakSelection.propTypes = {};

export default BreakSelection;
