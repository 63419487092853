import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { format } from "date-fns";

const getTimeValue = (time, timeFormat = "HH:mm") => {
	try {
		return format(time, timeFormat);
	} catch (e) {
		return "";
	}
};

function ShiftTimeCell({ getValue }) {
	return <Typography>{getTimeValue(getValue())}</Typography>;
}

ShiftTimeCell.propTypes = { getValue: PropTypes.func };

export default ShiftTimeCell;
