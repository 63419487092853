import React from "react";
import { Box, Backdrop, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { COMPONENT_ID } from "../../constants";

const CustomBox = styled(Box)(({ theme }) => ({
	color: theme.palette.background.default,
	textAlign: "center",
}));

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
	zIndex: theme.zIndex.drawer - 1,
}));

export default function LoadingIndicator({ size }) {
	return (
		<>
			<CustomBackdrop invisible open />
			<CustomBox>
				<CircularProgress
					color="inherit"
					size={size}
					data-testid={COMPONENT_ID.LOADER_TEST_ID}
				/>
			</CustomBox>
		</>
	);
}

LoadingIndicator.propTypes = {
	size: PropTypes.number,
};
