import { IconButton, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import PropTypes from "prop-types";
import MESSAGE_STRINGS from "../../constants/en-us";
import LegendItem from "../LegendItem";
import PlusIcon from "../../assets/img/plusInsideCircle.svg";
import { SHIFT_TEST_ID } from "../../containers/Schedule/ScheduleConstants";
import GeneralTooltip from "../GeneralTooltip";

const StyledConfigShiftsContainer = styled("div")(({ theme }) => ({
	color: "white",
	"& .configshifts-header": {
		display: "flex",
		justifyContent: "space-between",
		paddingTop: "1rem",
		paddingBottom: "1.375rem",
		backgroundColor: theme.palette.background.darkishBlackBlue,
		alignItems: "center",
		marginTop: "1rem",
		"& .legenditem-container:not(:last-child)": {
			marginRight: "1.5rem",
		},
		border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
		borderBottom: "none",
		borderRadius: "0.25rem 0.25rem 0 0",
	},
	"& .configshifts-addshift-btn": {
		"&.Mui-disabled": {
			filter: `invert(34%) sepia(6%) saturate(408%) hue-rotate(175deg) brightness(91%) contrast(87%)`,
			cursor: "not-allowed",
			pointerEvents: "all",
		},
	},
	"& .configshifts-addshifts": {
		marginLeft: "0.875rem",
		display: "flex",
		alignItems: "center",
	},
	"& .configshifts-legends": {
		display: "flex",
		flexDirection: "row",
		paddingRight: "1.875rem",
	},
	"& .configshifts-configtablecontainer": {
		display: "flex",
		border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
		borderRadius: "0 0 0.25rem 0.25rem",
	},
	"& .configshifts-configtable": {
		display: "flex",
		flexBasis: "36%",
		minWidth: "36%",
	},
	"& .configshifts-timelineview": {
		display: "flex",
		flexBasis: "64%",
		maxWidth: "64%",
	},
}));

function customFormat(date) {
	try {
		return `(${format(date, "P")})`;
	} catch {
		return "";
	}
}

function ConfigShifts({
	productionDayStart,
	productionDayEnd,
	hideLegends = false,
	configShiftsFormComponent,
	timeLineComponent,
	onClickCreateNewShift = () => null,
}) {
	const startSubLabel = customFormat(productionDayStart);
	const endSubLabel = customFormat(productionDayEnd);
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<StyledConfigShiftsContainer>
			<header className="configshifts-header">
				<div className="configshifts-addshifts">
					<Typography
						variant="h6"
						data-testid={SHIFT_TEST_ID.SHIFTS_LABEL}
						className="configshifts-addshifts-text"
					>
						Shifts
					</Typography>
					<GeneralTooltip
						message="Add Shift"
						onClose={handleClose}
						onOpen={handleOpen}
						placement="top"
						open={open}
					>
						<IconButton
							className="configshifts-addshift-btn"
							onClick={() => {
								handleClose();
								onClickCreateNewShift();
							}}
							data-testid={SHIFT_TEST_ID.ADD}
						>
							<PlusIcon />
						</IconButton>
					</GeneralTooltip>
				</div>
				<div className="configshifts-legends">
					{!hideLegends && (
						<>
							<LegendItem
								colorCode="background.primrose"
								mainLabel={
									MESSAGE_STRINGS[
										"Schedule.configShifts.legends.productionDayStart"
									]
								}
								data-testid={SHIFT_TEST_ID.LEGEND_START}
								subLabel={startSubLabel}
							/>
							<LegendItem
								colorCode="background.default"
								mainLabel={
									MESSAGE_STRINGS[
										"Schedule.configShifts.legends.calendarChangeTime"
									]
								}
							/>
							<LegendItem
								colorCode="background.pantone"
								mainLabel={
									MESSAGE_STRINGS[
										"Schedule.configShifts.legends.productionDayEndTime"
									]
								}
								subLabel={endSubLabel}
								data-testid={SHIFT_TEST_ID.LEGEND_END}
							/>
						</>
					)}
				</div>
			</header>
			<section className="configshifts-configtablecontainer">
				<div className="configshifts-configtable">
					{configShiftsFormComponent}
				</div>
				<div className="configshifts-timelineview">{timeLineComponent}</div>
			</section>
		</StyledConfigShiftsContainer>
	);
}

ConfigShifts.propTypes = {
	productionDayStart: PropTypes.instanceOf(Date),
	productionDayEnd: PropTypes.instanceOf(Date),
	hideLegends: PropTypes.bool,
	configShiftsFormComponent: PropTypes.node,
	timeLineComponent: PropTypes.node,
	onClickCreateNewShift: PropTypes.func,
};

export default ConfigShifts;
