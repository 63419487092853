import { add, compareAsc, isSameDay, isWithinInterval, parse } from "date-fns";
import { countBy, some } from "lodash";
import { ENDS_ON } from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";
import { ENDS_ON_VALUES } from "./ScheduleConstants";

export default {};

export function validateDuplicateShiftName(values) {
	const counts = countBy(values, "shiftName");
	return values.map((data) => {
		let errorText = "";
		if (!data.shiftName || data.shiftName.length === 0) {
			errorText = MESSAGE_STRINGS["Schedule.Error.requiredField"];
		} else if (counts[data.shiftName] > 1 && data.shiftName.length !== 0) {
			errorText = MESSAGE_STRINGS["ERROR_MESSAGES.mustBeUnique"];
		}
		return {
			...data,
			shiftNameIsError: !!errorText,
			shiftNameErrorMsg: errorText,
		};
	});
}

export function generateEndsOn({
	shiftStartTime,
	shiftEndTime,
	isProdSameDay,
}) {
	if (isProdSameDay) {
		return ENDS_ON.SAME_DAY;
	}
	if (!isSameDay(shiftStartTime, shiftEndTime)) {
		return ENDS_ON.OVERLAPPING;
	}
	return ENDS_ON.NEXT_DAY;
}
function getStartsOnEndsOn(endsOn) {
	if (endsOn === "overlapping") {
		return { startsOn: "sameDay", endsOn: "nextDay" };
	}
	if (endsOn === "nextDay") {
		return { startsOn: "nextDay", endsOn: "nextDay" };
	}

	return { startsOn: "sameDay", endsOn: "sameDay" };
}
const getFormattedTimes = (startsOn, endsOn, item) => {
	const tempStartTime = add(parse(item.startTime, "HH:mm", new Date()), {
		hours: 0,
	});
	const formattedStartTime = add(tempStartTime, {
		hours: startsOn !== "sameDay" ? 24 : 0,
	});

	const tempEndTime = add(parse(item.endTime, "HH:mm", new Date()), {
		hours: 0,
	});

	const formattedEndTime = add(tempEndTime, {
		hours: endsOn !== "sameDay" ? 24 : 0,
	});
	return { tempEndTime, tempStartTime, formattedStartTime, formattedEndTime };
};
const formatBreaks = (
	breaks = [],
	parentId = null,
	type = "",
	mainShiftId = null
) => {
	return breaks.map((br) => {
		const { startsOn, endsOn } = getStartsOnEndsOn(br.endsOn);
		const { tempEndTime, tempStartTime, formattedStartTime, formattedEndTime } =
			getFormattedTimes(startsOn, endsOn, br);
		return {
			id: br.id,
			name: br.name || "",
			startTime: {
				time: br.startTime,
				isError: false,
				errorText: "",
			},
			endTime: {
				time: br.endTime,
				isError: false,
				errorText: "",
			},
			startsOn,
			endsOn,
			type: type === "shift" ? "shiftBreak" : "subShiftBreak",
			formattedStartTime,
			renderStartTime: tempStartTime,
			formattedEndTime,
			renderEndTime: tempEndTime,
			isNew: false,
			isUpdated: false,
			parentId,
			mainShiftId,
			fieldState: {
				renderStartTime: {
					blur: false,
					dirty: false,
				},
				name: {
					blur: false,
					dirty: false,
				},
				renderEndTime: {
					blur: false,
					dirty: false,
				},
				startsOn: {
					blur: false,
					dirty: false,
				},
				endsOn: {
					blur: false,
					dirty: false,
				},
			},
		};
	});
};
const formatSelectedHierarchy = (hierarchy) => {
	const selectedHierarchy = {};
	hierarchy?.forEach((key) => {
		selectedHierarchy[key] = true;
		return null;
	});
	return selectedHierarchy;
};
export function formatShifts({
	shifts = [],
	prodStartTime,
	isProdSameDay,
	type = "shift",
	parentId = null,
}) {
	const newShifts = [];

	shifts.forEach((shift, index) => {
		const { startsOn, endsOn } = getStartsOnEndsOn(shift.endsOn);
		const { tempEndTime, tempStartTime, formattedStartTime, formattedEndTime } =
			getFormattedTimes(startsOn, endsOn, shift);
		const id = shift.shiftID || shift.id;
		newShifts.push({
			id,
			shiftId: id,
			shiftName: shift.shiftName || shift.name || "",
			shiftNameErrorMsg: "",
			startTime: {
				time: shift.startTime,
				isError: false,
				errorText: "",
			},
			endTime: {
				time: shift.endTime,
				isError: false,
				errorText: "",
			},
			...getStartsOnEndsOn(shift.endsOn),
			formattedStartTime,
			renderStartTime: tempStartTime,
			formattedEndTime,
			renderEndTime: tempEndTime,
			isNew: false,
			isUpdated: false,
			index,
			isExtraRow: false,
			type,
			selectedHierarchy: formatSelectedHierarchy(shift.entityId),
			breaks: formatBreaks(shift.breaks, id, type, parentId),
			parentId,
			subShifts: formatShifts({
				shifts: shift.subShifts || [],
				prodStartTime,
				isProdSameDay,
				type: "subShift",
				parentId: id,
			}),
		});
	});
	return newShifts;
}

const isSameProductionDay = (endsOn) =>
	endsOn === "sameDay" ? ENDS_ON_VALUES.SAME_DAY : ENDS_ON_VALUES.NEXT_DAY;

export function formatProductionDay(prodDay) {
	let productionDayDetails = {};
	const endsOn = prodDay.endsOn || null;
	const formattedStartTime = add(
		parse(prodDay.startTime, "HH:mm", new Date()),
		{
			hours: 0,
		}
	);
	const formattedEndTime = add(parse(prodDay.endTime, "HH:mm", new Date()), {
		hours: endsOn === ENDS_ON.NEXT_DAY ? 24 : 0,
	});
	productionDayDetails = {
		...prodDay,
		startTime: {
			value: prodDay.startTime || null,
			isError: false,
			errorText: "",
			formattedTime: formattedStartTime || undefined,
			renderTime: parse(prodDay.startTime, "HH:mm", new Date()),
		},
		endTime: {
			value: prodDay.endTime || null,
			isError: false,
			errorText: "",
			formattedTime: formattedEndTime,
			renderTime: parse(prodDay.endTime, "HH:mm", new Date()),
		},
		timezone: {
			value: prodDay.timezone || null,
			zone: prodDay.timezone || null,
			isError: false,
			errorText: "",
		},
		productionDayEndsOn: {
			value: !endsOn ? null : isSameProductionDay(endsOn),
			isError: false,
			errorText: "",
			isEndsOnEmpty: !endsOn, // TO Display placeholder
			isSameDay: !endsOn ? true : endsOn === ENDS_ON.SAME_DAY,
		},
		isUpdated: false,
		isSameDay: endsOn === "" ? true : endsOn === ENDS_ON.SAME_DAY,
		isEndsOnEmpty: endsOn === "", // TO Display placeholder
	};
	return productionDayDetails;
}

export function compareTimeHHMM(time1, time2) {
	const date = new Date();
	const formattedTime1 = parse(time1, "HH:mm", date);
	const formattedTime2 = parse(time2, "HH:mm", date);

	return compareAsc(formattedTime1, formattedTime2);
}

// bhargavi's code
export function getShiftsAfterProductionDayChanges(
	shifts,
	productionDayStartTime
) {
	return shifts.map((shift) => ({
		...shift,
		// Change the formattedStartTime of existing shifts based on change in production day timings
		formattedStartTime: add(parse(shift.startTime.time, "HH:mm", new Date()), {
			hours:
				compareTimeHHMM(shift.startTime.time, productionDayStartTime.value) ===
				-1
					? 24
					: 0,
		}),
		// Change the formattedEndTime of existing shifts based on change in production day timings
		formattedEndTime: add(parse(shift.endTime.time, "HH:mm", new Date()), {
			hours:
				compareTimeHHMM(shift.endTime.time, productionDayStartTime.value) < 1
					? 24
					: 0,
		}),
	}));
}

/**
 * Function to check isUpdated exists on state
 * @param {Object} state
 * @param {*} id
 * @returns true/false
 */
export const isAnyUpdatedStateExists = (state = {}, id = "") =>
	state.productionDayStartTime.isUpdated ||
	state.productionDayEndTime.isUpdated ||
	state.productionDayEndsOn.isUpdated ||
	state.productionDayTimezone.isUpdated ||
	some(
		state.shifts.filter((shift) => shift.shiftId !== id),
		(shift) =>
			shift.isUpdated ||
			some(
				shift?.subShifts?.filter((s) => s.id !== id),
				(s) => s.isUpdated
			)
	);

export const isShiftNull = (shift) =>
	shift.startTime.time === null ||
	shift.endTime.time === null ||
	shift.shiftName === null;

export const isShiftError = (shift) =>
	(shift.startTime.isError && shift.startTime.time !== null) ||
	(shift.endTime.isError && shift.endTime.time !== null) ||
	(shift.shiftNameIsError && shift.shiftName !== null);

export const isShiftErrorOnNext = (shift) =>
	shift.startTime.isError || shift.endTime.isError || shift.shiftNameIsError;

export const calculateProdDayFromShifts = (state, newShifts) => {
	const lowerBoundTime = newShifts.reduce((prev, curr) =>
		prev?.formattedStartTime < curr.formattedStartTime ? prev : curr
	);
	const upperBoundTime = newShifts.reduce((prev, curr) =>
		prev?.formattedEndTime > curr.formattedEndTime ? prev : curr
	);

	const defaultValue = { isError: false, errorText: "" };
	const newProdEndTime = { ...state.productionDayEndTime, ...defaultValue };
	const newProdStartTime = {
		...state.productionDayStartTime,
		...defaultValue,
	};
	const newProdEndsOn = { ...state.productionDayEndsOn, ...defaultValue };
	if (
		!(
			compareAsc(
				lowerBoundTime.formattedStartTime,
				state.productionDayStartTime.formattedTime
			) === 0 &&
			compareAsc(
				upperBoundTime.formattedEndTime,
				state.productionDayEndTime.formattedTime
			) === 0
		)
	) {
		newProdStartTime.formattedTime = lowerBoundTime.formattedStartTime;
		newProdStartTime.renderTime = lowerBoundTime.renderStartTime;
		newProdStartTime.value = lowerBoundTime.startTime.time;
		newProdStartTime.isError = false;
		newProdStartTime.errorText = "";
		newProdStartTime.isUpdated = true;

		newProdEndTime.formattedTime = upperBoundTime.formattedEndTime;
		newProdEndTime.renderTime = upperBoundTime.renderEndTime;
		newProdEndTime.value = upperBoundTime.endTime.time;
		newProdEndTime.isError = false;
		newProdEndTime.errorText = "";
		newProdEndTime.isUpdated = true;

		newProdEndsOn.value = isSameProductionDay(upperBoundTime.endsOn);
		newProdEndsOn.isError = false;
		newProdEndsOn.errorText = "";
		newProdEndsOn.isUpdated = true;
		newProdEndsOn.isEndsOnEmpty = false;
	}

	return { newProdEndTime, newProdEndsOn, newProdStartTime };
};

export const validateSubShiftMapping = (newShifts) => {
	try {
		const isSubShiftMappingError = newShifts.some((s) => {
			const mainShiftInterval = {
				start: s.formattedStartTime,
				end: s.formattedEndTime,
			};

			return s.subShifts.some(
				(subShift) =>
					!(
						isWithinInterval(subShift.formattedStartTime, mainShiftInterval) &&
						isWithinInterval(subShift.formattedEndTime, mainShiftInterval)
					)
			);
		});
		return isSubShiftMappingError
			? "Sub Shift Timings are not within shift timings please update"
			: "";
	} catch {
		return "";
	}
};

export const validateShiftMappingForProdDay = (
	startTime,
	endTime,
	newShifts
) => {
	try {
		const prodDayInterval = {
			start: startTime.formattedTime,
			end: endTime.formattedTime,
		};

		const isShiftMappingError = newShifts.some(
			(s) =>
				!(
					isWithinInterval(s.formattedStartTime, prodDayInterval) &&
					isWithinInterval(s.formattedEndTime, prodDayInterval)
				)
		);
		return isShiftMappingError
			? MESSAGE_STRINGS["Schedule.ShiftTimeError.boundError"]
			: "";
	} catch {
		return "";
	}
};

const getBreakObj = (br) => {
	return {
		name: (br.name || "").trim(),
		startTime: br.startTime.time,
		endTime: br.endTime.time,
		startsOn: br.startsOn,
		endsOn: br.endsOn,
	};
};
const getSelectedEntityIds = (selectedHierarchy = {}) => {
	return Object.keys(selectedHierarchy)
		.filter((key) => selectedHierarchy[key] === true)
		.map((key) => Number(key));
};
export const getItemsToBeUpdated = (state) => {
	const itemsToBeUpdated = [];
	state.shifts.forEach((shift) => {
		if (shift.isUpdated && !shift.isLocal) {
			itemsToBeUpdated.push({
				itemId: shift.id,
				name: (shift.shiftName || "").trim(),
				startTime: shift.startTime.time,
				endTime: shift.endTime.time,
				startsOn: shift.startsOn,
				endsOn: shift.endsOn,
				type: shift.type,
				entityId: getSelectedEntityIds(shift.selectedHierarchy),
			});
		}
		shift.breaks
			?.filter((br) => br.isUpdated && !br.isLocal)
			.forEach((br) => {
				itemsToBeUpdated.push({
					itemId: br.id,
					...getBreakObj(br),
					parentId: shift.id,
					type: "shiftBreak",
				});
			});
		shift.subShifts?.forEach((subShift) => {
			if (subShift.isUpdated && !subShift.isLocal) {
				itemsToBeUpdated.push({
					itemId: subShift.id,
					name: (subShift.shiftName || "").trim(),
					startTime: subShift.startTime.time,
					endTime: subShift.endTime.time,
					startsOn: subShift.startsOn,
					endsOn: subShift.endsOn,
					type: subShift.type,
					parentId: shift.id,
					entityId: getSelectedEntityIds(subShift.selectedHierarchy),
				});
			}
			subShift.breaks
				?.filter((br) => br.isUpdated && !br.isLocal)
				.forEach((br) => {
					itemsToBeUpdated.push({
						itemId: br.id,
						...getBreakObj(br),
						type: "subShiftBreak",
						parentId: subShift.id,
					});
				});
		});
	});
	return itemsToBeUpdated;
};
const getSubShiftCreationObj = (subShift) => {
	return {
		name: (subShift.shiftName || "").trim(),
		startTime: subShift.startTime.time,
		endTime: subShift.endTime.time,
		startsOn: subShift.startsOn,
		endsOn: subShift.endsOn,
		type: subShift.type,
		breaks: subShift.breaks?.map((br) => ({
			...getBreakObj(br),
			type: "subShiftBreak",
		})),
		entityId: getSelectedEntityIds(subShift.selectedHierarchy),
	};
};
export const getItemsToBeCreated = (state) => {
	const itemsToBeCreated = [];
	state.shifts.forEach((shift) => {
		if (!shift.isLocal) {
			shift.breaks
				?.filter((br) => br.isLocal)
				.forEach((br) => {
					itemsToBeCreated.push({
						...getBreakObj(br),
						type: "shiftBreak",
						parentId: shift.id,
					});
				});
			shift.subShifts?.forEach((subShift) => {
				if (subShift.isLocal) {
					itemsToBeCreated.push({
						...getSubShiftCreationObj(subShift),
						parentId: shift.id,
					});
				} else {
					subShift.breaks
						?.filter((br) => br.isLocal)
						.forEach((br) => {
							itemsToBeCreated.push({
								...getBreakObj(br),
								type: "subShiftBreak",
								parentId: subShift.id,
							});
						});
				}
			});
		}
	});
	return itemsToBeCreated;
};

export const getShiftItemsToBeCreated = (state) => {
	return state.shifts
		.filter((shift) => shift.isLocal)
		.map((shift) => ({
			name: (shift.shiftName || "").trim(),
			startTime: shift.startTime.time,
			endTime: shift.endTime.time,
			breaks: shift.breaks?.map((br) => ({
				...getBreakObj(br),
				type: "shiftBreak",
			})),
			subShifts: shift.subShifts?.map(getSubShiftCreationObj),
			startsOn: shift.startsOn,
			endsOn: shift.endsOn,
			type: "shift",
			entityId: getSelectedEntityIds(shift.selectedHierarchy),
		}));
};

export const getShiftsForUpdates = (shift, state) => {
	shift?.subShifts?.forEach((subShift) => {
		const newHierarchy = subShift.selectedHierarchy;
		if (newHierarchy) {
			Object.keys(newHierarchy).forEach((key) => {
				if (!shift?.selectedHierarchy?.[key]) {
					delete newHierarchy[key];
				}
			});
		}
	});
	return state.shifts.map((s) =>
		s.id === shift.id ? { ...s, ...shift } : { ...s }
	);
};

export const getNewShiftsAfterRemovingBreaks = (row, state) => {
	const isSubShift = row.type === "subShiftBreak";
	const newShifts = state.shifts.map((s) => ({ ...s }));
	if (!isSubShift) {
		const changedShift = newShifts.find((s) => s.id === row?.parentId);
		const newBreaks = changedShift?.breaks?.filter((b) => b.id !== row.id);
		changedShift.breaks = newBreaks || [];
	} else {
		const changedShift = newShifts.find((s) => s.id === row.mainShiftId);
		const changedSubShift = changedShift?.subShifts?.find(
			(s) => s.id === row.parentId
		);
		const newBreaks = changedSubShift?.breaks?.filter((b) => b.id !== row.id);
		changedSubShift.breaks = newBreaks || [];
	}
	return newShifts;
};
