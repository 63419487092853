import { config, globalstate$ } from "@smf/ui-util-app";

const WSS_ENDPOINT_FACTORY_GENERAL = `wss://${config.BASE_WS_API_URL}/factorygeneral`;

export function processEquipmentIntelligenceWebSocket(sendData) {
	const authToken = globalstate$.value.sessionToken;
	return new Promise((resolve, reject) => {
		const ws = new WebSocket(
			WSS_ENDPOINT_FACTORY_GENERAL,
			authToken?.replace("Bearer ", "")
		);
		ws.onopen = () => {
			ws.send(
				JSON.stringify({
					action: "processEquipmentIntelligence",
					...sendData,
					// eslint-disable-next-line no-underscore-dangle
					authorizationToken: globalstate$._value.sessionToken,
				})
			);
		};
		ws.onmessage = ({ data }) => {
			const recvData = JSON.parse(data);
			if (recvData.isError !== undefined && recvData.isError === false) {
				resolve(recvData);
			} else reject(recvData);
			ws.close();
		};
		ws.onerror = (err) => {
			reject(err);
		};
	});
}

export default {};
