import Month from "react-big-calendar/lib/Month";
import { Box } from "@mui/material";

function MonthView(props) {
	return (
		<Box sx={{ overflowY: "auto" }} height="100%" display="flex">
			<Month {...props} />
		</Box>
	);
}
MonthView.range = Month.range;
MonthView.navigate = Month.navigate;
MonthView.title = Month.title;

export default MonthView;
