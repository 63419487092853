import { add, format, parse } from "date-fns";
import { countBy, some, uniqueId } from "lodash";
import { DROPDOWN_CONSTANTS } from "../../constants";
import MESSAGE_STRINGS from "../../constants/en-us";
import SCHEDULE_ACTIONS from "./ScheduleActions";
import { ENDS_ON_VALUES } from "./ScheduleConstants";
import {
	calculateProdDayFromShifts,
	formatProductionDay,
	formatShifts,
	getNewShiftsAfterRemovingBreaks,
	getShiftsForUpdates,
	validateDuplicateShiftName,
	validateShiftMappingForProdDay,
	validateSubShiftMapping,
} from "./scheduleHelpers";
import {
	checkShiftBounds,
	validateProdTimings,
	validateShiftTimings,
} from "./ScheduleValidations";

export const scheduleInitialValues = {
	productionDay: null,
	productionDayStartTime: {
		value: null,
		isError: false,
		errorText: "",
		formattedTime: null,
	},
	productionDayEndTime: {
		value: null,
		isError: false,
		errorText: "",
		formattedTime: null,
	},
	productionDayTimezone: { value: null, isError: false, errorText: "" },
	timezoneOptions: [],
	productionDayEndsOn: {
		value: DROPDOWN_CONSTANTS.DEFAULT_VALUE,
		isError: false,
		errorText: "",
	},
	shifts: [],
	showDeleteShiftModal: false,
	showCreateShiftModal: false,
	showUpdateShiftModal: false,
	isAnyShiftsError: false,
	isAnyExistingShiftUpdated: false,
	isFirstTime: false,
	hasStartTimeChanged: false,
};

function boundCheckInShifts(shifts) {
	return shifts.map((shift) => {
		if (!shift.startTime.isError && !shift.endTime.isError) {
			return {
				...shift,
				startTime: {
					...shift.startTime,
				},
				endTime: {
					...shift.endTime,
					...checkShiftBounds({
						formattedStartTime: shift.formattedStartTime,
						formattedEndTime: shift.formattedEndTime,
					}),
				},
			};
		}
		return {
			...shift,
		};
	});
}

function setProdDayStart(newStartTime, state) {
	const newShifts = [...state.shifts].map((shift) => ({ ...shift }));

	const newProductionDayStartTime = {
		...newStartTime,
		formattedTime: parse(newStartTime.value, "HH:mm", new Date()),
		isUpdated: true,
	};

	const {
		newProductionDayStartTime: validatedProductionDayStartTime,
		newProductionDayEndTime: validatedProductionDayEndTime,
		newProductionDayEndsOn: validatedProductionDayEndsOn,
		newTimezone,
	} = validateProdTimings({
		productionDayStartTime: newProductionDayStartTime,
		productionDayEndTime: state.productionDayEndTime,
		productionDayEndsOn: state.productionDayEndsOn,
		timezone: state.productionDayTimezone,
	});

	const isShiftDisabled =
		validatedProductionDayStartTime.value == null ||
		validatedProductionDayStartTime.isError ||
		validatedProductionDayEndTime.value == null ||
		validatedProductionDayEndTime.isError ||
		validatedProductionDayEndsOn.value == null ||
		newTimezone.value == null ||
		newTimezone.isError;

	const isAnyShiftsError = some(newShifts, (shift) => shift.isError);
	return {
		...state,
		productionDayStartTime: validatedProductionDayStartTime,
		productionDayEndTime: validatedProductionDayEndTime,
		productionDayEndsOn: validatedProductionDayEndsOn,
		productionDayTimezone: newTimezone,
		shiftMappingError: validateShiftMappingForProdDay(
			validatedProductionDayStartTime,
			validatedProductionDayEndTime,
			newShifts
		),
		shifts: newShifts.map((shift) => ({
			...shift,
			isDisabled: isShiftDisabled,
		})),
		isAnyShiftsError,
	};
}

function setProdDayEnd(newEndTime, state) {
	const newProductionDayEndTime = {
		...newEndTime,
		value: newEndTime.value,
		formattedTime: add(parse(newEndTime.value, "HH:mm", new Date()), {
			hours:
				state.productionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY ? 0 : 24,
		}),
		isUpdated: true,
	};

	const newShifts = [...state.shifts].map((shift) => ({ ...shift }));

	const {
		newProductionDayStartTime: validatedProductionDayStartTime,
		newProductionDayEndTime: validatedProductionDayEndTime,
		newProductionDayEndsOn: validatedProductionDayEndsOn,
		newTimezone,
	} = validateProdTimings({
		productionDayStartTime: state.productionDayStartTime,
		productionDayEndTime: newProductionDayEndTime,
		productionDayEndsOn: state.productionDayEndsOn,
		timezone: state.productionDayTimezone,
	});

	const isShiftDisabled =
		validatedProductionDayStartTime.value == null ||
		validatedProductionDayStartTime.isError ||
		validatedProductionDayEndTime.value == null ||
		validatedProductionDayEndTime.isError ||
		validatedProductionDayEndsOn.value == null ||
		newTimezone.value == null ||
		newTimezone.isError;

	const isAnyShiftsError = some(newShifts, (shift) => shift.isError);

	return {
		...state,
		productionDayStartTime: validatedProductionDayStartTime,
		productionDayEndTime: validatedProductionDayEndTime,
		productionDayEndsOn: validatedProductionDayEndsOn,
		productionDayTimezone: newTimezone,
		shifts: newShifts.map((shift) => ({
			...shift,
			isDisabled: isShiftDisabled,
		})),
		shiftMappingError: validateShiftMappingForProdDay(
			validatedProductionDayStartTime,
			validatedProductionDayEndTime,
			newShifts
		),
		isAnyShiftsError,
	};
}

function changeShiftNameFunc(id, name, state) {
	const newShifts = state.shifts.map((shift) => ({ ...shift }));
	const changedShift = newShifts.find((shift) => shift.id === id);
	if (changedShift) {
		changedShift.shiftName = (name || "").trimStart();
		changedShift.isUpdated = true;
		const counts = countBy(
			newShifts.map((shift) => ({
				shiftName: shift.shiftName || "",
			})),
			"shiftName"
		);
		newShifts.forEach((shift) => {
			// eslint-disable-next-line no-param-reassign
			shift.shiftNameIsError = false;
			if (!shift.shiftName || shift.shiftName.length === 0) {
				// eslint-disable-next-line no-param-reassign
				shift.shiftNameIsError = true;
				// eslint-disable-next-line no-param-reassign
				shift.shiftNameErrorMsg =
					MESSAGE_STRINGS["Schedule.Error.requiredField"];
			} else if (
				counts[shift.shiftName.trimEnd()] > 1 &&
				shift.shiftName.length !== 0
			) {
				// eslint-disable-next-line no-param-reassign
				shift.shiftNameIsError = true;
				// eslint-disable-next-line no-param-reassign
				shift.shiftNameErrorMsg =
					MESSAGE_STRINGS["ERROR_MESSAGES.mustBeUnique"];
			}
		});
	}

	let { isAnyExistingShiftUpdated } = state;
	if (!isAnyExistingShiftUpdated && !changedShift.isLocal) {
		isAnyExistingShiftUpdated = true;
	}

	const isAnyShiftsError = some(newShifts, (shift) => shift.isError);

	return {
		...state,
		shifts: newShifts,
		isAnyShiftsError,
		isAnyExistingShiftUpdated,
	};
}

function changeShiftStartTime(id, newDate, enteredText, state) {
	const isProdDayEndOnSameDay =
		state.productionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY;

	const newShifts = [...state.shifts].map((shift) => ({ ...shift }));

	const itemToBeUpdated = newShifts.find((shift) => shift.id === id);

	if (itemToBeUpdated) {
		itemToBeUpdated.isUpdated = true;

		const isBeforeProductionStart =
			(enteredText || "00:00").split(":")[0] <
			state.productionDayStartTime.value.split(":")[0];

		itemToBeUpdated.enteredStartTime = enteredText;
		itemToBeUpdated.renderStartTime = newDate;
		itemToBeUpdated.formattedStartTime = add(
			parse(enteredText, "HH:mm", new Date()),
			{
				hours: isBeforeProductionStart && !isProdDayEndOnSameDay ? 24 : 0,
			}
		);

		let result = null;
		try {
			result = format(itemToBeUpdated.formattedStartTime, "HH:mm");

			itemToBeUpdated.startTime.time = result;
		} catch {
			itemToBeUpdated.startTime.time = enteredText;
			//
		}
	}
	const validatedShifts = validateShiftTimings({
		shifts: newShifts,
		prodStartTime: state.productionDayStartTime.value,
		prodEndTime: state.productionDayEndTime.value,
		isSameDay: state.productionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY,
	});

	const boundCheckShifts = boundCheckInShifts(validatedShifts);

	const isAnyShiftsError = some(boundCheckShifts, (shift) => shift.isError);

	let { isAnyExistingShiftUpdated } = state;
	if (!isAnyExistingShiftUpdated && !itemToBeUpdated.isLocal) {
		isAnyExistingShiftUpdated = true;
	}

	return {
		...state,
		shifts: boundCheckShifts,
		isAnyShiftsError,
		isAnyExistingShiftUpdated,
	};
}

function changeShiftEndTime(id, enteredText, newDate, state) {
	const newShifts = state.shifts.map((shift) => ({ ...shift }));
	const isProdDayEndOnSameDay =
		state.productionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY;

	const itemToBeUpdated = newShifts.find((shift) => shift.id === id);

	if (itemToBeUpdated) {
		itemToBeUpdated.isUpdated = true;
		const newDateTimeString = enteredText || "00:00";
		const isBeforeProductionStart =
			newDateTimeString.split(":")[0] <
			state.productionDayStartTime.value.split(":")[0]
				? true
				: newDateTimeString.split(":")[0] ===
						state.productionDayStartTime.value.split(":")[0] &&
				  newDateTimeString.split(":")[1] <=
						state.productionDayStartTime.value.split(":")[1];

		itemToBeUpdated.enteredEndTime = enteredText;
		itemToBeUpdated.renderEndTime = newDate;
		itemToBeUpdated.formattedEndTime = add(
			parse(enteredText, "HH:mm", new Date()),
			{
				hours: isBeforeProductionStart && !isProdDayEndOnSameDay ? 24 : 0,
			}
		);
		let result = null;
		try {
			result = format(itemToBeUpdated.formattedEndTime, "HH:mm");

			itemToBeUpdated.endTime.time = result;
		} catch {
			//
			itemToBeUpdated.endTime.time = enteredText;
		}
	}

	const validatedShifts = validateShiftTimings({
		shifts: newShifts,
		prodStartTime: state.productionDayStartTime.value,
		prodEndTime: state.productionDayEndTime.value,
		isSameDay: state.productionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY,
	});

	const boundCheckShifts = validatedShifts.map((shift) => {
		if (!shift.startTime.isError && !shift.endTime.isError) {
			return {
				...shift,
				endTime: {
					...shift.endTime,
					...checkShiftBounds({
						formattedStartTime: shift.formattedStartTime,
						formattedEndTime: shift.formattedEndTime,
					}),
				},
				startTime: {
					...shift.startTime,
				},
			};
		}
		return {
			...shift,
		};
	});

	const isAnyShiftsError = some(boundCheckShifts, (shift) => shift.isError);

	let { isAnyExistingShiftUpdated } = state;
	if (!isAnyExistingShiftUpdated && !itemToBeUpdated.isLocal) {
		isAnyExistingShiftUpdated = true;
	}

	return {
		...state,
		shifts: boundCheckShifts,
		isAnyShiftsError,
		isAnyExistingShiftUpdated,
	};
}

function changeEndsOnFunc(newEndsOn, state) {
	const newProductionDayEndsOn = { ...state.productionDayEndsOn };
	const newProductionDayEndTime = { ...state.productionDayEndTime };

	newProductionDayEndsOn.isSameDay = newEndsOn === ENDS_ON_VALUES.SAME_DAY;
	newProductionDayEndsOn.isEndsOnEmpty = false;
	newProductionDayEndsOn.errorText = "";
	newProductionDayEndsOn.isError = false;
	newProductionDayEndsOn.isUpdated = true;

	newProductionDayEndsOn.value =
		newEndsOn === ENDS_ON_VALUES.SAME_DAY
			? ENDS_ON_VALUES.SAME_DAY
			: ENDS_ON_VALUES.NEXT_DAY;

	newProductionDayEndTime.formattedTime = add(
		parse(newProductionDayEndTime.value, "HH:mm", new Date()),
		{
			hours: newEndsOn === ENDS_ON_VALUES.SAME_DAY ? 0 : 24,
		}
	);

	const newShifts = [...state.shifts];

	const {
		newProductionDayStartTime,
		newProductionDayEndTime: validatedProductionDayEndTime,
		newProductionDayEndsOn: validatedProductionDayEndsOn,
		newTimezone,
	} = validateProdTimings({
		productionDayStartTime: state.productionDayStartTime,
		productionDayEndTime: newProductionDayEndTime,
		productionDayEndsOn: newProductionDayEndsOn,
		timezone: state.productionDayTimezone,
	});

	const isShiftsDisabled =
		newProductionDayStartTime.value === null ||
		newProductionDayStartTime.isError ||
		validatedProductionDayEndTime.value === null ||
		validatedProductionDayEndTime.isError ||
		validatedProductionDayEndsOn.value === null ||
		newTimezone.value === null ||
		newTimezone.isError;

	const isAnyShiftsError = some(newShifts, (shift) => shift.isError);

	return {
		...state,
		productionDayStartTime: newProductionDayStartTime,
		productionDayEndTime: validatedProductionDayEndTime,
		productionDayTimezone: newTimezone,
		productionDayEndsOn: validatedProductionDayEndsOn,
		shifts: newShifts.map((shift) => ({
			...shift,
			isDisabled: isShiftsDisabled,
		})),
		shiftMappingError: validateShiftMappingForProdDay(
			newProductionDayStartTime,
			validatedProductionDayEndTime,
			newShifts
		),
		isAnyShiftsError,
	};
}
function scheduleReducer(state, action) {
	const { type, payload } = action;

	switch (type) {
		case SCHEDULE_ACTIONS.SET_PRODUCTION_DAY_START: {
			const { newStartTime } = payload;
			return setProdDayStart(newStartTime, state);
		}
		case SCHEDULE_ACTIONS.SET_PRODUCTION_DAY_END: {
			const { newEndTime } = payload;
			return setProdDayEnd(newEndTime, state);
		}

		case SCHEDULE_ACTIONS.SET_PRODUCTION_DAY: {
			const { newProductionDay } = payload;
			return {
				...state,
				productionDay: newProductionDay,
			};
		}

		case SCHEDULE_ACTIONS.SET_SHIFTS: {
			const { newShifts } = payload;
			return {
				...state,
				shifts: newShifts,
				overlapError: "",
			};
		}

		case SCHEDULE_ACTIONS.UPDATE_PRODUCTION_DAY_AND_SHIFTS: {
			const { newProductionDay, newShifts } = payload;
			return {
				...state,
				productionDay: newProductionDay,
				shifts: newShifts,
				overlapError: "",
			};
		}

		case SCHEDULE_ACTIONS.LOAD_DATA: {
			const { productionDay, shifts, timezoneOptions } = payload;

			const prodDayDetails = formatProductionDay(productionDay);

			const newShifts = formatShifts({
				shifts,
				endsOn: productionDay.endsOn,
				prodStartTime: prodDayDetails.startTime.formattedTime,
				prodEndTime: prodDayDetails.endTime.formattedTime,
				isProdSameDay: prodDayDetails.isSameDay,
			});

			const isShiftDisabled =
				prodDayDetails.startTime.time === null ||
				prodDayDetails.startTime.isError ||
				prodDayDetails.endTime.time === null ||
				prodDayDetails.endTime.isError ||
				prodDayDetails.productionDayEndsOn.value === null ||
				prodDayDetails.timezone.value === null ||
				prodDayDetails.timezone.isError;

			let isFirstTime = false;
			if (isShiftDisabled && newShifts.length === 0) {
				isFirstTime = true;
			}

			return {
				...state,
				shifts: [...newShifts].map((shift) => ({
					...shift,
					isDisabled: isShiftDisabled,
				})),
				productionDayStartTime: prodDayDetails.startTime,
				productionDayEndTime: prodDayDetails.endTime,
				productionDayTimezone: prodDayDetails.timezone,
				productionDayEndsOn: prodDayDetails.productionDayEndsOn,
				productionDay: prodDayDetails,
				timezoneOptions,
				isFirstTime,
				oldProductionDay: { ...prodDayDetails },
				hasStartTimeChanged: productionDay.hasStartTimeChanged,
				isAnyExistingShiftUpdated: false,
				overlapError: "",
			};
		}

		case SCHEDULE_ACTIONS.CHANGE_SHIFT_NAME: {
			const { id, name } = action.payload;
			return changeShiftNameFunc(id, name, state);
		}

		case SCHEDULE_ACTIONS.CHANGE_SHIFT_START_TIME: {
			const { id, newDate, enteredText } = action.payload;
			return changeShiftStartTime(id, newDate, enteredText, state);
		}

		case SCHEDULE_ACTIONS.CHANGE_SHIFT_END_TIME: {
			const { id, enteredText, newDate } = action.payload;
			return changeShiftEndTime(id, enteredText, newDate, state);
		}

		case SCHEDULE_ACTIONS.ADD_NEW_SHIFT: {
			const newShifts = [...state.shifts].map((shift) => ({ ...shift }));
			const { addedShift } = payload;
			const uuid = uniqueId("localShift-");
			const newShift = {
				id: uuid,
				shiftName: null,
				startTime: {
					time: null,
					isError: false,
					errorText: "",
				},
				endTime: {
					time: null,
					isError: false,
					errorText: "",
				},
				isNew: true,
				isLocal: true,
				shiftNameErrorMsg: "",
				index: newShifts.length,
				isUpdated: true,
				...addedShift,
			};

			newShifts.push(newShift);
			const { newProdEndTime, newProdEndsOn, newProdStartTime } =
				calculateProdDayFromShifts(state, newShifts);
			return {
				...state,
				shifts: newShifts,
				productionDayEndTime: newProdEndTime,
				productionDayStartTime: newProdStartTime,
				productionDayEndsOn: newProdEndsOn,
				shiftMappingError: validateShiftMappingForProdDay(
					newProdStartTime,
					newProdEndTime,
					newShifts
				),
				overlapError: "",
			};
		}

		case SCHEDULE_ACTIONS.DELETE_LOCAL_SHIFT: {
			const { id, type: shiftType, parentId } = action.payload;
			const { shifts } = state;
			if (shiftType !== "subShift") {
				const newShifts = shifts.filter((shift) => shift.id !== id);
				const validatedNewShifts = validateDuplicateShiftName(
					validateShiftTimings({
						shifts: newShifts,
						prodStartTime: state.productionDayStartTime.value,
						prodEndTime: state.productionDayEndTime.value,
						isSameDay:
							state.productionDayEndsOn.value === ENDS_ON_VALUES.SAME_DAY,
					})
				);
				const isAnyShiftsError = some(
					validatedNewShifts,
					(shift) => shift.isError
				);
				const { newProdEndTime, newProdEndsOn, newProdStartTime } =
					calculateProdDayFromShifts(state, newShifts);
				return {
					...state,
					shifts: [...validatedNewShifts],
					productionDayEndTime: newProdEndTime,
					productionDayStartTime: newProdStartTime,
					productionDayEndsOn: newProdEndsOn,
					shiftMappingError: validateShiftMappingForProdDay(
						newProdStartTime,
						newProdEndTime,
						newShifts
					),
					subShiftMappingError: validateSubShiftMapping(newShifts),
					isAnyShiftsError,
				};
			}
			const newShifts = [...state.shifts].map((shift) => ({ ...shift }));
			const changedShift = newShifts.find((s) => s.id === parentId);
			if (changedShift) {
				const newSubShifts =
					changedShift?.subShifts?.filter((s) => s.id !== id) || [];
				changedShift.subShifts = [...newSubShifts];
			}
			return {
				...state,
				shifts: [...newShifts],
				subShiftMappingError: validateSubShiftMapping(newShifts),
				overlapError: "",
			};
		}
		case SCHEDULE_ACTIONS.UPDATE_SHIFT_STATE: {
			const { shift } = action.payload;
			const newShifts = getShiftsForUpdates(shift, state);
			const { newProdEndTime, newProdEndsOn, newProdStartTime } =
				calculateProdDayFromShifts(state, newShifts);
			return {
				...state,
				shifts: [...newShifts],
				productionDayEndTime: newProdEndTime,
				productionDayStartTime: newProdStartTime,
				productionDayEndsOn: newProdEndsOn,
				subShiftMappingError: validateSubShiftMapping(newShifts),
				shiftMappingError: validateShiftMappingForProdDay(
					newProdStartTime,
					newProdEndTime,
					newShifts
				),
				overlapError: "",
			};
		}

		case SCHEDULE_ACTIONS.CHANGE_ENDS_ON: {
			const { newEndsOn } = action.payload;
			return changeEndsOnFunc(newEndsOn, state);
		}

		case SCHEDULE_ACTIONS.CHANGE_TIME_ZONE: {
			const { newTimezoneValue } = action.payload;

			const newProductionDayTimezone = { ...state.productionDayTimezone };
			const newShifts = [...state.shifts].map((shift) => ({ ...shift }));

			newProductionDayTimezone.value = newTimezoneValue;
			newProductionDayTimezone.zone = newTimezoneValue;
			newProductionDayTimezone.isError = false;
			newProductionDayTimezone.errorText = null;
			newProductionDayTimezone.isUpdated = true;

			const isShiftsDisabled =
				state.productionDayStartTime.value === null ||
				state.productionDayStartTime.isError ||
				state.productionDayEndTime.value === null ||
				state.productionDayEndTime.isError ||
				state.productionDayEndsOn.value === null ||
				newProductionDayTimezone.value === null ||
				newProductionDayTimezone.isError;

			let validatedNewShifts = null;

			validatedNewShifts = newShifts;

			const isAnyShiftsError = some(
				validatedNewShifts,
				(shift) => shift.isError
			);

			return {
				...state,
				productionDayTimezone: newProductionDayTimezone,
				shifts: validatedNewShifts.map((shift) => ({
					...shift,
					isDisabled: isShiftsDisabled,
				})),
				isAnyShiftsError,
			};
		}

		case SCHEDULE_ACTIONS.TRIGGER_SHOW_PRODUCTION_ERRORS: {
			const {
				newProductionDayStartTime,
				newProductionDayEndTime,
				newProductionDayEndsOn,
				newTimezone,
			} = validateProdTimings({
				productionDayStartTime: state.productionDayStartTime,
				productionDayEndTime: state.productionDayEndTime,
				productionDayEndsOn: state.productionDayEndsOn,
				timezone: state.productionDayTimezone,
			});
			return {
				...state,
				productionDayStartTime: newProductionDayStartTime,
				productionDayEndTime: newProductionDayEndTime,
				productionDayEndsOn: newProductionDayEndsOn,
				productionDayTimezone: newTimezone,
			};
		}

		case SCHEDULE_ACTIONS.TRIGGER_EMPTY_SHIFTS_ERROR: {
			const validatedShifts = validateDuplicateShiftName(
				validateShiftTimings({
					shifts: state.shifts,
					prodStartTime: state.productionDayStartTime.value,
					prodEndTime: state.productionDayEndTime.value,
					isSameDay: state.productionDayEndsOn.isSameDay,
				})
			);
			return {
				...state,
				shifts: validatedShifts,
			};
		}
		case SCHEDULE_ACTIONS.ADD_NEW_SUB_SHIFT: {
			const { addedSubShift } = payload;
			const newShifts = state.shifts.map((shift) => ({ ...shift }));
			const existingSubShifts =
				newShifts.find((shift) => shift.id === addedSubShift?.parentId)
					?.subShifts || [];
			existingSubShifts.push({ ...addedSubShift });
			return {
				...state,
				shifts: newShifts,
				overlapError: "",
			};
		}
		case SCHEDULE_ACTIONS.EDIT_SUB_SHIFT: {
			const { subShift } = payload;
			const newShifts = state.shifts.map((shift) => ({ ...shift }));
			const changedShift = newShifts.find(
				(shift) => shift.id === subShift.parentId
			);
			const changedSubShifts = changedShift?.subShifts?.map((s) =>
				s.id === subShift.id ? { ...s, ...subShift } : s
			);
			changedShift.subShifts = [...changedSubShifts];
			return {
				...state,
				shifts: newShifts,
				subShiftMappingError: validateSubShiftMapping(newShifts),
				overlapError: "",
			};
		}
		case SCHEDULE_ACTIONS.OVERLAP_ERROR_CHECK: {
			return {
				...state,
				overlapError: action.payload,
			};
		}

		// case SCHEDULE_ACTIONS.RESET_PRODUCTION_DAY_AND_SHIFTS: {
		// 	return {
		// 		...state,
		// 		productionDay: scheduleInitialValues.productionDay,
		// 		shifts: scheduleInitialValues.shifts,
		// 	};
		// }

		// case SCHEDULE_ACTIONS.SET_SHOW_UPDATE_SHIFT_MODAL: {
		// 	return {
		// 		...state,
		// 		showUpdateShiftModal: true,
		// 	};
		// }

		// case SCHEDULE_ACTIONS.SET_HIDE_UPDATE_SHIFT_MODAL: {
		// 	return {
		// 		...state,
		// 		showUpdateShiftModal: false,
		// 	};
		// }

		case SCHEDULE_ACTIONS.REMOVE_BREAK_FROM_STATE: {
			const { row } = payload;
			const newShifts = getNewShiftsAfterRemovingBreaks(row, state);
			return {
				...state,
				shifts: newShifts,
			};
		}

		default:
			throw new Error("Invalid action - ", action.type);
	}
}

export default scheduleReducer;

export function changeShiftName(dispatch, { id, name }) {
	dispatch({
		type: SCHEDULE_ACTIONS.CHANGE_SHIFT_NAME,
		payload: {
			id,
			name,
		},
	});
}

export function changeShiftTime(dispatch, { id, newDate, colId, enteredText }) {
	dispatch({
		type:
			colId === "renderStartTime"
				? SCHEDULE_ACTIONS.CHANGE_SHIFT_START_TIME
				: SCHEDULE_ACTIONS.CHANGE_SHIFT_END_TIME,
		payload: {
			id,
			newDate,
			enteredText,
		},
	});
}

export function addNewShift(dispatch, payload) {
	dispatch({
		type: SCHEDULE_ACTIONS.ADD_NEW_SHIFT,
		payload,
	});
}

export function addNewSubShift(dispatch, payload) {
	dispatch({
		type: SCHEDULE_ACTIONS.ADD_NEW_SUB_SHIFT,
		payload,
	});
}
export function editSubShift(dispatch, payload) {
	dispatch({
		type: SCHEDULE_ACTIONS.EDIT_SUB_SHIFT,
		payload,
	});
}

export function deleteLocalShift(dispatch, { id, type, parentId }) {
	dispatch({
		type: SCHEDULE_ACTIONS.DELETE_LOCAL_SHIFT,
		payload: {
			id,
			type,
			parentId,
		},
	});
}

export function changeEndsOn(dispatch, { newEndsOn }) {
	dispatch({
		type: SCHEDULE_ACTIONS.CHANGE_ENDS_ON,
		payload: {
			newEndsOn,
		},
	});
}

export function changeTimeZone(dispatch, { newTimezoneValue }) {
	dispatch({
		type: SCHEDULE_ACTIONS.CHANGE_TIME_ZONE,
		payload: {
			newTimezoneValue,
		},
	});
}

export function triggerShowErrors(dispatch) {
	dispatch({
		type: SCHEDULE_ACTIONS.TRIGGER_SHOW_ERRORS,
	});
}

export function triggerShowEmptyRowErrors(dispatch) {
	dispatch({
		type: SCHEDULE_ACTIONS.TRIGGER_EMPTY_SHIFTS_ERROR,
	});
}

export function triggerShowProdDayErrors(dispatch) {
	dispatch({
		type: SCHEDULE_ACTIONS.TRIGGER_SHOW_PRODUCTION_ERRORS,
	});
}

export function updateShiftState(dispatch, payload) {
	dispatch({
		type: SCHEDULE_ACTIONS.UPDATE_SHIFT_STATE,
		payload,
	});
}

export function removeBreakFromState(dispatch, payload) {
	dispatch({
		type: SCHEDULE_ACTIONS.REMOVE_BREAK_FROM_STATE,
		payload,
	});
}

export function triggerOverlapError(dispatch, payload) {
	dispatch({
		type: SCHEDULE_ACTIONS.OVERLAP_ERROR_CHECK,
		payload,
	});
}
