import { Box } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-query";
import { globalstate$ } from "@smf/ui-util-app";
import GeneralConfigBody from "../layouts/GeneralConfigBody";
import GeneralConfigFooter from "../layouts/GeneralConfigFooter";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
	FILE_ACCEPT_TYPES,
	FILE_CONTENT_TYPES,
	GENERAL_CONFIGURATION_STEPS,
	QUERY_CONSTANTS,
	TOAST_REDUCER_CONSTANTS,
	UPLOAD_CATEGORY,
} from "../../constants";
import { simulateUrlClick } from "../../utils/helpers";
import MESSAGE_STRINGS from "../../constants/en-us";
import UploadFile from "../../components/UploadFile";
import {
	getEquipmentIntelligenceExportDataUrl,
	getEquipmentIntelligenceMetaData,
	getPresignedURLForAIP,
	uploadFileToS3,
} from "../../utils/apiHelper";
import { useToastContext } from "../../context/toastContext";
import { processEquipmentIntelligenceWebSocket } from "../../utils/websocket";
import {
	updateStepStatusCompleted,
	updateStepStatusInProgress,
	useGeneralConfigContext,
} from "../../context/generalConfigContext";
import CommonBackdrop from "../../components/CommonBackdrop";
import { updateRxjsState } from "../../utils/rxjs";

function EquipmentIntelligence({
	onNextClickAPI,
	onPreviousClickAPI,
	refetchGeneralStatus,
}) {
	const [showInitialStep, setShowIntialStep] = useState(true);
	const [fileObject, setFileObject] = useState(null);
	const [isFileUploadedOnce, setIsFileUploadedOnce] = useState(false);
	const [errorMessages, setErrorMessages] = useState("");
	const [uploadUserInfo, setUploadUserInfo] = useState(null);
	const {
		generalConfigState: { isMaintenanceMode },
		generalConfigDispatch,
	} = useGeneralConfigContext();
	const { toastDispatch } = useToastContext();

	const entityId = globalstate$?.value?.plantId;

	function handleNextClick() {
		if (!isFileUploadedOnce) {
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST,
				payload: {
					message:
						MESSAGE_STRINGS["EquipmentIntelligence.onNextClick.errorMessage"],
				},
			});
			return;
		}
		onNextClickAPI();
	}
	function handlePreviousClick() {
		onPreviousClickAPI();
	}
	const { isFetching: isMetadataFetching, isLoading: isMetadataLoading } =
		useQuery(
			[QUERY_CONSTANTS.EQUIPMENT_INTELLIGENCE_META_DATA],
			() => getEquipmentIntelligenceMetaData({ plantId: entityId }),
			{
				onSuccess: (response) => {
					if (response?.data?.fileName && response?.data?.fileSize) {
						const { fileName, fileSize, uploadedInfo } = response.data;
						setShowIntialStep(false);
						setIsFileUploadedOnce(true);
						setFileObject({ name: fileName, size: fileSize });
						setUploadUserInfo(uploadedInfo);
					}
				},
				onError: () => {
					setIsFileUploadedOnce(false);
				},
				refetchOnWindowFocus: false,
				retry: false,
			}
		);
	const {
		isLoading: isProcessEquipmentIntelligenceAPILoading,
		isError: isProcessEquipmentIntelligenceAPIError,
		mutate: processEquipmentIntelligenceAPI,
	} = useMutation((data) => processEquipmentIntelligenceWebSocket(data), {
		onSuccess: (response) => {
			const { fileName, fileSize, uploadedInfo } = response.fileData || {};
			if (fileName && fileSize)
				setFileObject({ name: fileName, size: fileSize });
			setUploadUserInfo(uploadedInfo);

			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
				payload: {
					message: MESSAGE_STRINGS["EquipmentIntelligence.upload.success"],
				},
			});
			updateStepStatusCompleted(
				generalConfigDispatch,
				GENERAL_CONFIGURATION_STEPS.EQUIPMENT_INTELLIGENCE
			);
			setIsFileUploadedOnce(true);
			if (isMaintenanceMode) {
				updateRxjsState({ isEIPorUoMChanged: true });
				refetchGeneralStatus();
			}
		},
		onError: (error) => {
			if (error?.message) {
				setErrorMessages(error.message);
			}
		},
	});
	const {
		isLoading: isGetUploadUrlLoading,
		isError: isGetUploadUrlError,
		mutate: getUploadUrl,
	} = useMutation(
		(file) => {
			setErrorMessages("");
			setUploadUserInfo(null);
			return getPresignedURLForAIP({
				plantId: entityId,
				fileName: file.name,
				category: UPLOAD_CATEGORY.EQUIPMENT_INTELLIGENCE,
				contentType: FILE_CONTENT_TYPES.XLSX,
			});
		},
		{
			onSuccess: async (response, file) => {
				updateStepStatusInProgress(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.EQUIPMENT_INTELLIGENCE
				);
				await uploadFileToS3(response.data, file);
				setShowIntialStep(false);
				processEquipmentIntelligenceAPI({
					fileName: file.name,
					plantId: entityId,
				});
			},
			onError: () => {
				setShowIntialStep(false); // In Error Scenario Reupload button used to upload.
			},
		}
	);

	const { refetch: fetchExportDataUrl, isLoading: isDownloadUrlLoading } =
		useQuery(
			[QUERY_CONSTANTS.EQUIPMENT_INTELLIGENCE_EXPORT_DATA],
			() => getEquipmentIntelligenceExportDataUrl(entityId),
			{
				onSuccess: (response) => {
					if (response && response.url) {
						simulateUrlClick(response.url, FILE_ACCEPT_TYPES.XLSX);
					}
				},
				refetchOnWindowFocus: false,
				retry: false,
				enabled: false,
			}
		);

	return (
		<>
			<GeneralConfigBody>
				<Box>
					<UploadFile
						getUploadUrl={getUploadUrl}
						onExport={fetchExportDataUrl}
						tabTitle={
							MESSAGE_STRINGS["EquipmentIntelligence.fileUpload.tabtitle"]
						}
						popoverContent={
							MESSAGE_STRINGS[
								"EquipmentIntelligence.fileUpload.popover.content"
							]
						}
						exportCsvTitle={
							MESSAGE_STRINGS["EquipmentIntelligence.fileUpload.export"]
						}
						uploadTitle={
							MESSAGE_STRINGS["EquipmentIntelligence.primaryUploadTitle"]
						}
						isLoading={
							isGetUploadUrlLoading || isProcessEquipmentIntelligenceAPILoading
						}
						isFileUploadError={
							isGetUploadUrlError || isProcessEquipmentIntelligenceAPIError
						}
						showInitialStep={showInitialStep}
						fileObject={fileObject}
						setFileObject={setFileObject}
						errorMessages={errorMessages}
						uploadTooltipContent={
							MESSAGE_STRINGS["EquipmentIntelligence.tooltip.upload.content"]
						}
						reUploadModalData={{
							enabled: true,
							customText:
								MESSAGE_STRINGS[
									"EquipmentIntelligenec.reUploadModal.customText"
								],
						}}
						uploadUserInfo={uploadUserInfo}
					/>
				</Box>
				<CommonBackdrop
					open={isDownloadUrlLoading || isMetadataLoading || isMetadataFetching}
				>
					<LoadingIndicator />
				</CommonBackdrop>
			</GeneralConfigBody>
			<GeneralConfigFooter
				onNextClick={handleNextClick}
				onPreviousClick={handlePreviousClick}
				showSave={isMaintenanceMode}
				hideBackButton
			/>
		</>
	);
}

EquipmentIntelligence.propTypes = {
	onNextClickAPI: PropTypes.func,
	onPreviousClickAPI: PropTypes.func,
	refetchGeneralStatus: PropTypes.func,
};

export default EquipmentIntelligence;
