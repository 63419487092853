const ROTATION_ID = {
	ROTATION_TABLE: "table-rotation",
	CREW_DROPDOWN: "dropdown-crew-",
	ROTATION_ADD: "button-addRotation",
	ROTATION_DELETE: "button-deleteRotation",
	CREW_ITEMS: "crew-items",
	CREW_ERROR: "crew-error",
	CREW_ERROR_CONTENT: "crew-error_content",
	ROTATION_HEADER: "header-rotationName",
	ROTATION_TIME_BOX: "legend-time-box",
	ROTATION_TOP_ERROR: "rotation-top-error",
	WEEKNAME: "weekName-value",
	WEEK: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
	PRODUCTION_DAY_ID: "production-day-id",
	PRODUCTION_DAY_WEEKDAY: "production-day-weekday",
	CREW_INFO_ERROR: "indicator-error-crew-info",
	LOADER: "loader",
};

export default ROTATION_ID;
