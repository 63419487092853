import {
	Box,
	Collapse,
	IconButton,
	MenuItem,
	Popover,
	Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useCallback, useRef, useState } from "react";
import GeneralButton from "../../GeneralButton";
import InlineErrorMessages from "../../InlineErrorMessages";
import GeneralDropdown from "../../GeneralDropdown";
import { BUTTON_TYPE, CALENDAR_TEST_IDS } from "../../../constants";
import MESSAGE_STRINGS from "../../../constants/en-us";
import CloseIcon from "../../../assets/img/closeIconWhite.svg";
import EditIcon from "../../../assets/img/editIconWhite.svg";
import ClockIcon from "../../../assets/img/clockIconWhite.svg";
import {
	formatRotationDuration,
	formatShiftDuration,
} from "../../../utils/helpers";
import GeneralToolTip from "../../GeneralTooltip";

const useEventStyles = {
	rotationName: {
		fontSize: "0.75rem",
	},
	paperRoot: {
		borderRadius: "0.5rem",
	},
	iconButtonRoot: {
		padding: 0,
	},
	popoverRotationName: {
		fontSize: "1rem",
		fontWeight: "700",
	},
	durationLabel: {
		fontSize: "0.875rem",
		fontWeight: "400",
		textTransform: "none",
	},
	selectRotationShiftLabel: {
		fontSize: "0.875rem",
	},
};

function CalendarEvent(props) {
	const {
		event = {},
		isWeeklyRotation,
		selectDropdownOptions = [],
		onSave,
		isDuplicateCrewAssigned = () => null,
	} = props;

	function getSelectedItem() {
		if (isWeeklyRotation) {
			return selectDropdownOptions.find((item) => item.id === event.rotation)
				?.id;
		}
		return selectDropdownOptions.find((item) => item.text === event.crewName)
			?.id;
	}

	const [eventPopoverAnchor, setEventPopoverAnchor] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [selectedItem, setSelectedItem] = useState(getSelectedItem());
	const [isDuplicateCrew, setIsDuplicateCrew] = useState(false);
	const [, updateState] = useState();

	const clickedEventRef = useRef(null);

	const isEventPopoverOpen = Boolean(eventPopoverAnchor);
	const id = isEventPopoverOpen ? "event-details" : undefined;

	function handleEventClick() {
		setEventPopoverAnchor(clickedEventRef.current);
	}

	function handlePopoverClose() {
		setEventPopoverAnchor(null);
		setIsDuplicateCrew(false);
		setSelectedItem(getSelectedItem());
		setIsEditing(false);
	}
	const forceUpdate = useCallback(() => updateState({}), []);
	useEffect(() => {
		setTimeout(forceUpdate, 500);
	}, [forceUpdate, isEditing]);

	function handleRotationOrCrewChange(e) {
		setSelectedItem(e.target.value);
		setIsDuplicateCrew(
			isWeeklyRotation
				? false
				: isDuplicateCrewAssigned(
						e.target.value,
						event.dateTimestamp,
						event.shiftId
				  )
		);
	}

	function getSelectedItemName() {
		const val = selectDropdownOptions.find((item) => item.id === selectedItem);
		return val.text.length > 17 ? val.text : "";
	}

	function handleClickSave() {
		const postBody = { ...event, selectedItem };
		onSave(postBody);
	}
	return (
		<>
			<Box
				onClick={handleEventClick}
				ref={clickedEventRef}
				padding={isWeeklyRotation ? "0.375rem 0.5rem" : "0.125rem 0.75rem"}
				data-testid={CALENDAR_TEST_IDS.EVENT_ITEM}
			>
				<Typography
					varint="body1"
					sx={{ ...useEventStyles.rotationName }}
					noWrap
				>
					{event.eventName}
				</Typography>
			</Box>
			{isEventPopoverOpen && (
				<Popover
					id={id}
					open={isEventPopoverOpen}
					anchorEl={eventPopoverAnchor}
					onClose={handlePopoverClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					PaperProps={{ sx: { ...useEventStyles.paperRoot } }}
					marginThreshold={16}
					sx={{ zIndex: 10 }}
				>
					<Box
						width={430}
						bgcolor="background.layoutBackgroundColor"
						padding={2}
						display="flex"
						flexDirection="column"
						data-testid={CALENDAR_TEST_IDS.EVENT_POPOVER}
					>
						<Box display="flex" alignItems="center" marginBottom={0.5}>
							<Box>
								<GeneralToolTip
									message={
										!isWeeklyRotation && event.title.length > 31 && event.title
									}
									disableInteractive
									placement="top"
								>
									<Typography
										variant="h6"
										sx={{ ...useEventStyles.popoverRotationName }}
										data-testid={CALENDAR_TEST_IDS.EVENT_POPOVER_TITLE}
									>
										{(isWeeklyRotation && event.eventName) ||
											(!isWeeklyRotation &&
												`Shift: ${
													event.title.length > 31
														? `${event.title.substring(0, 31)}...`
														: event.title
												}`)}
									</Typography>
								</GeneralToolTip>
								{!isWeeklyRotation && (
									<GeneralToolTip
										message={event.eventName.length > 30 && event.eventName}
										disableInteractive
										placement="top"
									>
										<Typography
											variant="h6"
											sx={{ ...useEventStyles.popoverRotationName }}
										>{`Crew: ${
											event.eventName.length > 30
												? `${event.eventName.substring(0, 30)}...`
												: event.eventName
										}`}</Typography>
									</GeneralToolTip>
								)}
							</Box>
							<Box
								display="flex"
								flexGrow={1}
								justifyContent="flex-end"
								alignItems="center"
							>
								<Box marginRight={1}>
									<IconButton
										sx={{ ...useEventStyles.iconButtonRoot }}
										onClick={() =>
											setIsEditing((prevIsEditing) => !prevIsEditing)
										}
										data-testid={CALENDAR_TEST_IDS.EVENT_POPOVER_EDIT}
									>
										<EditIcon height={24} width={24} />
									</IconButton>
								</Box>
								<Box marginLeft={1}>
									<IconButton
										sx={{ ...useEventStyles.iconButtonRoot }}
										onClick={handlePopoverClose}
										data-testid={CALENDAR_TEST_IDS.EVENT_POPOVER_CLOSE}
									>
										<CloseIcon height={24} width={24} />
									</IconButton>
								</Box>
							</Box>
						</Box>
						<Box marginY={0.5} display="flex" alignItems="center">
							<Box marginRight={0.5} display="flex">
								<ClockIcon height={16} width={16} />
							</Box>
							<Box marginLeft={0.5}>
								<Typography
									variant="subtitle2"
									sx={{ ...useEventStyles.durationLabel }}
									data-testid={CALENDAR_TEST_IDS.EVENT_POPOVER_CAPTION}
								>
									{isWeeklyRotation
										? formatRotationDuration(
												new Date(event.startDate),
												new Date(event.endDate)
										  )
										: formatShiftDuration(
												event.actualStartTime,
												event.actualEndTime,
												event.date,
												event.endsOn,
												event.timezone
										  )}
								</Typography>
							</Box>
						</Box>

						<Collapse
							in={isEditing}
							timeout={300}
							data-testid={CALENDAR_TEST_IDS.EVENT_POPOVER_COLLAPSE}
						>
							<Box display="flex" flexDirection="column" marginY={1}>
								<Box marginBottom={1}>
									<Typography
										sx={{
											...useEventStyles.selectRotationShiftLabel,
										}}
									>
										{isWeeklyRotation
											? MESSAGE_STRINGS[
													"WeeklyAndDailyRotation.RotationsCalendar.title.selectRotation"
											  ]
											: MESSAGE_STRINGS[
													"WeeklyAndDailyRotation.RotationsCalendar.title.selectCrew"
											  ]}
									</Typography>
								</Box>
								<Box width="50%" marginY={1}>
									<GeneralToolTip
										message={getSelectedItemName()}
										disableInteractive
										maxWidth="16.75rem"
										sx={{ zIndex: 20 }}
									>
										<GeneralDropdown
											data-testid={CALENDAR_TEST_IDS.EVENT_POPOVER_DROPDOWN}
											value={selectedItem}
											onChange={handleRotationOrCrewChange}
											error={!isWeeklyRotation && isDuplicateCrew}
											sx={{ width: "13.45rem" }}
										>
											{selectDropdownOptions.map(({ id: itemId, text }) => (
												<MenuItem
													data-testid={
														CALENDAR_TEST_IDS.EVENT_POPOVER_DROPDOWN_ITEM
													}
													value={itemId}
													key={itemId}
													title={text.length > 17 && text}
													sx={{ width: "13.45rem", zIndex: 100 }}
												>
													<Typography noWrap>{text}</Typography>
												</MenuItem>
											))}
										</GeneralDropdown>
									</GeneralToolTip>
								</Box>
								{!isWeeklyRotation && isDuplicateCrew && (
									<InlineErrorMessages
										message={
											MESSAGE_STRINGS[
												"WeeklyAndDailyRotation.RotationsCalendar.DailyShifts.validation"
											]
										}
										width="100%"
										dataTestId={CALENDAR_TEST_IDS.EVENT_POPOVER_DROPDOWN_ERROR}
									/>
								)}
								<Box
									display="flex"
									alignItems="center"
									marginTop={8}
									justifyContent="flex-end"
								>
									<GeneralButton
										text="Save"
										type={BUTTON_TYPE.PRIMARY}
										onClick={handleClickSave}
										disabled={isDuplicateCrew}
										data-testid={CALENDAR_TEST_IDS.EVENT_SAVE}
									/>
								</Box>
							</Box>
						</Collapse>
					</Box>
				</Popover>
			)}
		</>
	);
}

const eventPropTypes = {
	rotation: PropTypes.string,
	startDate: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string,
	]),
	endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	actualEndTime: PropTypes.shape({
		endHours: PropTypes.string,
		endMinutes: PropTypes.string,
	}),
	actualStartTime: PropTypes.shape({
		endHours: PropTypes.string,
		endMinutes: PropTypes.string,
	}),
	color: PropTypes.string,
	crew: PropTypes.number,
	crewName: PropTypes.string,
	date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	dateTimestamp: PropTypes.string,
	endsOn: PropTypes.string,
	shiftId: PropTypes.number,
	timezone: PropTypes.string,
	title: PropTypes.string,
};

CalendarEvent.propTypes = {
	event: PropTypes.shape(eventPropTypes),
	isWeeklyRotation: PropTypes.bool,
	selectDropdownOptions: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.string,
		})
	),
	onSave: PropTypes.func,
	isDuplicateCrewAssigned: PropTypes.func,
};
export default CalendarEvent;
