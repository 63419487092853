/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { some } from "lodash";
import { DialogActions } from "@mui/material";
import MemoizedHierarchySelection from "./EditComponents/HierarchySelection";
import GeneralButton from "../../../components/GeneralButton";
import { BUTTON_TYPE, SHIFT_EDIT_REDUCER_CONSTANTS } from "../../../constants";
import ShiftsDetails from "./EditComponents/ShiftsDetails";
import BreakSelection from "./EditComponents/BreakSelection";
import {
	StyledDialog,
	StyledHeaderBox,
	StyledHeaderTitle,
	StyledModalContainer,
	StyledModalContent,
} from "./styles";
import { useShiftEditContext } from "./shiftEditContext";
import MESSAGE_STRINGS from "../../../constants/en-us";
import { getDefaultSelectedHierarchy } from "./helpers";

function ShiftEditModal({
	actionType,
	onCancelClick,
	selectedShift,
	state,
	onSaveClick,
	hierarchyData,
	parentShift,
	shiftType = "shift",
	factoryId,
	deleteBreak,
}) {
	const { shiftEditState, shiftEditDispatch } = useShiftEditContext();
	const handleAddBreak = () =>
		shiftEditDispatch({
			type: SHIFT_EDIT_REDUCER_CONSTANTS.ADD_BREAK,
		});

	const isBreaksError = (br) =>
		br.startTime?.isError ||
		br.endTime?.isError ||
		!br.endsOn ||
		!br.startsOn ||
		!!br.nameError;

	const isAnyShiftError = (shift) =>
		shift?.startTime.isError ||
		shift?.endTime.isError ||
		!shift.endsOn ||
		!shift.startsOn ||
		!!shift.nameErrorText ||
		!!shift.hierarchyErrorMessage;

	const handleSaveClicked = () => {
		const isAnyBreaksError = some(shiftEditState.shift.breaks, isBreaksError);
		shiftEditDispatch({
			type: SHIFT_EDIT_REDUCER_CONSTANTS.SHOW_ERRORS,
		});
		if (!isAnyShiftError(shiftEditState.shift) && !isAnyBreaksError) {
			const saveState = {
				...shiftEditState.shift,
				...getDefaultSelectedHierarchy(
					hierarchyData,
					shiftEditState.shift,
					shiftType,
					parentShift,
					state
				),
			};
			if (saveState?.hierarchyErrorMessage) {
				shiftEditDispatch({
					type: SHIFT_EDIT_REDUCER_CONSTANTS.SELECT_HIERARCHY,
					payload: {
						newHierarchy: saveState?.selectedHierarchy,
					},
				});
			} else {
				onSaveClick(saveState, actionType);
				onCancelClick();
			}
		}
	};
	useEffect(() => {
		shiftEditDispatch({
			type: SHIFT_EDIT_REDUCER_CONSTANTS.LOAD_MAIN_STATE,
			payload: {
				mainState: state,
				selectedShift,
				actionType,
				parentShift,
				factoryId,
				hierarchyData,
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<StyledDialog open fullWidth data-testid="shift-edit-modal">
				<StyledModalContainer
					sx={{ background: (theme) => theme.palette.background.blackGrey }}
				>
					<StyledHeaderBox>
						<StyledHeaderTitle>
							{MESSAGE_STRINGS[`ShiftEditModal.title.${shiftType}`]}
						</StyledHeaderTitle>
					</StyledHeaderBox>
					<StyledModalContent>
						<ShiftsDetails
							state={shiftEditState.shift}
							dispatch={shiftEditDispatch}
						/>
						<BreakSelection
							breaks={shiftEditState.shift?.breaks}
							handleAddBreak={handleAddBreak}
							dispatch={shiftEditDispatch}
							deleteAPI={deleteBreak}
						/>
						<MemoizedHierarchySelection
							width="70%"
							selectedHierarchy={shiftEditState.shift.selectedHierarchy}
							dispatch={shiftEditDispatch}
							data={hierarchyData ? [hierarchyData] : []}
							errorMessage={shiftEditState.shift.hierarchyErrorMessage}
							parentHierarchy={shiftEditState.parentShift?.selectedHierarchy}
							shiftType={shiftType}
							factoryId={factoryId}
						/>
					</StyledModalContent>
				</StyledModalContainer>
				<DialogActions>
					<GeneralButton
						text="Cancel"
						type={BUTTON_TYPE.SECONDARY}
						onClick={onCancelClick}
						dataTestId="shift-modal-cancel"
					/>

					<GeneralButton
						text="Save"
						onClick={handleSaveClicked}
						dataTestId="shift-modal-save"
						type={BUTTON_TYPE.PRIMARY}
					/>
				</DialogActions>
			</StyledDialog>
		</LocalizationProvider>
	);
}

ShiftEditModal.propTypes = {};

export default ShiftEditModal;
