import PropTypes from "prop-types";
import { GeneralDialog } from "../../../components/GeneralDialog";
import NotificationDialog from "../../../components/GeneralDialog/NotificationDialog";
import { MODAL_TYPES } from "../../../constants";
import MESSAGE_STRINGS from "../../../constants/en-us";

function NavigationModal({
	open = false,
	onClose,
	onSave,
	isLoading,
	...props
}) {
	return (
		<GeneralDialog
			open={open}
			fullWidth={false}
			isLoading={isLoading}
			sx={{
				"& .notificationdialog-title": {
					fontWeight: "700",
					paddingY: "0.75rem",
				},
			}}
		>
			<NotificationDialog
				minWidth="32rem"
				maxWidth="35rem"
				type={MODAL_TYPES.CONFIRM}
				customTitle={MESSAGE_STRINGS["NavigationModal.title"]}
				customText={MESSAGE_STRINGS["NavigationModal.customText"]}
				noteText={MESSAGE_STRINGS["NavigationModal.noteText"]}
				lightText={false}
				handleSave={onSave}
				handleClose={onClose}
				cancelText={MESSAGE_STRINGS["NavigationModal.button.discard"]}
				confirmText={MESSAGE_STRINGS["NavigationModal.button.save"]}
				{...props}
			/>
		</GeneralDialog>
	);
}

NavigationModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	isLoading: PropTypes.bool,
};

export default NavigationModal;
