import { Box, Typography, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const StyledLegendItemContainer = styled("div")(({ theme }) => ({
	color: theme.palette.text.lightYellowishGray,
	fontWeight: "700",
	fontSize: "0.875rem",
	display: "flex",
	"& .legenditem-legendmainpart": {
		display: "flex",
		alignItems: "center",
	},
	"& .legenditem-legendicon": {
		height: "0.75rem",
		width: "0.75rem",
		borderRadius: "4px",
		marginRight: "0.5rem",
	},
	"& .legenditem-legendtext": {
		display: "flex",
		flexDirection: "column",
	},
	"& .legenditem-sublabel": {
		fontSize: "0.75rem",
		textAlign: "center",
	},
}));

function LegendItem({
	colorCode = "red",
	mainLabel = "",
	subLabel = "",
	...other
}) {
	return (
		<StyledLegendItemContainer className="legenditem-container" {...other}>
			<div className="legenditem-legendtext">
				<div className="legenditem-legendmainpart">
					<Box className="legenditem-legendicon" bgcolor={colorCode} />
					<Typography className="legenditem-mainlabel" variant="subtitle1">
						{mainLabel}
					</Typography>
				</div>
				{subLabel && (
					<Typography className="legenditem-sublabel" variant="subtitle2">
						{subLabel}
					</Typography>
				)}
			</div>
		</StyledLegendItemContainer>
	);
}

LegendItem.propTypes = {
	colorCode: PropTypes.string,
	mainLabel: PropTypes.string,
	subLabel: PropTypes.string,
};

export default LegendItem;
