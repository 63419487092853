import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { cloneDeep, omitBy, uniqueId } from "lodash";

import { SHIFT_EDIT_REDUCER_CONSTANTS } from "../../../constants";
import {
	addDayToTime,
	getBreakItemsForUpdate,
	getInitialLoadData,
	isEndsOnBeforeStartsOn,
	validateBreakNames,
	validateBreakTimings,
	validateHierarchySelection,
	validateShiftName,
	validateShiftTimings,
} from "./helpers";
import MESSAGE_STRINGS from "../../../constants/en-us";

export const ShiftEditContext = createContext();

export const useShiftEditContext = () => useContext(ShiftEditContext);

const initialValues = {
	shift: {
		shiftName: "",
		startTime: {
			time: null,
			isError: false,
			errorText: "",
		},
		endTime: {
			time: null,
			isError: false,
			errorText: "",
		},
		renderStartTime: null,
		renderEndTime: null,
		breaks: [],
		selectedHierarchy: {},
		isSaveClickedOnce: false,
	},
};

function reducer(state, action) {
	const { payload, type } = action;
	switch (type) {
		case SHIFT_EDIT_REDUCER_CONSTANTS.LOAD_MAIN_STATE: {
			const newState = getInitialLoadData(state, payload);
			return { ...newState };
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_SHIFT_NAME: {
			const { value } = payload;
			const newShift = state.shift;
			newShift.shiftName = value;
			newShift.isUpdated = true;
			newShift.nameErrorText = validateShiftName(
				newShift,
				state.mainState,
				state.parentShift
			);
			return { ...state, shift: { ...newShift } };
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_DAY_SELECTION: {
			const { field, value } = payload;
			const newShift = state.shift;
			const newBreaks = newShift.breaks;
			newShift[field] = value;
			newShift.isUpdated = true;
			if (isEndsOnBeforeStartsOn(newShift.startsOn, newShift.endsOn, field)) {
				newShift.endsOn = "nextDay";
			}
			newShift.formattedStartTime = addDayToTime(
				newShift.startTime?.time,
				newShift.startsOn
			);
			newShift.formattedEndTime = addDayToTime(
				newShift.endTime?.time,
				newShift.endsOn
			);
			const validatedShift = validateShiftTimings(
				newShift,
				state.parentShift,
				state.mainState,
				state.plantEntityId
			);

			return {
				...state,
				shift: {
					...validatedShift,
					breaks: validateBreakTimings(newBreaks, newShift),
				},
			};
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_TIMING: {
			const { field, value, enteredText } = payload;
			const newShift = state.shift;
			const newBreaks = newShift.breaks;
			newShift.isUpdated = true;
			if (field === "renderStartTime") {
				newShift.formattedStartTime = addDayToTime(
					enteredText,
					newShift.startsOn
				);
				newShift.startTime.time = enteredText;
			} else {
				newShift.formattedEndTime = addDayToTime(enteredText, newShift.endsOn);
				newShift.endTime.time = enteredText;
			}

			newShift[field] = value;
			const validatedShifts = validateShiftTimings(
				newShift,
				state.parentShift,
				state.mainState,
				state.plantEntityId
			);
			return {
				...state,
				shift: {
					...validatedShifts,
					breaks: validateBreakTimings(newBreaks, newShift),
				},
			};
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.ADD_BREAK: {
			const newShift = state.shift;
			const newBreaks = [...newShift.breaks].map((b) => ({ ...b }));
			const breakObj = {
				name: "",
				id: uniqueId("localBreak_"),
				renderStartTime: null,
				renderEndTime: null,
				endsOn: null,
				startsOn: null,
				startTime: {
					time: "",
					isError: true,
					errorText: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
				},
				endTime: {
					time: "",
					isError: true,
					errorText: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
				},
				nameError: MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"],
				fieldState: {
					renderStartTime: { blur: false, dirty: false },
					name: { blur: false, dirty: false },
					renderEndTime: { blur: false, dirty: false },
					startsOn: { blur: false, dirty: false },
					endsOn: { blur: false, dirty: false },
				},
				isLocal: true,
				isNew: true,
				parentId: newShift.id,
			};
			newBreaks.push(breakObj);
			newShift.breaks = [...newBreaks];
			return { ...state, shift: { ...newShift } };
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.REMOVE_BREAK: {
			const { id } = payload;
			const newShift = state.shift;
			const newBreaks = cloneDeep(newShift?.breaks?.filter((b) => b.id !== id));
			const validatedBreaks = validateBreakNames(
				validateBreakTimings(newBreaks, newShift)
			);
			return { ...state, shift: { ...newShift, breaks: validatedBreaks } };
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.SELECT_HIERARCHY: {
			const { newHierarchy } = payload;
			const newShift = state.shift;
			const result = omitBy(newHierarchy, (value) => !value === true);
			newShift.selectedHierarchy = result;
			newShift.isUpdated = true;
			newShift.hierarchyErrorMessage = validateHierarchySelection(
				newShift,
				state?.mainState?.shifts,
				state?.parentShift,
				state?.plantEntityId
			);

			return { ...state, shift: { ...newShift } };
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_NAME: {
			const { id, value } = payload;
			const newShift = state.shift;
			const newBreaks = cloneDeep(newShift.breaks);
			const itemToBeUpdated = newBreaks.find((br) => br.id === id);

			if (itemToBeUpdated) {
				itemToBeUpdated.isUpdated = true;
				itemToBeUpdated.name = value;
				const oldFieldState = itemToBeUpdated.fieldState.name;
				itemToBeUpdated.fieldState.name = {
					...oldFieldState,
					dirty: true,
				};
			}
			return {
				...state,
				shift: { ...newShift, breaks: validateBreakNames(newBreaks) },
			};
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_DAY_SELECTION: {
			const { id, value, fieldType } = payload;
			const newShift = state.shift;
			const newBreaks = cloneDeep(newShift.breaks);
			const itemToBeUpdated = newBreaks.find((br) => br.id === id);

			if (itemToBeUpdated) {
				itemToBeUpdated.isUpdated = true;
				itemToBeUpdated[fieldType] = value;
				if (
					isEndsOnBeforeStartsOn(
						itemToBeUpdated.startsOn,
						itemToBeUpdated.endsOn,
						fieldType
					)
				) {
					itemToBeUpdated.endsOn = "nextDay";
				}
				const oldFieldState = itemToBeUpdated.fieldState[fieldType];
				itemToBeUpdated.fieldState[fieldType] = {
					...oldFieldState,
					dirty: true,
				};

				itemToBeUpdated.formattedStartTime = addDayToTime(
					itemToBeUpdated.startTime?.time,
					itemToBeUpdated.startsOn
				);
				itemToBeUpdated.formattedEndTime = addDayToTime(
					itemToBeUpdated.endTime?.time,
					itemToBeUpdated.endsOn
				);
			}

			return {
				...state,
				shift: {
					...newShift,
					breaks: validateBreakTimings(newBreaks, newShift),
				},
			};
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_TIMING: {
			const { id, value, fieldType, enteredText } = payload;
			const newShift = state.shift;
			const newBreaks = getBreakItemsForUpdate(
				newShift,
				id,
				enteredText,
				fieldType,
				value
			);
			return {
				...state,
				shift: {
					...newShift,
					breaks: validateBreakTimings(newBreaks, newShift),
				},
			};
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.SHOW_ERRORS: {
			const newShift = state.shift;
			const newBreaks = cloneDeep(newShift).breaks.map((b) => ({
				...b,
				isShowError: true,
			}));

			return {
				...state,
				shift: { ...newShift, isShowError: true, breaks: newBreaks },
			};
		}
		case SHIFT_EDIT_REDUCER_CONSTANTS.UPDATE_BREAK_FIELD_STATE: {
			const { id, fieldType, newFieldState } = payload;
			const newShift = state.shift;
			const newBreaks = cloneDeep(newShift.breaks);
			const itemToBeUpdated = newBreaks.find((br) => br.id === id);
			itemToBeUpdated.fieldState[fieldType] = { ...newFieldState };
			return {
				...state,
				shift: { ...newShift, breaks: newBreaks },
			};
		}
		default:
			return state;
	}
}

export function ShiftEditProvider({ children }) {
	const [shiftEditState, shiftEditDispatch] = useReducer(
		reducer,
		initialValues
	);

	const shiftEditData = React.useMemo(
		() => ({ shiftEditState, shiftEditDispatch }),
		[shiftEditState]
	);
	return (
		<ShiftEditContext.Provider value={shiftEditData}>
			{children}
		</ShiftEditContext.Provider>
	);
}

ShiftEditProvider.propTypes = {
	children: PropTypes.node,
};
