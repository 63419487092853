import { GENERAL_CONFIGURATION_STEPS } from "..";

const MESSAGE_STRINGS = {
	// App Wrapper Labels
	"GeneralDropdown.default.placeholder": "Select",
	"Actions.permissions.doYouWishToDelete": "Do you wish to delete",

	// Pagination Footer
	"GeneralTable.Pagination.caption.label1": `Displaying`,
	"GeneralTable.Pagination.caption.label2": "of",
	"GeneralTable.Pagination.caption.label3": "results",
	"GeneralTable.Pagination.caption.rowsLabel": "Rows per page",
	"GeneralTable.Pagination.caption.noData": "No data found",

	// Crews
	"GeneralSettings.Crews.sectionHeader": "Crews",
	"GeneralSettings.Crews.column.header.crewName": "Crew Name",
	"GeneralSettings.Crews.column.header.actions": "Actions",
	"GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.crewNameShouldUnique":
		"Crew Names Should be Unique",
	"GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.crewNameLength":
		"Please complete the required field.",
	"GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.failedUniqueNameGeneration":
		"Please re-assign a unique name",
	"GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customTitle": "Delete Crew ?",
	"GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText.inProgress":
		"Crew is being deleted...",
	"GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.customText":
		"Do you wish to delete",
	"GENERAL_CONFIGURATIONS.CREWS.NOTIFICATION.noteText":
		"Note: Deleting crew will affect rotation screen and will also delete all assigned crews.",
	"GENERAL_CONFIGURATIONS.CREWS.MESSAGES.deleteCrewSuccess":
		"Deleted Crew Member Successfully",
	"GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.deleteCrewFailed":
		"There was a problem deleting crew member",
	"GENERAL_CONFIGURATIONS.CREWS.ERROR_MESSAGES.updateCrewFailed":
		"There was a problem updating crew members",
	"GENERAL_CONFIGURATIONS.CREWS.ADD.errorsPresent":
		"Remove errors before adding new crew members",
	"Crews.tabHeading": "Crews",

	// General Configurations
	"GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText": "No",
	"GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText": "Yes",

	// General Error Messages
	"ERROR_MESSAGES.emptyField": "Required field",
	"ERROR_MESSAGES.fixAllErrorsBeforeProceed":
		"Please resolve the errors before proceeding.",
	"ERROR_MESSAGES.mustBeUnique": "Values must be unique.",
	// Schedule
	"Schedule.tabHeading": "Schedule",
	"Schedule.ProductionDay.tableHeader.startTime": "Start Time",
	"Schedule.ProductionDay.tableHeader.endsOn": "Ends On",
	"Schedule.ProductionDay.tableHeader.endTime": "End Time",
	"Schedule.ProductionDay.tableHeader.timezone": "Timezone",
	"Schedule.ProductionDay.tableCell.productionDay": "Production Day",
	"Schedule.Shifts.heading": "Shifts",
	"Schedule.Shifts.tableHeader.shiftName": "Shift Name",
	"Schedule.Shifts.tableHeader.startTime": "Start Time",
	"Schedule.Shifts.tableHeader.endTime": "End Time",
	"Schedule.Shifts.tableHeader.action": "Action",
	"Schedule.Error.requiredField": "Required field",
	"Schedule.Error.invalidTime": "Value must be between 00:00 and 23:59",
	"Schedule.configShifts.legends.productionDayStart": "Production Day Start",
	"Schedule.configShifts.legends.productionDayEndTime": "Production Day End",
	"Schedule.configShifts.legends.calendarChangeTime": "Calendar Day Change",
	"Schedule.ShiftTimeError.sameShiftTime": "Shift must have different timings.",
	"Schedule.ShiftTimeError.startTimeError":
		"Shift End Time must be later than Shift Start Time",
	"Schedule.ProdTimeError.startTimeError":
		"End Time should be later than the Start Time",
	"Schedule.ShiftEmpty.ErrorText": "Please Enter Shift Timings",
	"Schedule.ShiftTimeError.boundError":
		"Shift timings should be within the Production Day.",
	"Schedule.shiftOverlap.Errortext": "Shift must have different timings.",
	"Schedule.ShiftNameError.empty": "Shift name cannot be empty",
	"Schedule.ShiftNameError.duplicate": "Shift names cannot be duplicate",
	"Schedule.NOTIFICATION.customTitle": "Delete Shift?",
	"Schedule.endsOn.sameDay": "Calendar Day 1",
	"Schedule.endsOn.nextDay": "Calendar Day 2",
	"Schedule.error.endsOn": "Ends On cannot be empty",
	"Schedule.error.timezone": "Timezone cannot be empty",
	"Schedule.error.emptyShift": "Please add one shift before proceeding",
	"Schedule.fieldCannotBeBlank": "Field cannot be blank",
	"Schedule.ShiftTimeError.invalidTime": "Invalid Time",
	"Schedule.productionDay.lessThanProductionDayStartTime": `End Time should be between 00:00 and the Production Day's Start Time`,
	"Schedule.updateScheduleModal.customText":
		"Do you wish to update the Schedule?",
	"Schedule.updateScheduleModal.customNote.1":
		"Note: The changes made to the production schedule will reflect across the system starting the next calendar day.",
	"Schedule.updateScheduleModal.customNote.2":
		"Note: The updated Production Day will go into effect at the Start Time of the next Production Day.",
	"Schedule.updateProductionDay":
		"The updated Production Day will go into effect at the Start Time of the next Production Day",
	"Schedule.deleteModal.title.shift": "Delete Shift?",
	"Schedule.deleteModal.title.subShift": "Delete Sub-Shift?",
	"Schedule.shiftOverlap.subShift.errorMessage":
		"When creating overlapping shifts please add non-overlapping sub-shift(s) to ensure the same equipment is not assigned to more than one time period at a time.",

	// Rotations
	"Rotations.tabHeading": "Rotations",

	// Weekly and Daily Rotations
	"WeeklyAndDailyRotations.tabHeading": "Weekly and Daily Rotations",

	// Equipment Intelligence
	"EquipmentIntelligence.tabHeading": "Equipment Intelligence",
	"EquipmentIntelligence.fileUpload.popover.content":
		"Equipment Intelligence describes the equipment hierarchy of a factory through the Area, Line, Zone, Cell, Asset, Sub-asset down to the component level. Use the template to provide the equipment hierarchy details of the plant and relevant asset parameter data including parameter tags and parameter tag purposes for various use cases (e.g., Factory Health, OEE, Standard Operating Conditions, Equipment Constraints).",
	"EquipmentIntelligence.fileUpload.tabtitle": "Equipment Intelligence",
	"EquipmentIntelligence.fileUpload.export": "Export Data",
	"EquipmentIntelligence.primaryUploadTitle":
		" Upload Equipment Intelligence XLSX",
	"EquipmentIntelligence.tooltip.upload.content":
		"If you have already uploaded an XLSX file, clicking this icon again will allow you to upload a new XLSX file that will replace the previous file. All data from the first file will be removed.",
	"EquipmentIntelligence.upload.success":
		" File has been successfully uploaded!",
	"EquipmentIntelligence.onNextClick.errorMessage":
		"Please upload Equipment Intelligence XLSX file to before clicking on Next.",
	"EquipmentIntelligenec.reUploadModal.customText": `Uploading a new file will erase the previously saved Equipment Intelligence data from the system and replace it with the data in the new file upload.`,
	"EquipmentIntelligence.uploadedInfo.partA": "File uploaded by",
	"EquipmentIntelligence.uploadedInfo.partB": "on",
	"EquipmentIntelligence.uploadedInfo.partC": "at",

	// Plant Financial Calculations
	"GPFC.pageHeading.heading": "Plant Financial Calculations",
	"GPFC.errors.validMoneyAmount":
		"Enter a valid number with 2 decimal places (eg: 12.50)",
	"GPFC.errors.number": "Value Must be a Number",
	"GPFC.errors.zero": "Values should be greater than zero",
	"GPFC.errors.submit.errorsPresent": "Please fix the errors and resubmit",
	"GPFC.accessor.label": "Label",
	"GPFC.accessor.value": "Value",
	"GPFC.headers.uom.part1": "Cost Per",
	"GPFC.headers.uom.part2": "of Finished Goods",
	"GPFC.accessor.contribution.label": "Per",
	"GPFC.heading.parameters": "Parameters",
	"GPFC.heading.isPlantSoldout": "Is The Plant Sold Out? ",
	"GPFC.heading.contributionMargin": "Plant's Contribution Margin",
	"GPFC.heading.scrapPercentage": "Average Scrap Percentage",
	"GPFC.heading.variableCosts": "Variable Costs",
	"GPFC.tooltip.contributionMargin": "Pre-tax profits per unit sold",
	"GPFC.tooltip.isPlantSoldout":
		"The state of a manufacturing plant when there is sufficient demand to ensure that every new unit produced will be sold.",
	"GPFC.tooltip.scrapPercentage":
		"A ratio of expected finished goods with quality defects over total finished goods produced over a given period of time.",

	"GPFC.plantSoldout.options.true": "Yes",
	"GPFC.plantSoldout.options.false": "No",

	"GPFC.tooltip.row.5":
		"Variable cost is the sum of labour, material, utility and miscellaneous variable costs",

	"GPFC.label.UoM": "UoM",

	"GPFC.modal.title": "Confirm!",
	"GPFC.modal.label": `Changing UoM will delete all previously entered data for Throughput, WIP, and Ideal Run Rate.`,
	"GPFC.modal.confirmText": "Do you wish to change UoM?",
	"GPFC.label.DOLLAR": "Dollars ($)",
	"GPFC.label.UNIT": "Units",
	"GPFC.label.POUND": "Pounds (lbs)",
	"GPFC.label.row.UoM": `Unit of Measure (UoM) of Finished Goods – used for Throughput, WIP, and Ideal Run Rate`,
	"GPFC.dropdown.placeholder": "Select",
	"GPFC.label.totalVariableCost": "Total Variable Cost",
	"GPFC.label.hourlyAssociates": "Labor Cost, Hourly Associates",
	"GPFC.label.salariedAssociates": "Labor Cost, Salaried Associates",
	"GPFC.label.materialCost": "Material Cost",
	"GPFC.label.utilityCost": "Utility Cost",
	"GPFC.label.miscellaneousVariableCost": "Miscellaneous Variable Cost",
	"GPFC.toast.success":
		"Factory General Configuration settings have been successfully completed",

	"PlantFinancialCalculations.tabHeading": "Plant Financial Calculations",
	// Toast Component
	"Toast.message.SUCCESS": "Your changes were saved Successfully!",
	"Toast.message.ERROR": "Oops, something went wrong. Please try again later!",
	"Toast.message.WARNING": "Oops, something went wrong!",
	"Toast.message.INFO": "Please wait",

	// Upload Component
	"UploadComponent.reUpload.text": "Re-upload",
	"UploadComponent.secondaryUpload.XLSX": "Upload XLSX",
	"UploadComponent.loading": "Loading Data...",
	"UploadComponent.reUploadModal.customTitle": "Confirm!",
	"UploadComponent.reUploadModal.noteText": "Do you wish to continue?",
	"UploadComponent.reUploadModal.action.YES": "Yes",
	"UploadComponent.reUploadModal.action.NO": "No",

	// Weekly and Daily Rotations
	"WeeklyAndDailyRotation.screenName": "Weekly and Daily Rotation",
	"WeeklyAndDailyRotations.tooltip.message":
		"The dates and the days identified below correspond to production days and not calendar days. The specific dates and days are selected based on the Production Day Start Time and the logic for displaying shifts would correspond for the entire production day and not calendar day. For example, if the Production Day duration is from 19:00 to 07:00, since this duration crosses midnight, if the date January 1 is a Monday, then Production Day 1 would begin on Monday at 19:00 and end on Tuesday at 07:00. In the view below, all shifts for Production Day 1 will also be listed under Monday, January 1.",
	"WeeklyAndDailyRotations.RotationsCalendar.upload.info":
		"If you have already uploaded an XLSX file, clicking this icon again will allow you to upload a new XLSX file that will replace the previous file. All data from the first file will be removed.",
	"WeeklyAndDailyRotation.RotationsCalendar.DailyShifts.validation":
		"Please assign a different crew for Shift",
	"WeeklAndDailyRotations.warning.newUnassignedRotationExists":
		"You have a rotation that has not been assigned to any weeks. Upload an updated weekly rotation file to identify the weekly rotation schedule.",
	"WeeklAndDailyRotations.error.rotationsNotPresentForNextDay":
		"No future rotations have been identified. Upload an updated weekly rotation file immediately to identify the future weekly rotation schedule.",
	"WeeklAndDailyRotations.warning.rotationsNotPresentForNextWeek":
		"No rotations have been identified for next week and beyond. Upload an updated weekly rotation file to identify the future weekly rotation schedule.",
	"WeeklAndDailyRotations.RotationsCalendar.upload.title":
		"Upload Weekly Rotation XLSX",
	"WeeklAndDailyRotations.tabTitle": "Weekly and Daily Rotation",
	"WeeklAndDailyRotations.RotationsCalendar.upload.fileUploadError":
		"Error occured while uploading file",
	"WeeklAndDailyRotations.RotationsCalendar.none": "None",
	"WeeklyAndDailyRotations.RotationsCalendar.button.today": "Today",
	"WeeklyAndDailyRotation.RotationsCalendar.week": "Week",
	"WeeklyAndDailyRotation.RotationsCalendar.shift": "Shift",
	"WeeklyAndDailyRotation.RotationsCalendar.rotation": "Rotation",
	"WeeklyAndDailyRotation.RotationsCalendar.dailyRotation": "Daily Rotation",
	"WeeklyAndDailyRotation.RotationsCalendar.weeklyRotation": "Weekly Rotation",
	"WeeklyAndDailyRotation.RotationsCalendar.weekly": "Weekly",
	"WeeklyAndDailyRotation.RotationsCalendar.daily": "Daily",
	"WeeklyAndDailyRotation.button.exportData": "Export Data",
	"WeeklyAndDailyRotation.button.uploadXLSX": "Upload XLSX",
	"WeeklyAndDailyRotation.RotationsCalendar.title.selectCrew": "Select Crew",
	"WeeklyAndDailyRotation.RotationsCalendar.title.selectRotation":
		"Select Rotation",
	"WeeklyAndDailyRotation.onNextClick.error":
		"Please upload Weekly and Daily Rotations XLSX file before clicking on Next.",
	"WeeklyAndDailyRotation.error.InterdependecyAction":
		"A previously assigned rotation was deleted. Re-Upload an updated weekly rotation file to identify the weekly rotation schedule.",

	"SFF.dummyText": "This is a dummy text 1",

	// Rotations
	"ROTATIONS.WeekDays.header.Monday": "Mon",
	"ROTATIONS.WeekDays.header.Tuesday": "Tue",
	"ROTATIONS.WeekDays.header.Wednesday": "Wed",
	"ROTATIONS.WeekDays.header.Thursday": "Thu",
	"ROTATIONS.WeekDays.header.Friday": "Fri",
	"ROTATIONS.WeekDays.header.Saturday": "Sat",
	"ROTATIONS.WeekDays.header.Sunday": "Sun",
	"ROTATIONS.ProductionDay.caption.startTime": "Production Day Start Time",
	"ROTATIONS.ProductionDay.caption.endTime": "Production Day End Time",
	"ROTATION.productionDay.caption.sameDay": "Same Calendar Day",
	"ROTATION.productionDay.caption.nextDay": "Next Calendar Day",
	"ROTATIONS.ProductionDays.header.productionDay1": "Production Day 1",
	"ROTATIONS.ProductionDays.header.productionDay2": "Production Day 2",
	"ROTATIONS.ProductionDays.header.productionDay3": "Production Day 3",
	"ROTATIONS.ProductionDays.header.productionDay4": "Production Day 4",
	"ROTATIONS.ProductionDays.header.productionDay5": "Production Day 5",
	"ROTATIONS.ProductionDays.header.productionDay6": "Production Day 6",
	"ROTATIONS.ProductionDays.header.productionDay7": "Production Day 7",
	"ROTATIONS.noRotations.message": "No Rotations Found",
	"ROTATIONS.rotationAction.addRotation.inProgress": "adding...",
	"GENERAL_CONFIGURATIONS.ROTATIONS.NOTIFICATION.customTitle":
		"Delete Rotation?",
	"GENERAL_CONFIGURATIONS.ROTATIONS.ADD_NOTIFICATION.customTitle":
		"Add Rotation?",
	"GENERAL_CONFIGURATIONS.ROTATIONS.ADD_NOTIFICATION.noteText":
		"Adding Rotation will affect the rest of the steps.",
	"GENERAL_CONFIGURATIONS.ROTATIONS.NOTIFICATION.noteText":
		"Deleting a Rotation will affect the rest of the steps.",
	"GENERAL_CONFIGURATIONS.ROTATIONS.NOTIFICATION.customText.inProgress":
		"Rotation is being deleted...",
	"GENERAL_CONFIGURATIONS.ROTATIONS.ADD_NOTIFICATION.customText.inProgress":
		"Rotation is being added...",
	"GENERAL_CONFIGURATIONS.Rotation.NOTIFICATION.customText":
		"Do you wish to delete",
	"GENERAL_CONFIGURATIONS.Rotation.ADD_NOTIFICATION.customText":
		"Do you wish to create a new Rotation?",
	"ROTATIONS.deleteRotation.success": "has been deleted successfully",
	"ROTATIONS.deleteRotation.error":
		"Oops there was problem deleting this rotation!",
	"ROTATIONS.addRotation.success": "New Rotation Created Successfully",
	"ROTATIONS.addRotation.error": "There was an error creating new rotations",
	"ROTATIONS.updateRotation.fieldErrors": "Please fix errors and submit again",
	"ROTATIONS.updateRotation.unassignedCrews":
		"Crews need to be assigned to proceed to next step",
	"ROTATIONS.sameCrewError":
		"Crew is already assigned. Select a different crew for this shift.",
	"ROTATIONS.sameRotationError":
		"You have multiple rotations with the same crew assignments. Please update the assignment(s) so that each rotation is different.",
	"ROTATIONS.emptyFieldError": "Required field",
	"ROTATIONS.updateRotationsFailed": "There was a problem updating Rotation",
	"ROTATION.errorInfo.crewAssignment":
		"Please identify any missing crew assignments",
	"FGC.save": "Save",

	// Navigation Modal
	"NavigationModal.title": "Confirm!",
	"NavigationModal.button.save": "Save",
	"NavigationModal.button.discard": "Discard",
	"NavigationModal.customText": "You have unsaved changes.",
	"NavigationModal.noteText": "Do you want to save or discard your changes?",

	"InterDependencyModal.title": "General Configuration is incomplete.",
	"InterDependencyModal.button.discard": "Discard",
	"InterDependencyModal.button.continueEditing": "Continue Editing",
	"InterDependencyModal.confirmText":
		"Do you want to continue editing or discard changes?",
	"InterDependencyModal.shiftAddAndCrewDelete.text": `Since a shift was added and an already assigned crew was deleted, you must also complete step ${GENERAL_CONFIGURATION_STEPS.ROTATION} of General Configuration to apply your changes.`,
	"InterDependencyModal.crewDelete.text": `Since an already assigned crew was deleted, you must also complete step ${GENERAL_CONFIGURATION_STEPS.ROTATION} of General Configuration to apply your changes.`,
	"InterDependencyModal.shiftAdd.text": `Since a new shift was added, you must also complete step ${GENERAL_CONFIGURATION_STEPS.ROTATION} of General Configuration to apply your changes.`,
	"InterDependencyModal.rotationDelete.text": `Since an already assigned rotation was deleted, you must also complete step ${GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION} of General Configuration to apply your changes.`,
	"InterDependencyModal.shiftAddAndRotationDelete.text": `Since a shift was added and an already assigned rotation was deleted, you must also complete steps ${GENERAL_CONFIGURATION_STEPS.ROTATION}-${GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION} of General Configuration to apply your changes.`,
	"InterDependencyModal.crewDeleteAndRotationDelete.text": `Since an already assigned crew was deleted and an already assigned rotation was deleted, you must also complete steps ${GENERAL_CONFIGURATION_STEPS.ROTATION}-${GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION} of General Configuration to apply your changes.`,
	"InterDependencyModal.shiftAddAndCrewRotationDelete.text": `Since a shift was added, an already assigned crew was deleted and an already assigned rotation was deleted, you must also complete steps ${GENERAL_CONFIGURATION_STEPS.ROTATION}-${GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION} of General Configuration to apply your changes.`,

	// Shift Edit modal
	"ShiftEditModal.title.shift": "Shift Details",
	"ShiftEditModal.title.subShift": "Sub-Shift Details",
};

export default MESSAGE_STRINGS;
