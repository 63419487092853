/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { styled } from "@mui/material";
import { format, isValid } from "date-fns";
import { isEmpty } from "lodash";

const StyledScheduleTimeLineContainer = styled("div")(({ theme }) => ({
	backgroundColor: "#121212",
	display: "flex",
	width: "100%",
	borderBottomRightRadius: "0.25rem",
	"& .bar": {
		fill: theme.palette.background.infoColor,
		"&:hover": {
			opacity: 0.9,
		},
	},
	"& .subShift": {
		fill: theme.customColors.exploreBlue,
		"&:hover": {
			opacity: 0.9,
		},
	},
	"& .subShiftBreak, .shiftBreak": {
		fill: "#0076A8",
		"&:hover": {
			opacity: 0.9,
		},
	},
	"& text": {
		fill: "#5E5E5E",
	},
	"& path": {
		// stroke: "white",
	},
	"& .xaxis": {
		color: "transparent",
		fontSize: "0.75rem",
	},
	"& .grid path": {
		strokeWidth: 0,
	},
	"& .grid line": {
		stroke: "#373737",
		strokeOpacity: 0.3,
	},
	"& line#productionDayStartLine": {
		stroke: "#E3E48D",
		strokeWidth: 3,
	},
	"& line#calendarDayChange": {
		stroke: "#fff",
		strokeWidth: 3,
	},
	"& line#productionDayEndLine": {
		stroke: "#6FC2B4",
		strokeWidth: 3,
	},
	".timeline-tooltip": {
		position: "absolute",
		backgroundColor: "#181818",
		border: ".0625rem solid #2D3338",
		borderRadius: ".313rem",
		padding: "0.313rem",
		fontFamily: "Open Sans",
		fontSize: ".75rem",
		fontWeight: "normal",
		lineHeight: "1rem",
		zIndex: 1000,
		visibility: "hidden",
	},
}));

const getBarHeight = (bar) => (bar.type === "subShift" ? 10 : 20);
const getBreakClassName = (breakObj) =>
	breakObj.type === "subShift" ? "subShiftBreak" : "shiftBreak";
const getBarClassName = (bar) => (bar.type === "subShift" ? "subShift" : "bar");
function getTooltipData(hierarchyData, selectedHierarchies, tooltipDataConst) {
	if (selectedHierarchies[hierarchyData?.entityId]) {
		tooltipDataConst.push(
			`${hierarchyData.entityNumber ?? hierarchyData?.entityType} - ${
				hierarchyData.entityName
			}`
		);
	} else if (hierarchyData?.entityChildren?.length !== 0) {
		hierarchyData?.entityChildren?.forEach((child) =>
			getTooltipData(child, selectedHierarchies, tooltipDataConst)
		);
	}
}
const showTooltip = (event, tooltipDiv, d, hierarchyData) => {
	if (tooltipDiv && (d?.breaks?.length || !isEmpty(d.selectedHierarchy))) {
		d3.select(tooltipDiv)
			.transition()
			.duration(200)
			.style("opacity", 0.9)
			.style("visibility", "visible");
		d3.select(tooltipDiv)
			.style("top", `${event.pageY - 10}px`)
			.style("left", `${event.pageX + 10}px`)
			.html(() => {
				const breaks = d?.breaks?.map(
					(s) =>
						`${s.name} : ${format(s.formattedStartTime, "HH:mm")} to ${format(
							s.formattedEndTime,
							"HH:mm"
						)}</br>`
				);
				const tooltipDataConst = [];
				getTooltipData(hierarchyData, d?.selectedHierarchy, tooltipDataConst);

				const slicedTooltipData = tooltipDataConst?.slice(0, 10);
				const restLength = tooltipDataConst.length - slicedTooltipData.length;
				if (restLength > 0) {
					slicedTooltipData.push(`${restLength} more...`);
				}
				const hierarchyTooltipData = slicedTooltipData?.join("</br>");
				const breaksTooltipData = breaks?.join("");
				return `${breaksTooltipData}\n${hierarchyTooltipData}`;
			});
	}
};
// eslint-disable-next-line react/prop-types
function ScheduleTimeLine({
	startTime,
	endTime,
	graphTimeLineStart,
	graphTimeLineEnd,
	dayChangeTime,
	shifts = [],
	hierarchyData,
}) {
	const timeLineRef = React.useRef();

	const tooltipRef = React.useRef();
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(() => shifts.length * 53);
	const draw = React.useCallback(
		function draw(heightRange, widthRange, myShifts = []) {
			d3.selectAll("g").remove();

			// Three function that change the tooltip when user hover / move / leave a cell
			const mouseover = (event, d) => {
				const tooltipDiv = tooltipRef.current;
				showTooltip(event, tooltipDiv, d, hierarchyData);
			};

			const mouseout = () => {
				const tooltipDiv = tooltipRef.current;
				if (tooltipDiv) {
					d3.select(tooltipDiv)
						.transition()
						.duration(500)
						.style("opacity", 0)
						.style("visibility", "hidden");
				}
			};

			const svg = d3.select(timeLineRef.current);
			const chart = svg
				.append("g")
				.attr("transform", `translate(${14}, ${30})`);

			const yScale = d3
				.scaleBand()
				.range([0, heightRange])
				.domain(myShifts.map((shift) => shift.id));
			// .padding(0.2);
			const xScale = d3
				.scaleTime()
				.domain([graphTimeLineStart, graphTimeLineEnd])
				.range([0, widthRange - 0.05 * widthRange]);

			chart
				.append("g")
				.attr("class", "xaxis")
				.call(
					d3
						.axisTop(xScale)
						.ticks(24)
						.tickFormat((d, i) => {
							if (xScale.ticks(24).length > 26 && i % 2 !== 0) return "";
							return `${String(d.getHours()).padStart(2, 0)}:${String(
								d.getMinutes()
							).padEnd(2, 0)}`;
						})
				);

			chart
				.append("g")
				.attr("class", "grid")
				.attr("transform", `translate(0, ${heightRange + 53})`)
				.call(
					d3
						.axisBottom()
						.scale(xScale)
						.ticks(24)
						.tickSize(-heightRange - 53)
						.tickFormat("")
				);

			chart
				.append("g")
				.attr("class", "grid")
				.call(
					d3.axisLeft().scale(yScale).tickSize(-widthRange, 0, 0).tickFormat("")
				);

			chart
				.selectAll()
				.data(myShifts)
				.enter()
				.append("rect")
				.attr("data-testid", "timeline-bars")
				.attr("class", getBarClassName)
				.attr("x", (s) => xScale(s.formattedStartTime))
				.attr("y", (s) => yScale(s.id) + 40)
				.attr("rx", 2)
				.attr("height", getBarHeight)
				.attr(
					"width",
					(s) => xScale(s.formattedEndTime) - xScale(s.formattedStartTime)
				)
				.on("mouseover", mouseover)
				.on("mouseout", mouseout);

			chart
				.selectAll()
				.data(myShifts)
				.enter()
				.each(function addBreak(d, i) {
					if (d.breaks) {
						d3.select(this)
							.selectAll()
							.data(d.breaks)
							.enter()
							.append("rect")
							.attr("data-testid", "timeline-breaks")
							.attr("class", getBreakClassName(d))
							.attr("x", (s) => {
								return xScale(s.formattedStartTime);
							})
							.attr("y", () => yScale(d.id) + 40)
							.attr("rx", 2)
							.attr("height", getBarHeight(d))
							.attr(
								"width",
								(s) => xScale(s.formattedEndTime) - xScale(s.formattedStartTime)
							)
							.on("mouseover", (e) => mouseover(e, d))
							.on("mouseout", mouseout);
					}
				});

			if (isValid(startTime)) {
				chart
					.append("line")
					.attr("id", "productionDayStartLine")
					.attr("x1", 0)
					.attr("y1", 0)
					.attr("x2", 0)
					.attr("y2", heightRange + 53);
			}
			if (isValid(dayChangeTime)) {
				chart
					.append("line")
					.attr("id", "calendarDayChange")
					.attr("x1", xScale(dayChangeTime))
					.attr("y1", 0)
					.attr("x2", xScale(dayChangeTime))
					.attr("y2", heightRange + 53);
			}
			if (isValid(endTime)) {
				chart
					.append("line")
					.attr("id", "productionDayEndLine")
					.attr("x1", xScale(endTime))
					.attr("y1", 0)
					.attr("x2", xScale(endTime))
					.attr("y2", heightRange + 53);
			}
		},
		[
			dayChangeTime,
			endTime,
			graphTimeLineEnd,
			graphTimeLineStart,
			hierarchyData,
			startTime,
		]
	);

	// height of graph 159 53 * 3

	React.useEffect(() => {
		function handleResize() {
			const targetWidth = d3
				.select(timeLineRef.current.parentElement)
				.node()
				.getBoundingClientRect().width;
			d3.select(timeLineRef.current)
				.attr("width", targetWidth)
				.attr("height", 53 + 53 * shifts.length);
			setWidth(targetWidth);
			setHeight(53 * shifts.length);
		}
		window.addEventListener("resize", handleResize);

		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [shifts.length]);

	React.useEffect(() => {
		draw(height, width, shifts);
	}, [draw, height, shifts, width]);
	return (
		<StyledScheduleTimeLineContainer>
			<div className="timeline-tooltip" ref={tooltipRef} />
			<svg ref={timeLineRef} />
		</StyledScheduleTimeLineContainer>
	);
}

ScheduleTimeLine.propTypes = {
	startTime: PropTypes.instanceOf(Date),
	endTime: PropTypes.instanceOf(Date),
	graphTimeLineStart: PropTypes.instanceOf(Date),
	graphTimeLineEnd: PropTypes.instanceOf(Date),
	dayChangeTime: PropTypes.instanceOf(Date),
	shifts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			// startTime: PropTypes.instanceOf(Date),
			// endTime: PropTypes.instanceOf(Date),
		})
	),
	hierarchyData: PropTypes.instanceOf(Object),
};
export default ScheduleTimeLine;
