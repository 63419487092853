import React from "react";
import PropTypes from "prop-types";
import useGeneralTable from "../../hooks/useGeneralTable";
import BasicGeneralTable from "./BasicGeneralTable";
import TablePaginationFooter from "./TablePaginationFooter";
import BasicTableError from "./BasicTableError";
import DefaultEmptyTable from "./DefaultEmptyTable";

/**
 * BasicGeneralTable is just a dummy component - renders the UI.
 */

export const GeneralTableContext = React.createContext();
export const useGeneralTableContext = () =>
	React.useContext(GeneralTableContext);

function GeneralTable({
	data = [],
	columns = [],
	isLoading,
	isError,
	// eslint-disable-next-line no-unused-vars
	// isSuccess,
	showHeader = true,
	enablePagination = false,
	tableInitState = null,
	onClickNextPage = null,
	onClickPrevPage = null,
	mappedMethods = {},
}) {
	const instance = useGeneralTable({
		data,
		columns,
		isPaginated: !!enablePagination,
		initialState: tableInitState,
	});

	function handleOnNextPage(e) {
		if (!onClickNextPage) {
			instance.nextPage(e);
		} else {
			onClickNextPage(e);
		}
	}

	function handleOnPrevPage(e) {
		if (!onClickPrevPage) {
			instance.previousPage(e);
		} else {
			onClickPrevPage(e);
		}
	}

	return (
		<GeneralTableContext.Provider value={mappedMethods}>
			<BasicGeneralTable
				{...instance}
				showHeader={showHeader}
				showFetchingUI={isLoading}
				enablePagination={enablePagination}
				rows={enablePagination ? instance.page : instance.rows}
				ErrorComponent={BasicTableError}
				EmptyComponent={DefaultEmptyTable}
				showErrorUI={isError}
			/>
			{enablePagination && (
				<TablePaginationFooter
					pageSize={instance.state.pageSize}
					setPageSize={instance.setPageSize}
					pageIndex={instance.state.pageIndex}
					pageLength={instance.page.length}
					rowsLength={instance.rows.length}
					canPreviousPage={instance.canPreviousPage}
					canNextPage={instance.canNextPage}
					previousPage={handleOnPrevPage}
					nextPage={handleOnNextPage}
				/>
			)}
		</GeneralTableContext.Provider>
	);
}

GeneralTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	columns: PropTypes.arrayOf(PropTypes.shape({})),
	isLoading: PropTypes.bool,
	isError: PropTypes.bool,
	// isSuccess: PropTypes.bool,
	showFetchingUI: PropTypes.bool,
	showHeader: PropTypes.bool,
	enablePagination: PropTypes.bool,
	tableInitState: PropTypes.shape({
		pageIndex: PropTypes.number,
	}),
	onClickNextPage: PropTypes.func,
	onClickPrevPage: PropTypes.func,
	mappedMethods: PropTypes.shape({}),
};

export default GeneralTable;
