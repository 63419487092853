export const PRODUCTION_DAY_TABLE_ACCESSORS = {
	PRODUCTION_DAY_BLANK: "productionDayBlank",
	START_TIME: "startTime",
	ENDS_ON: "endsOn",
	END_TIME: "endTime",
	TIMEZONE: "timezone",
};

export const ENDS_ON_VALUES = {
	SAME_DAY: "SAME_DAY",
	NEXT_DAY: "NEXT_DAY",
};

export const TIME_FIELD_TYPES = {
	START_TIME: "START_TIME",
	END_TIME: "END_TIME",
};

export const SCHEDULE_TEST_ID = {
	PRODUCTION_DAY_START_TIME: "production-day-start-time",
	PRODUCTION_DAY_END_TIME: "production-day-end-time",
	PRODUCTION_DAY_ENDS_ON: "production-day-ends-on",
	PRODUCTION_DAY_ENDS_ON_ITEMS: "production-day-ends-on-items",
	PRODUCTION_DAY_TIMEZONE: "production-day-timezone",
	PRODUCTION_DAY_TIMEZONE_ITEMS: "production-day-timezone-items",
	PRODUCTION_DAY_HEADER_START_TIME: "production-day-header-start-time",
	PRODUCTION_DAY_HEADER_END_TIME: "production-day-header-end-time",
	PRODUCTION_DAY_HEADER_ENDS_ON: "production-day-header-ends-on",
	PRODUCTION_DAY_HEADER_TIME_ZONE: "production-day-header-timezone",
	PRODUCTION_DAY_ERRORS: "production-day-errors",
	PRODUCTION_DAY_LABEL: "production-day-label",
};

export const SHIFT_TEST_ID = {
	HEADER_ST: "shift-header-start-time",
	HEADER_ET: "shift-header-end-time",
	HEADER_NAME: "shift-header-shift-name",
	HEADER_ACTION: "shift-header-action",
	ADD: "shift-add-button",
	DELETE: "shift-delete-button",
	START_TIME: "shift-start-time",
	END_TIME: "shift-end-time",
	SHIFT_NAME: "shift-shift-name",
	ERROR_ST: "shift-error-start-time",
	ERROR_ET: "shift-error-end-time",
	ERROR_NAME: "shift-error-shift-name",
	SHIFTS_LABEL: "shifts-label",
	LEGEND_START: "production-day-start",
	LEGEND_END: "production-day-end",
};
