import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { InputAdornment, styled, TextField } from "@mui/material";

const CustomizedTextField = styled(TextField, {
	shouldForwardProp: (prop) => prop !== "textAlign",
})(({ theme, textAlign }) => ({
	"& label.Mui-focused": {
		color: theme.palette.text.primary,
	},
	"& .MuiOutlinedInput-input": {
		// padding: "0rem .75rem",
		textAlign,
		color: theme.palette.text.primary,
		fontSize: "0.875rem",
		"&.Mui-disabled": {
			color: theme.palette.text.shuttleGray,
			WebkitTextFillColor: "inherit", // Was overriding given disabled color
		},
	},
	"& .MuiTypography-colorTextSecondary": {
		color: theme.palette.text.primary,
	},
	"& .MuiFormHelperText-root.Mui-error": {
		color: theme.palette.text.errorText,
		fontSize: "0.75rem",
		marginLeft: "0.2rem",
		minWidth: "100%",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: theme.palette.background.configLighterDark,
		height: "2rem",
		"& fieldset": {
			borderColor: theme.palette.border.lightWhiteGrey,
		},
		"&:hover fieldset": {
			borderColor: theme.palette.border.primary,
		},
		"&.Mui-focused fieldset": {
			borderColor: theme.palette.selected.greenSelect,
		},
		"&.Mui-disabled fieldset": {
			borderColor: theme.palette.border.darkGrey,
		},
	},
	"& .MuiFormHelperText-root": {
		color: theme.palette.text.primary,
		marginLeft: "0.2rem",
	},
	"& .MuiInputAdornment-root .MuiTypography-root": {
		color: theme.palette.text.primary,
	},
}));

const GeneralTextField = forwardRef(
	(
		{
			value,
			variant = "outlined",
			error = false,
			helperText = "",
			type = "text",
			onChange,
			suffixText = "",
			maxLength = 32,
			placeholder = "",
			customInputProps = {},
			fullWidth = true,
			textAlign = "left",
			isPlaceholderItalic = true,
			...other
		},
		ref
	) => {
		return (
			<CustomizedTextField
				ref={ref}
				data-testid="input-textfield"
				value={value}
				variant={variant}
				error={error}
				helperText={helperText}
				type={type}
				onChange={onChange}
				fullWidth={fullWidth}
				placeholder={placeholder}
				InputProps={{
					autoComplete: "off",
					endAdornment: suffixText && (
						<InputAdornment position="end">{suffixText}</InputAdornment>
					),
				}}
				// eslint-disable-next-line react/jsx-no-duplicate-props
				inputProps={{
					sx: {
						"&::placeholder": {
							fontStyle: isPlaceholderItalic ? "italic" : "inherit",
						},
					},
					maxLength,
					form: {
						autocomplete: "off",
					},
					...customInputProps,
				}}
				textAlign={textAlign}
				{...other}
			/>
		);
	}
);

GeneralTextField.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	variant: PropTypes.string,
	onChange: PropTypes.func,
	error: PropTypes.bool,
	helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	type: PropTypes.string,
	suffixText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	maxLength: PropTypes.number,
	placeholder: PropTypes.string,
	customInputProps: PropTypes.shape({}),
	fullWidth: PropTypes.bool,
	textAlign: PropTypes.string,
	isPlaceholderItalic: PropTypes.bool,
};

export default GeneralTextField;

// Use sx prop,Box or FormControl component to control width

// Example:
// <GeneralTextField value="" onChange={} sx={{ width: 160 }} />
// :Example
