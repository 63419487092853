/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useMemo } from "react";
import {
	flexRender,
	getCoreRowModel,
	useReactTable,
	getExpandedRowModel,
} from "@tanstack/react-table";

import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import {
	StyledCheckBox,
	StyledHierarchyContainer,
	StyledHierarchyHeader,
	StyledSubHeading,
} from "../styles";
import BlockLevelIndicator from "../../../../components/BlockLevelIndicator";
import {
	INDICATOR_SIZE,
	SHIFT_EDIT_REDUCER_CONSTANTS,
} from "../../../../constants";

const findNode = (entityId, data) => {
	const queue = [...data];
	while (queue.length > 0) {
		const currNode = queue.shift();
		if (currNode.entityId === entityId) {
			return currNode;
		}
		if (currNode.entityChildren) {
			queue.push(...currNode.entityChildren);
		}
	}
	return {}; // Target node not found
};
const toggleDescendants = (nodeList, node) => {
	if (!node.entityChildren?.length) {
		return;
	}
	const { checked } = node;
	node.entityChildren.forEach((n) => {
		n.checked = checked;
		nodeList[n.entityId] = checked;
		toggleDescendants(nodeList, n);
	});
};
const updateAncestors = (nodeList, node, data) => {
	if (!node.parentEntityId) {
		return;
	}

	const parent = findNode(node.parentEntityId, data);
	if (nodeList[parent.entityId] && !nodeList[node.entityId]) {
		parent.checked = false;
		nodeList[parent.entityId] = false;
		updateAncestors(nodeList, parent, data);
	}

	if (!nodeList[parent.entityId] && nodeList[node.entityId]) {
		if (parent.entityChildren.every((n) => nodeList[n.entityId])) {
			parent.checked = true;
			nodeList[parent.entityId] = true;
			updateAncestors(nodeList, parent, data);
		}
	}
};
const handleBoxChecked = (e, item, selectedHierarchy, dispatch, data) => {
	const nodeList = { ...selectedHierarchy };
	const node = findNode(item.entityId, data);
	const { checked } = e.currentTarget;
	nodeList[node.entityId] = e.currentTarget.checked;
	node.checked = checked;

	toggleDescendants(nodeList, node);
	updateAncestors(nodeList, node, data);
	dispatch({
		type: SHIFT_EDIT_REDUCER_CONSTANTS.SELECT_HIERARCHY,
		payload: { newHierarchy: nodeList },
	});
};
function HierarchyCell({
	row,
	getValue,
	selectedHierarchy,
	dispatch,
	data,
	parentHierarchy,
	shiftType,
}) {
	const isSubShift = shiftType === "subShift";
	return (
		<Box display="flex" alignItems="center" height="3rem">
			<StyledCheckBox
				data-testid="hierarchy-checkbox"
				{...{
					checked: selectedHierarchy[row.original.entityId],
					onChange: (e) =>
						handleBoxChecked(
							e,
							row.original,
							selectedHierarchy,
							dispatch,
							data
						),
				}}
				disabled={isSubShift && !parentHierarchy[row.original.entityId]}
			/>
			{row.getCanExpand() ? (
				<Box
					sx={{
						marginLeft: "1rem",
						paddingLeft: `${row.depth * 2}rem`,
					}}
					display="flex"
					alignItems="center"
				>
					<IconButton
						onClick={row.getToggleExpandedHandler()}
						sx={{ color: "white" }}
						data-testid="hierarchy-expand-button"
					>
						{row.getIsExpanded() ? (
							<ExpandMore color="white" />
						) : (
							<ChevronRight color="white" />
						)}
					</IconButton>
					{getValue()}
				</Box>
			) : (
				<Box
					display="flex"
					alignItems="center"
					marginLeft="1rem"
					sx={{
						paddingLeft: `${row.depth * 2 + 1.5}rem`,
					}}
				>
					{getValue()}
				</Box>
			)}
		</Box>
	);
}
const getColumns = (
	dispatch,
	selectedHierarchy,
	data,
	parentHierarchy,
	shiftType
) => [
	{
		id: "entityName",
		header: "",
		accessorKey: "entityName",
		accessorFn: ({ entityNumber, entityType, entityName }) =>
			entityType === "Plant"
				? `Plant: ${entityName}`
				: `${entityNumber} - ${entityName}`,
		cell: (props) => (
			<HierarchyCell
				{...props}
				selectedHierarchy={selectedHierarchy}
				dispatch={dispatch}
				data={data}
				parentHierarchy={parentHierarchy}
				shiftType={shiftType}
			/>
		),
	},
];

function HierarchySelection({
	data = [],
	selectedHierarchy = {},
	dispatch,
	width,
	errorMessage,
	parentHierarchy = {},
	shiftType,
}) {
	return (
		<>
			<StyledSubHeading>Select Equipment</StyledSubHeading>
			{errorMessage && (
				<BlockLevelIndicator
					sx={{ marginTop: ".75rem" }}
					width="fit-content"
					variant={INDICATOR_SIZE.SMALL}
				>
					{errorMessage}
				</BlockLevelIndicator>
			)}
			<StyledHierarchyContainer width={width}>
				<StyledHierarchyHeader>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.lightUnitGrey,
							marginLeft: "4rem",
							fontSize: ".875rem",
						}}
					>
						Equipment Hierarchy
					</Typography>
				</StyledHierarchyHeader>
				<MemoizedHierarchyTable
					data={data}
					selectedHierarchy={selectedHierarchy}
					dispatch={dispatch}
					parentHierarchy={parentHierarchy}
					shiftType={shiftType}
				/>
			</StyledHierarchyContainer>
		</>
	);
}
function HierarchyTable({
	data,
	selectedHierarchy = {},
	dispatch,
	parentHierarchy,
	shiftType,
}) {
	const columns = useMemo(
		() =>
			getColumns(dispatch, selectedHierarchy, data, parentHierarchy, shiftType),
		[dispatch, selectedHierarchy, data, parentHierarchy, shiftType]
	);
	const [expanded, setExpanded] = React.useState({});
	const table = useReactTable({
		data,
		columns,
		state: {
			expanded,
		},
		enableSubRowSelection: true,
		onExpandedChange: setExpanded,
		getExpandedRowModel: getExpandedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSubRows: (originalRow) => originalRow.entityChildren,
	});
	useEffect(() => {
		table.toggleAllRowsExpanded();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Box
			height="100%"
			minHeight={300}
			maxHeight={300}
			sx={{ overflowY: "scroll" }}
		>
			<TransitionGroup>
				{table.getRowModel().rows.map((row) => {
					return (
						<Collapse key={row.id}>
							<Box key={row.id}>
								{row.getVisibleCells().map((cell) => {
									return (
										<Box key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</Box>
									);
								})}
							</Box>
						</Collapse>
					);
				})}
			</TransitionGroup>
		</Box>
	);
}
const MemoizedHierarchyTable = memo(
	HierarchyTable,
	(prevProps, nextProps) =>
		prevProps.selectedHierarchy === nextProps.selectedHierarchy
);

HierarchySelection.propTypes = {};
const customComparator = (prevProps, nextProps) => {
	return (
		prevProps.selectedHierarchy === nextProps.selectedHierarchy &&
		prevProps.errorMessage === nextProps.errorMessage
	);
};

const MemoizedHierarchySelection = memo(HierarchySelection, customComparator);

export default MemoizedHierarchySelection;
