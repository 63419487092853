import { useState, useEffect } from "react";
import { Box, MenuItem, Typography, styled, alpha } from "@mui/material";
import PropTypes from "prop-types";
import InlineErrorMessages from "../../../components/InlineErrorMessages";
import TimePicker from "../../../components/TimePicker";
import GeneralDropdown from "../../../components/GeneralDropdown";
import MESSAGE_STRINGS from "../../../constants/en-us";
import {
	ENDS_ON_VALUES,
	TIME_FIELD_TYPES,
	SCHEDULE_TEST_ID,
} from "../ScheduleConstants";
import { DROPDOWN_CONSTANTS } from "../../../constants";

function getSameDayValue(isSameDay, isEndsOnEmpty) {
	if (isEndsOnEmpty) return DROPDOWN_CONSTANTS.DEFAULT_VALUE;
	return isSameDay ? ENDS_ON_VALUES.SAME_DAY : ENDS_ON_VALUES.NEXT_DAY;
}

const HeaderContainer = styled(Box)(({ theme }) => ({
	background: theme.palette.background.darkishBlackBlue,
	display: "flex",
	alignItems: "center",
	width: "100%",
	padding: "0 1rem",
	minHeight: "3.5rem",
	border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
	borderBottom: "none",
	borderRadius: ".25rem .25rem 0 0",
}));

const ValuesContainer = styled(Box)(({ theme }) => ({
	background: theme.palette.background.blackGrey,
	display: "flex",
	alignItems: "center",
	padding: "0.5rem 1rem",
	border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
	borderBottomRightRadius: 4,
	borderBottomLeftRadius: 4,
	minHeight: "3.5rem",
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
	background: theme.palette.background.blackGrey,
	display: "flex",
	alignItems: "center",
	padding: "0.5rem 1rem",
	border: `1px solid ${alpha(theme.palette.background.darkGrey, 0.3)}`,
	borderBottomRightRadius: 4,
	borderBottomLeftRadius: 4,
}));

const HeaderLabel = styled(Typography)(() => ({
	fontSize: "1rem",
}));

function ProductionDay(props) {
	const {
		startTime = {},
		endTime = {},
		timezone = {},
		endsOn = {},
		isEndsOnEmpty = false,
		handleProductionDayTimeChange = () => null,
		handleSameDayChange = () => null,
		handleTimezoneChange = () => null,
		timezones = [],
		showProductionErrorsOnNext = { productionDay: false, shifts: {} },
	} = props;

	const [visible, setVisible] = useState({
		startTime: { dirty: false, blur: false },
		endTime: { dirty: false, blur: false },
		endsOn: { dirty: false, blur: false },
		timezone: { dirty: false, blur: false },
	});

	useEffect(() => {
		if (showProductionErrorsOnNext.productionDay)
			setVisible({
				startTime: { dirty: true, blur: true },
				endTime: { dirty: true, blur: true },
				endsOn: { dirty: true, blur: true },
				timezone: { dirty: true, blur: true },
			});
	}, [showProductionErrorsOnNext.productionDay]);

	function errorBoxValidations() {
		return (
			(startTime.isError && visible.startTime.blur) ||
			(endTime.isError && visible.endTime.blur) ||
			(timezone.isError && visible.timezone.blur) ||
			(endsOn.isError && visible.endsOn.blur)
		);
	}

	function handleErrorVisibilityChange(type, field) {
		if (type === "blur" && visible[field].dirty) {
			setVisible((prev) => {
				return {
					...prev,
					[field]: { ...prev[field], blur: true },
				};
			});
		} else if (type === "dirty") {
			setVisible((prev) => {
				return {
					...prev,
					[field]: { ...prev[field], dirty: true },
				};
			});
		}
	}

	return (
		<>
			<Box sx={{ marginTop: "1rem" }}>
				<HeaderContainer
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						bgcolor: "background.darkishBlackBlue",
						paddingX: "1rem",
						minHeight: "3.5rem",
					}}
				>
					<Box sx={{ width: "20%" }} />
					<Box sx={{ width: "20%" }}>
						<HeaderLabel
							variant="h4"
							data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_HEADER_START_TIME}
						>
							Starts on
						</HeaderLabel>
					</Box>
					<Box sx={{ width: "20%" }}>
						<HeaderLabel
							variant="h4"
							data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_HEADER_START_TIME}
						>
							{MESSAGE_STRINGS["Schedule.ProductionDay.tableHeader.startTime"]}
						</HeaderLabel>
					</Box>
					<Box sx={{ width: "20%" }}>
						<HeaderLabel
							variant="h4"
							data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_HEADER_ENDS_ON}
						>
							{MESSAGE_STRINGS["Schedule.ProductionDay.tableHeader.endsOn"]}
						</HeaderLabel>
					</Box>
					<Box sx={{ width: "20%" }}>
						<HeaderLabel
							variant="h4"
							data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_HEADER_END_TIME}
						>
							{MESSAGE_STRINGS["Schedule.ProductionDay.tableHeader.endTime"]}
						</HeaderLabel>
					</Box>
				</HeaderContainer>

				<ValuesContainer>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Box sx={{ width: "20%" }}>
							<HeaderLabel
								variant="body1"
								data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_LABEL}
							>
								{
									MESSAGE_STRINGS[
										"Schedule.ProductionDay.tableCell.productionDay"
									]
								}
							</HeaderLabel>
						</Box>
						<Box sx={{ width: "20%" }}>
							<HeaderLabel
								variant="body1"
								data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_LABEL}
							>
								Calendar Day 1
							</HeaderLabel>
						</Box>
						<Box sx={{ width: "20%" }}>
							<Box sx={{ width: "10rem" }}>
								<TimePicker
									dataTestId={SCHEDULE_TEST_ID.PRODUCTION_DAY_START_TIME}
									value={startTime.renderTime}
									onBlur={() =>
										handleErrorVisibilityChange("blur", "startTime")
									}
									isError={visible.startTime.blur && startTime.isError}
									handleChange={(value, keyboardInput) => {
										if (!visible.startTime.dirty) {
											handleErrorVisibilityChange("dirty", "startTime");
										}
										handleProductionDayTimeChange(
											value,
											keyboardInput,
											TIME_FIELD_TYPES.START_TIME
										);
									}}
								/>
							</Box>
						</Box>
						<Box sx={{ width: "20%" }}>
							<Box sx={{ width: "12.5rem" }}>
								<GeneralDropdown
									value={getSameDayValue(
										endsOn.value === ENDS_ON_VALUES.SAME_DAY,
										isEndsOnEmpty
									)}
									onChange={(e) => {
										if (!visible.endsOn.dirty) {
											handleErrorVisibilityChange("dirty", "endsOn");
										}
										handleSameDayChange(e);
									}}
									onBlur={() => handleErrorVisibilityChange("blur", "endsOn")}
									error={visible.endsOn.blur && endsOn.isError}
									placeholderText="Select Value"
									data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_ENDS_ON}
								>
									<MenuItem
										value={ENDS_ON_VALUES.SAME_DAY}
										data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_ENDS_ON_ITEMS}
									>
										{MESSAGE_STRINGS["Schedule.endsOn.sameDay"]}
									</MenuItem>
									<MenuItem
										value={ENDS_ON_VALUES.NEXT_DAY}
										data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_ENDS_ON_ITEMS}
									>
										{MESSAGE_STRINGS["Schedule.endsOn.nextDay"]}
									</MenuItem>
								</GeneralDropdown>
							</Box>
						</Box>
						<Box sx={{ width: "20%" }}>
							<Box sx={{ width: "10rem" }}>
								<TimePicker
									dataTestId={SCHEDULE_TEST_ID.PRODUCTION_DAY_END_TIME}
									value={endTime.renderTime}
									isError={visible.endTime.blur && endTime.isError}
									onBlur={() => handleErrorVisibilityChange("blur", "endTime")}
									handleChange={(value, keyboardInput) => {
										if (!visible.endTime.dirty) {
											handleErrorVisibilityChange("dirty", "endTime");
										}
										handleProductionDayTimeChange(
											value,
											keyboardInput,
											TIME_FIELD_TYPES.END_TIME
										);
									}}
								/>
							</Box>
						</Box>
					</Box>
				</ValuesContainer>
				{errorBoxValidations() && (
					<ErrorContainer data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_ERRORS}>
						<Box sx={{ width: "40%" }} />
						<Box sx={{ width: "20%" }}>
							{startTime.isError && visible.startTime.blur && (
								<InlineErrorMessages
									message={startTime.errorText}
									width="100%"
								/>
							)}
						</Box>
						<Box sx={{ width: "20%" }}>
							{endsOn.isError && visible.endsOn.blur && (
								<InlineErrorMessages message={endsOn.errorText} width="100%" />
							)}
						</Box>
						<Box sx={{ width: "20%" }}>
							{endTime.isError && visible.endTime.blur && (
								<InlineErrorMessages message={endTime.errorText} width="100%" />
							)}
						</Box>
					</ErrorContainer>
				)}
			</Box>
			<Box sx={{ marginTop: "1rem" }}>
				<HeaderContainer
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						bgcolor: "background.darkishBlackBlue",
						paddingX: "1rem",
						minHeight: "3.5rem",
					}}
				>
					<Box sx={{ width: "20%" }}>
						<HeaderLabel
							variant="h4"
							data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_HEADER_TIME_ZONE}
						>
							{MESSAGE_STRINGS["Schedule.ProductionDay.tableHeader.timezone"]}
						</HeaderLabel>
					</Box>
				</HeaderContainer>

				<ValuesContainer>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Box sx={{ width: "25%" }}>
							<GeneralDropdown
								value={timezone.value || DROPDOWN_CONSTANTS.DEFAULT_VALUE}
								onChange={(e) => {
									if (!visible.timezone.dirty) {
										handleErrorVisibilityChange("dirty", "timezone");
									}
									handleTimezoneChange(e);
								}}
								onBlur={() => handleErrorVisibilityChange("blur", "timezone")}
								error={visible.timezone.blur && timezone.isError}
								placeholderText="Select Value"
								data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_TIMEZONE}
							>
								{timezones.map(({ value, label }) => (
									<MenuItem
										key={value}
										value={value}
										data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_TIMEZONE_ITEMS}
									>
										{label}
									</MenuItem>
								))}
							</GeneralDropdown>
						</Box>
					</Box>
				</ValuesContainer>
				{errorBoxValidations() && (
					<ErrorContainer data-testid={SCHEDULE_TEST_ID.PRODUCTION_DAY_ERRORS}>
						<Box sx={{ width: "20%" }}>
							{timezone.isError && visible.timezone.blur && (
								<InlineErrorMessages message={timezone.errorText} width="70%" />
							)}
						</Box>
					</ErrorContainer>
				)}
			</Box>
		</>
	);
}

ProductionDay.propTypes = {
	startTime: PropTypes.shape({
		value: PropTypes.string,
		isError: PropTypes.bool,
		errorText: PropTypes.string,
	}),
	endTime: PropTypes.shape({
		value: PropTypes.string,
		isError: PropTypes.bool,
		errorText: PropTypes.string,
	}),
	timezone: PropTypes.shape({
		value: PropTypes.string,
		isError: PropTypes.bool,
		errorText: PropTypes.string,
	}),
	endsOn: PropTypes.shape({
		value: PropTypes.string,
		isError: PropTypes.bool,
		errorText: PropTypes.string,
	}),
	isEndsOnEmpty: PropTypes.bool,
	handleProductionDayTimeChange: PropTypes.func,
	handleSameDayChange: PropTypes.func,
	handleTimezoneChange: PropTypes.func,
	timezones: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
	),
	showProductionErrorsOnNext: PropTypes.shape({
		productionDay: PropTypes.bool,
		shifts: PropTypes.shape({
			shiftId: PropTypes.bool,
		}),
	}),
};

export default ProductionDay;
